import React, {FC} from 'react';
import {IEvacPrice} from "../../../../interfaces/evacInterfaces/evacPricesInterfaces";
interface IProps{
    price: IEvacPrice
}
const EvacPrice:FC<IProps> = ({price}) => {
    return (
        <div className='evacPrice'>
            <p>{price.service}</p>
            {price.price !=='' ? <p>Від {price.price}</p> : <p>Договірна</p>}
        </div>
    );
};

export default EvacPrice;