import React, {FC, useEffect, useState} from 'react';
import './orderSearchInputComponentStyles.scss'
import {router} from "../../../../router";
import {IOrder, IOrderPage, IOrderSearchParams} from "../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {ecommerceService} from "../../../../services/apiServices/ecommerce/ecommerceService";
import {AxiosResponse} from "axios";
import OrderInnerSearchInput from "./OrderInnerSearchInput/OrderInnerSearchInput";
interface IProps{
    setOrders:(value: IOrderPage | null) => void
}
const OrderSearchInputComponent:FC<IProps> = ({setOrders}) => {
    const [searchQuery, setSearchQuery] = useState<IOrderSearchParams | null>();
    const [orderId, setOrderId] = useState<string | null>('')
    const [userName, setUserName] = useState<string | null>('')
    const [userSurname, setUserSurname] = useState<string | null>('')
    const [userPhone, setUserPhone] = useState<string | null>('')
    const [userCity, setUserCity] = useState<string | null>('')
    const [productId, setProductId] = useState<string | null>()
    useEffect(() => {
        const newQuery:IOrderSearchParams = {
            orderId,
            userName,
            userSurname,
            userCity,
            userPhone,
            productId
        }
        setSearchQuery(newQuery)
        // console.log(newQuery)
    }, [orderId, userName, userSurname, userCity, userPhone, productId]);
    const handleSearch = async() => {
        try{
            const newOrders:AxiosResponse<IOrderPage> = await ecommerceService.getOrderList(1, searchQuery)
            setOrders(newOrders.data)
            console.log(searchQuery)
        }catch (e){
            console.log("no orders")
        }

    };
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };
    return (
        <div className='orderInputsContainer'>

            <OrderInnerSearchInput parametr='id' inputValue={orderId} setInputValue={setOrderId}
                                   handleKeyDown={handleKeyDown}/>
            <OrderInnerSearchInput parametr='імʼя клієнта' inputValue={userName} setInputValue={setUserName}
                                   handleKeyDown={handleKeyDown}/>
            <OrderInnerSearchInput parametr='прізвище клієнта' inputValue={userSurname} setInputValue={setUserSurname}
                                   handleKeyDown={handleKeyDown}/>
            <OrderInnerSearchInput parametr='телефон клієнта' inputValue={userPhone} setInputValue={setUserPhone}
                                   handleKeyDown={handleKeyDown}/>
            <OrderInnerSearchInput parametr='населений пункт клієнта' inputValue={userCity} setInputValue={setUserCity}
                                   handleKeyDown={handleKeyDown}/>
            {/*<OrderInnerSearchInput parametr='id одного з товарів' inputValue={productId} setInputValue={setProductId}*/}
            {/*                       handleKeyDown={handleKeyDown}/>*/}
            <button className='inputButton' onClick={handleSearch}>
                <img src="/headerImages/inputImages/inputSearch.png" alt='Пошук'/>
            </button>
        </div>
    );
};

export default OrderSearchInputComponent;
