import {IPayMethodData} from "../../interfaces/makeOrderFormInterface";
const payMethodPath = '/payMethodImages'
const deliverTypePath = '/deliverImages'
const fileFormat = 'png'
export const payMethodsData:IPayMethodData[] = [
    {
        id: 0,
        imgSrc:`${payMethodPath}/card.${fileFormat}`,
        inputName: "pay_method",
        valueName: 'card',
        label: 'Оплата на сайті'
    },
    {
        id: 1,
        imgSrc:`${payMethodPath}/cash.${fileFormat}`,
        inputName: "pay_method",
        valueName: 'cash',
        label: "Накладений платіж"
    }
]
export const deliverTypeData:IPayMethodData[] = [
    {
        id: 0,
        imgSrc:`${deliverTypePath}/nova.svg`,
        inputName: "deliverType",
        valueName: 'nova',
        label: 'Доставка Нова пошта'
    },
    {
        id: 1,
        imgSrc:`${deliverTypePath}/deliveryLogo.${fileFormat}`,
        inputName: "deliverType",
        valueName: 'delivery',
        label: 'Доставка "Delivery"'
    },
    {
        id: 2,
        imgSrc:`${deliverTypePath}/self.svg`,
        inputName: "deliverType",
        valueName: 'self',
        label: 'Самовивіз (Харків)'
    }
]
