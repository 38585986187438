import React, {useEffect} from 'react';
import {featuresDataInMain} from "../../../constants/featuresConstants/featuresConstants";
import Feature from "./Feature/Feature";
import './FeaturesStyles.scss'
import {useLocation} from "react-router-dom";

const FeaturesComponent = () => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash === '#features') {
            const element = document.getElementById('features');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (
        <div className='features' id='features'>
            <div className='featuresContainer'>
                {featuresDataInMain.map(feature => <Feature key={feature.id} props={feature}/>)}
            </div>
        </div>
    );
};

export default FeaturesComponent;