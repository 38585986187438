import React, {FC, PropsWithChildren} from 'react';
import {INewAddedPositionsList} from "../../../../../interfaces/productInterface";
import AddedItemComponent from "../AddedItemsComponents/AddedItemComponent/AddedItemComponent";
interface IProps extends PropsWithChildren{
    new_items_list: INewAddedPositionsList[];
}
const OpenListComponent: FC<IProps> = ({new_items_list}) => {
    return (
        <div className="list">
            {new_items_list.map(item => <AddedItemComponent
                key={item.id} item={item}/>)}
        </div>
    );
};

export default OpenListComponent;