import {useAuth} from "../../context/authContext";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {LoadingPage} from "../../pages/LoadingPage/LoadingPage";
import MeForm from "./MeForm/MeForm";
import MeInfo from "./MeInfo/MeInfo";

export const Me = () => {
    const {logout, me, isAuthenticated, getMe} = useAuth()
    const [loading, setLoading] = useState(!isAuthenticated);
    const [isChange, setIsChange] = useState(false)
    useEffect(() => {
        setLoading(!isAuthenticated)
    }, [me])
    return(
    <>{
    loading?
        <LoadingPage/>
        :  //ELSE

        <div className="mePage">
            {isAuthenticated ?
                    <div className="registeredContainer">


                    <MeInfo me={me} isChange={isChange} setIsChange={setIsChange}/>
                    <button className='changeBtn' onClick={() => setIsChange(prev => !prev)}>{isChange ? 'Закрити' : 'Змінити'}</button>


                        {/*<button onClick={() => logout()}>Вийти з акаунку</button>*/}
                    </div>
                ://ELSE
                    <div className="notRegistered">
                        <p>Ви ще не зареєстровані</p>
                        <Link style={{ textDecoration: 'none', color: 'blue' }} to="/register">Зареєструватися</Link>
                        <p>або</p>
                        <Link style={{ textDecoration: 'none', color: 'blue' }} to="/login">Увійти</Link>
                    </div>

            }

        </div>
}
</>
    )
}