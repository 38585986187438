import React, {FC, PropsWithChildren} from 'react';
import HeaderCart from "./Cart/HeaderCart";
import MeMenu from "./MeMenu/MeMenu";
import './headerRightSideContainerStyles1280.scss'
import HeaderSocialLinks from "./SocialLinks/HeaderSocialLinks";
import {IHeaderConfig, IHeaderRightConfigs, IHeadermessengerLinksConfigs} from "../../../interfaces/configInterfaces/headerConfigInterface";
interface IProps extends PropsWithChildren{
    configs: IHeaderRightConfigs
}
const HeaderRightSideContainer:FC<IProps> = ({configs}) => {
    return (
        <div className="headerRightContainer">
            <HeaderSocialLinks configs={configs.messengerLinksConfigs}/>
            <HeaderCart configs={configs.cartConfigs}/>
            <MeMenu configs={configs.meMenuConfigs}/>
        </div>
    );
};

export default HeaderRightSideContainer;