import React from 'react';
import {featuresDataInMain, featuresDataInService} from "../../../constants/featuresConstants/featuresConstants";
import AutoServiceFeature from "./AutoServiceFeature/AutoServiceFeature";
import './autoServiceFeaturesStyles.scss'
const AutoServiceFeatures = () => {
    return (
        <div className='serviceFeatures' id='serviceFeatures'>
            <div className='serviceFeaturesContainer'>
                {featuresDataInService.map(feature => <AutoServiceFeature key={feature.id} props={feature}/>)}
            </div>
        </div>
    );
};

export default AutoServiceFeatures;