import {apiService, IRes} from "../apiService";
import {
    IPartManufacturerPage,
    ISearchOption
} from "../../../interfaces/carInterface";
import {urls} from "../../../constants";

export const brandsService = {
    getAll: ():IRes<ISearchOption[]> => apiService.get(`${urls.cars.brands}`)
}
export const modelsService = {
    byBrand: (brandId:string): IRes<ISearchOption[]> => apiService.get(`${urls.cars.models}/${brandId}`)
}
export const manufacturerService= {
    getAll: (page: number):IRes<IPartManufacturerPage> => apiService.get(`${urls.cars.manufacturers}?page=${page}&size=50`)
}
