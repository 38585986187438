import {ChangeEvent, PropsWithChildren, useEffect, useState} from "react";
// import './OrderForm.css'
import './makeOrderFormStyles.scss'
import {useAuth} from "../../../context/authContext";
import {IMakeOrderForm} from "../../../interfaces/makeOrderFormInterface";
import {SubmitHandler, useForm} from "react-hook-form";
import {AxiosError} from "axios/index";
import {ICartInfo, ICartProblem, IOrderToCreate} from "../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {ecommerceService} from "../../../services/apiServices/ecommerce/ecommerceService";
import * as React from "react";
import {
    IDeliveryCities,
    IDeliveryRegion,
    IDeliveryWareHouse
} from "../../../interfaces/externalInterfaces/deliversInterfaces";
import {router} from "../../../router";
import PayMethodComponent from "./PayMethod/PayMethodComponent";
import DeliverTypeComponent from "./DeliverType/DeliverTypeComponent";
import {NovaForm} from "./ChooseDeliverInfo/Nova/NovaForm";
import DeliveryForm from "./ChooseDeliverInfo/Delivery/DeliveryForm";
import {paymentService} from "../../../services/apiServices/payment/paymentServices";
import UserDeliverInfoInputs from "./UserDeliverInfoInputs/UserDeliverInfoInputs";
interface IProps extends PropsWithChildren{
    setLoading: (value:boolean) => void
    problemInfo: ICartProblem
    cartInfo: ICartInfo | null;
    canBePaid: boolean | null;
    setButIsActive: (arg: boolean) => void;
}

export const MakeOrderForm: React.FC<IProps> = ({setLoading, problemInfo,  cartInfo, canBePaid, setButIsActive}) => {
    const [isBad, setIsBad] = useState(false)
    const [payMethod, setPayMethod] = useState<string>('')
    const [deliverType, setDeliverType] = useState<string>('')
    //nova
    const [selectedAreaNova, setSelectedAreaNova] = useState<{ value: string, label: string } | null>(null);
    const [selectedCityNova, setSelectedCityNova] = useState<{ value: string, label: string } | null>(null);
    const [selectedWarehouseNova, setSelectedWarehouseNova] = useState<{ value: string, label: string } | null>(null);
    //delivery
    const [selectedCityDelivery, setSelectedCitiesDelivery] = useState<IDeliveryCities | null>(null)
    const [selectedCityDeliveryId, setSelectedCityDeliveryId] = useState<string>('');
    const [selectedRegionDelivery, setSelectedRegionDelivery] = useState<IDeliveryRegion | null>(null)
    const [selectedRegionDeliveryId, setSelectedRegionDeliveryId] = useState<string>('');
    const [selectedWareHousesDelivery, setSelectedWareHousesDelivery] = useState<IDeliveryWareHouse | null>(null)
    const [selectedWareHousesDeliveryId, setSelectedWareHousesDeliveryId] = useState<string>("");
    //
    const {isAuthenticated, me} = useAuth()
    const {reset, register, handleSubmit, setValue} = useForm<IOrderToCreate>();
    const [formData, setFormData] = useState<IMakeOrderForm>({
        this_user_phone: isAuthenticated ? me.phone : '',
        this_user_name:isAuthenticated ? me.profile.name : '',
        this_user_surname:isAuthenticated ? me.profile.surname : '',
        this_user_email:isAuthenticated ? me.email : null

    })
    useEffect(() => {
        renderDeliveryForm()
    }, [deliverType]);
    const renderDeliveryForm = () => {
        switch (deliverType) {
            case 'delivery':
                return (
                    <div className='makeOrderInnerFormContainer' id='orderFormRightInnerContainer'>
                        <h3>Адреса доставки</h3>
                        <DeliveryForm
                            setSelectedCitiesDelivery={setSelectedCitiesDelivery}
                            selectedCityDeliveryId={selectedCityDeliveryId}
                            setSelectedCityDeliveryId={setSelectedCityDeliveryId}
                            setSelectedRegionDelivery={setSelectedRegionDelivery}
                            selectedRegionDeliveryId={selectedRegionDeliveryId}
                            setSelectedRegionDeliveryId={setSelectedRegionDeliveryId}
                            setSelectedWareHousesDelivery={setSelectedWareHousesDelivery}
                            selectedWareHousesDeliveryId={selectedWareHousesDeliveryId}
                            setSelectedWareHousesDeliveryId={setSelectedWareHousesDeliveryId}
                        />
                    </div>
                );
            case 'nova':
                return (
                        <div className='makeOrderInnerFormContainer' id='orderFormRightInnerContainer'>
                            <h3>Адреса доставки</h3>
                        <NovaForm
                            selectedArea={selectedAreaNova}
                            setSelectedArea={setSelectedAreaNova}
                            selectedCity={selectedCityNova}
                            setSelectedCity={setSelectedCityNova}
                            selectedWarehouse={selectedWarehouseNova}
                            setSelectedWarehouse={setSelectedWarehouseNova}
                        />
                        </div>
                );
            default:
                return (
                    <>
                    </>
                )
        }
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const save:SubmitHandler<IOrderToCreate> = async (request) => {
        if (!problemInfo){
            request.cart = cartInfo.id
            request.status = 'new'
            request.this_user_phone = formData.this_user_phone
            request.this_user_name = formData.this_user_name
            request.this_user_surname = formData.this_user_surname
            request.this_user_email = formData.this_user_email
            request.deliver_type = deliverType
            request.pay_method = payMethod
            request.is_paid = false
            if (deliverType === 'self') {
                request.this_user_region = 'Самовивіз'
                request.this_user_city = 'Самовивіз'
                request.deliver_adress = 'Самовивіз'
            }
            else if (deliverType === 'nova'){
                request.this_user_region = selectedAreaNova.label
                request.this_user_city = selectedCityNova.label
                request.deliver_adress = selectedWarehouseNova.label
            }
            else if (deliverType === 'delivery'){
                request.this_user_region = selectedRegionDelivery.name
                request.this_user_city = selectedCityDelivery.name
                request.deliver_adress = selectedWareHousesDelivery.name + ' - ' + selectedWareHousesDelivery.address
            }
            try {
                setLoading(true)
                setButIsActive(false)
                const orderInfo = await ecommerceService.makeOrder(request)
                console.log()
                setIsBad(false)
                localStorage.removeItem('cart')
                localStorage.removeItem('cartInfo')
                if (payMethod == 'card'){
                    const checkoutResponse = await paymentService.getCheckoutUrl({order_id: (await orderInfo).data.id})
                    console.log(checkoutResponse.data.data.order.checkout_url)
                    window.location.href = await (checkoutResponse).data.data.order.checkout_url;
                    // window.open(checkoutResponse.data.data.order.checkout_url, '_blank');
                }else {
                    router.navigate('/success_order')
                }
            }catch (e){
                const err = e as AxiosError
                if (err.response) {
                    console.log(`Помилка сервера: ${err.response.status} - ${err.response.data}`)
                    console.log(err.response.data)
                }
                localStorage.removeItem('cart')
                localStorage.removeItem('cartInfo')
                console.log(e)
                setIsBad(true)
                setButIsActive(true)
                router.navigate('/unsuccess_order')
            }finally {
                setLoading(false)
                setButIsActive(true)
            }
        }
    }
    return (
        <div className="makeOrderForm">
            <div className='orderFormHeader'>
                Оформлення замовлення
            </div>
            <form id='orderForm' onSubmit={handleSubmit(save)}>

                <UserDeliverInfoInputs formData={formData} handleInputChange={handleInputChange}/>

                <PayMethodComponent register={register} canBePaid={canBePaid} payMethod={payMethod}
                                    setPayMethod={setPayMethod}/>

                <DeliverTypeComponent deliverType={deliverType} setDeliverType={setDeliverType}/>
                {renderDeliveryForm()}
                {isBad && <h2 style={{color: "red"}}>Упс, щось пішло не так, спробуйте ще раз</h2>}
                {/*<button disabled={!butIsActive}>Оформити замовлення</button>*/}
            </form>
        </div>
);
};


//FOR DEV
// const handleChangeTablist = (event: React.SyntheticEvent, newValue: string) => {
//     setVal(newValue);
// };
// const handleDeliverTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const deliverT = event.target.value
//     setDeliverType(deliverT)
// }