import React from 'react';
import './securityPolicyStyles.scss'
const SecurityPolicyPage = () => {
    return (
        <div className='securityPolicy'>
            SecurityPolicyPage
        </div>
    );
};

export default SecurityPolicyPage;