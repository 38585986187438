import {Link, useParams} from "react-router-dom";
import {authService} from "../../../services/apiServices/auth/authService";
import {useEffect, useState} from "react";
import './activateAccountPageStyles.scss'
import ErrorComponent from "../../../components/ErrorComponent/ErrorComponent";

export const ActivateAccountPage = () => {
    const {token} = useParams<{token:string}>()
    const [isBad, setIsBad] = useState<string | null>(null)
    const activateAcc = async () => {
        try {
            await authService.activate(token)
            setIsBad(null)
        }catch (e){
            console.log(e)
            setIsBad('Error: ' + e.toString())
        }
    }
    useEffect(() => {
        activateAcc()
    }, [isBad])

    return (
        <div className='activateAccountPage'>
            {isBad?
                <ErrorComponent to={'/register'} error={isBad}/>
                :
                <div className='activateAccountContainer'>
                    <p>Ваш аккаунт зареєстровано</p>
                    <img src='/activateImages/activateAccountImage.png' alt={'activate acc'}/>
                    <Link to={'/login'}>Увійти</Link>
                </div>}
        </div>
    )
}