import React, {useEffect, useState} from 'react';
import {useCache} from "../../../../../context/cacheContext";
import {ICacheServices} from "../../../../../interfaces/autoServiceInterfaces/cacheAutoServiceInterfaces";
import {cacheService} from "../../../../../services/apiServices/cache/cacheService";
import {AdminLoadingPage} from "../../../AdminLoadingPage/AdminLoadingPage";
import ChangePhonesInputContainer from "../../Containers/ChangePhonesInputContainer/ChangePhonesInputContainer";

const ChangeUsaServices = () => {
    const {usaServices, getUsaServices} =useCache()
    const [inputs, setInputs] = useState(['']);
    const [changeInfo, setChangeInfo] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (usaServices) {
            const newInputs = usaServices.map(advantage => advantage);
            setInputs(newInputs)
        }else {
            console.log('no advantages')
            getUsaServices()
        }

    }, [usaServices]);
    useEffect(() => {
    }, [changeInfo]);
    const handleSubmit = async (event:any) => {
        const data:ICacheServices = {services: inputs}
        try {
            setLoading(true)
            const res = await cacheService.usa.usaServices.setServices(data)
            setChangeInfo('Список сервісів Авто з США успішно додано')
            setInputs(res.data.services)
            return res
        }catch (e){
            console.log(`Error: ${e}`)
            setChangeInfo('Помилка сервера: ' + e.toString())
            return null
        }finally {
            setLoading(false)
        }
    }
    return (
        <>
            {
                loading ?
                    <AdminLoadingPage/>
                    :
                    <div className='changePhones'>
                        <form className='changePhonesForm' onSubmit={handleSubmit}>
                            <div className='innerChangeMessengerFormDiv'>
                                <ChangePhonesInputContainer phones={usaServices} changeInfo={changeInfo}
                                                            header={"Список сервісів Авто з США"} inputs={inputs} setInputs={setInputs}/>
                            </div>
                            <button>Зберегти</button>
                        </form>
                    </div>
            }
        </>
    );
};

export default ChangeUsaServices;