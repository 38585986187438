import React, {FC, PropsWithChildren} from 'react';
import HorizontalRadioGroup from "../HorizontalRadioGroup/HorizontalRadioGroup";
import {payMethodsData} from "../../../../constants/MakeOrderFormConstants/makeOrderFormConstants";
interface IProps extends PropsWithChildren{
    register: (name: any, options?: any) => any
    canBePaid: boolean
    payMethod: string
    setPayMethod:(method: string) => void
}
const PayMethodComponent :FC<IProps>= ({register, canBePaid, setPayMethod}) => {
    return (
        <div className='makeOrderInnerFormContainer' id='orderFormRightInnerContainer'>
            <h3>Спосіб оплати</h3>
            {!canBePaid &&
                <p className='noPriceP'>Нажаль у одного або декількох товарів відсутня ціна, оплата онлайн буде доступна тільки після
                    перевірки
                    замовлення менеджером</p>}
            <div className='radioGroupContainer' id='pay_method'>
                {payMethodsData.map(data => <HorizontalRadioGroup key={data.id} imgSrc={data.imgSrc} valueName={data.valueName} inputName={data.inputName} label={data.label} func={setPayMethod} condition={canBePaid}/>)}
            </div>
        </div>
    );
};

export default PayMethodComponent;