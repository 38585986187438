import {FC, useEffect, useState} from "react"
import {Outlet} from "react-router-dom";
import {Footer} from "../../components/Footer/Footer";
import './mainLayoutStyles.scss'
import BinotelWidget from "../../widgets/BinotelWidget/BinotelWidget";
import {useGetWidth} from "../../hooks/screenHooks/useGetWidth";
import {Header} from "../../components/Header/Header";
import {IHeaderConfig} from "../../interfaces/configInterfaces/headerConfigInterface";
import {useCache} from "../../context/cacheContext";
import {headerMessengerLinksData} from "../../constants/SocialLinksConstants/SocialLinksConstants";
import {IFooterConfig} from "../../interfaces/configInterfaces/footerConfigInterface";
export const MainLayout = () => {
    const [isMobile, setIsMobile] = useState(false)
    const screenWidth = useGetWidth()
    const {phones, messengerPhones} = useCache()
    useEffect(() => {
    }, [phones, messengerPhones]);
    useEffect(() => {
        handleResize()
    }, [screenWidth]);

    const handleResize = () => {
        if (screenWidth === 200) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };
    const mainHeaderConfigs: IHeaderConfig = {
        style: {
            backgroundColor: "#F2F0F8",
        },
        downHeaderStyle: {
            backgroundColor: "#495990"
        },
        contactsConfigs: {
            contactsStyles:{
                aStyles: {
                    color: '#495990'
                },
                containerStyles: {
                    backgroundColor: '#F2F0F8'
                }
            },
            phones: phones,
            mobileImg: 'phoneImage'
        },
        rightConfigs: {
            messengerLinksConfigs: {
                headerMessengerLinksData: headerMessengerLinksData,
                phones:messengerPhones
            },
            cartConfigs:{
                img: 'cart'
            },
            meMenuConfigs:{
                img: 'account',
                avatarStyle:{
                    color: '#F2F0F8',
                    backgroundColor: '#495990'
                },
                listStyle:{
                    aStyles: {
                        color: '#495990'
                    },
                    containerStyles: {
                        backgroundColor: '#F2F0F8'
                    }
                },

            }
        }
    }
    const mainFooterConfigs: IFooterConfig = {
        style:{
            backgroundImage: 'linear-gradient(to top, rgba(73, 89, 144, 0.95) 0%, #F2F0F8 100%)'
        },
        infoDivClass: 'footerInfoDiv',
        phones: phones,
        messengerPhones: messengerPhones

    }
    return(
        <div className='mainLayout'>
            <Header isMobile={isMobile} configs={mainHeaderConfigs}/>
            <div className="contentContainer">
                <BinotelWidget/>
                <Outlet/>
            </div>
            <Footer isMobile={isMobile} configs={mainFooterConfigs}/>

        </div>
    )
}