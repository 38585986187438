import React, {ChangeEvent, useEffect, useState} from 'react';
import {IPartManufacturer, IPartManufacturerPage} from "../../interfaces/carInterface";
import {Outlet, useSearchParams} from "react-router-dom";
import {manufacturerService} from "../../services/apiServices/cars/carsService";
import {updateDataTime} from "../../constants/timers";
import {LoadingPage} from "../LoadingPage/LoadingPage";
import {Categories} from "../../components/Categories/Categories";
import {Pagination} from "@mui/material";
import Manufacturers from "../../components/Manufacturers/Manufacturers";
import './manufacturersStyles.css'

const ManufacturersPage = () => {
    const [manufacturerPage, setManufacturerPage] = useState<IPartManufacturerPage | null>(null)
    const [manufacturers, setManufacturers] = useState<IPartManufacturer[] | null>(null)
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useSearchParams({page:'1'})
    let pagesCount = 10
    const FetchData = async () => {
        try {
            setLoading(true);
            const response = await manufacturerService.getAll(+query.get('page'))
            if (response.data.results && Array.isArray(response.data.results)) {
                setManufacturerPage(response.data)
                setManufacturers(response.data.results);
            } else {
                console.log(response)
                console.error('Отримані дані не є масивом');
            }
        }catch(e){
            console.log(e)
        }
        finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        const localManufacturersStr = localStorage.getItem(`localManufacturers${query.get('page')}`)
        if (localManufacturersStr) {
            try {
                const localManufacturers = JSON.parse(localManufacturersStr)
                if (localManufacturers.time && localManufacturers.data && localManufacturers.page === query.get('page')) {
                    const savedTime = new Date(localManufacturers.time).getTime()
                    const now = new Date().getTime()
                    const dif = now - savedTime
                    if (dif > updateDataTime) {
                        FetchData()
                    } else {
                        setManufacturerPage(localManufacturers.data)
                        setManufacturers(localManufacturers.data.results)
                    }
                } else {
                    FetchData()
                    console.log("Error to parse data:")
                }
            } catch (e) {
                FetchData()
                console.log("Error to parse data:" + e)
            }
        }else {
            FetchData()
        }
    }, [query.get('page')]);
    if (manufacturerPage !== null)
        pagesCount = manufacturerPage.total_pages
    const handleChangePage = (event: ChangeEvent<unknown>, page:number) => {
        setQuery(prev => {prev.set('page', (page).toString())
            return prev
        })
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    return (
        <div>
            <Outlet/>
            {
                loading?
                    <LoadingPage/>
                    :  //ELSE
                    <>
                        <Manufacturers manufacturers={manufacturers}/>
                        <div className='paginationDiv'>
                            <Pagination count={pagesCount} page={+query.get("page")} onChange={handleChangePage} />
                        </div>
                    </>
            }
        </div>
    );
};

export default ManufacturersPage;