import React, {ChangeEvent, useEffect, useState} from 'react';
import {IProduct, IProductPage} from "../../../../interfaces/productInterface";
import {Outlet, useParams, useSearchParams} from "react-router-dom";
import {productService} from "../../../../services/apiServices/product/productServices";
import {LoadingPage} from "../../../LoadingPage/LoadingPage";
import {Products} from "../../../../components/Products/Products";
import {Pagination} from "@mui/material";
import {router} from "../../../../router";
import PaginationComponent from "../../../../components/Pagination/PaginationComponent";

const ProductsByCategoryAndModelPage = () => {
    const [products, setProducts] = useState<IProduct[] | null>(null)
    const [productPage, setProductsPage] = useState<IProductPage | null>(null)
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useSearchParams({page:'1'})
    const {category_id, model_id} = useParams<{category_id:string, model_id:string}>()
    let pagesCount = 2
    useEffect(() => {
        const FetchData = async () => {
            try {
                setLoading(true);
                const response = await productService.getByCategoryAndModel(category_id, model_id, +query.get('page'))
                const fetchedData = response.data
                const fetchedDataResults = response.data.results
                if (fetchedDataResults && Array.isArray(fetchedDataResults)) {
                    if(fetchedDataResults.length === 1){
                        router.navigate(`/product_details/${response.data.results[0].id}`)
                    }
                    setProductsPage(fetchedData)
                    setProducts(fetchedDataResults)
                } else {
                    console.error('Отримані дані не є масивом');
                }
            }catch (e){
                console.log(`Error: ${e}`)
            }finally {
                setLoading(false)
            }
        }
        FetchData()
    }, [query.get('page')])
    if (products !== null)
        pagesCount = productPage.total_pages
    else {
        pagesCount = 1
    }
    const handleChangePage = (event: ChangeEvent<unknown>, page:number) => {
        setQuery(prev => {prev.set('page', (page).toString())
            return prev
        })
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    return (
        <>
            {
                loading?
                    <LoadingPage/>
                    :  //ELSE
                    <div className="products-page-container">
                        <Products products={products}/>
                        <PaginationComponent pagesCount={pagesCount} page={+query.get('page')} handleChangePage={handleChangePage}/>
                    </div>
            }

        </>
    );
};

export default ProductsByCategoryAndModelPage;