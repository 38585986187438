import React, {FC, useEffect, useState} from 'react';
interface IProps{
    isOpen: boolean;
    setIsOpen:(arg:any) => void
}
const OrderOpenFullButton:FC<IProps> = ({isOpen, setIsOpen}) => {
    const [rotation, setRotation] = useState('-90')

    useEffect(() => {
        if(isOpen){
            setRotation('0')
        }else {
            setRotation('-90')
        }
    }, [isOpen]);
    const handleOpen = () => {
        const newValue = !isOpen
        setIsOpen((prevIsOpen:boolean) => !prevIsOpen);
    }
    return (
        <>
            <img src="/headerImages/contactImages/OpenBtn.png" onClick={handleOpen} style={{transform: `rotate(${rotation}deg)`}}/>
        </>
    );
};

export default OrderOpenFullButton;