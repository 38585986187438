import React, {FC, PropsWithChildren} from 'react';
import {IFeaturesProps} from "../../../../constants/featuresConstants/featuresConstants";
interface IProps extends PropsWithChildren{
    props: IFeaturesProps
}

const Feature:FC<IProps> = ({props}) => {
    return (
        <div className='feature'>
            <div className='imageDiv'>
                <img src={props.imageSrc} alt={props.alt}/>
            </div>

            <div className='textDiv'>
                <p>{props.text}</p>
            </div>

        </div>
    );
};

export default Feature;