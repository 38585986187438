import React, {FC, PropsWithChildren} from 'react';
import FooterAbout from "./About/FooterAbout";
import FooterWorkTime from "./WorkTime/FooterWorkTime";
interface IProps extends PropsWithChildren{
    infoDivClass: string
}
const FooterInfoLeftContainer:FC<IProps> = ({infoDivClass}) => {
    return (
        <div className='footerInfoInnerContainer' id='footerLeft'>
            <FooterAbout infoDivClass={infoDivClass}/>
            <FooterWorkTime infoDivClass={infoDivClass}/>
        </div>
    );
};

export default FooterInfoLeftContainer;