import React from 'react';
import UsaImageComponent from "../../components/CarsFromUsaComponents/UsaImageComponent/UsaImageComponent";
import './usaPageStyles.scss'
import MailTelInfoContainer from "../../components/InfoComponets/MailTelInfoContainer/MailTelInfoContainer";
import UsaTextContainer from "../../components/CarsFromUsaComponents/UsaTextContainer/UsaTextContainer";
import {useCache} from "../../context/cacheContext";
import UsaAdvantages from "../../components/CarsFromUsaComponents/UsaAdvantages/UsaAdvantages";
import UsaForm from "../../components/CarsFromUsaComponents/UsaForm/UsaForm";
import UsaBottomImg from "../../components/CarsFromUsaComponents/UsaBottomImg/UsaBottomImg";
const UsaPage = () => {
    const phones = ['+380730000000']
    const {supportEmail} = useCache()
    return (
        <div className='usaPage'>
            <UsaImageComponent/>
            <MailTelInfoContainer sign={'Контактний телефон'} method={'tel'} info={phones[0]} backgroundImage={'linear-gradient(to right, #499080 0%, #3E3E40 70%)'} pAid={'usa'}/>
            <UsaTextContainer/>
            <UsaAdvantages/>
            <MailTelInfoContainer sign={'Ваші скарги та пропозиціі'} method={'mailto'} info={supportEmail} backgroundImage={'linear-gradient(to left, #499080 0%, #3E3E40 90%)'} pAid={'usa'}/>
            <UsaForm/>
            <UsaBottomImg/>
        </div>
    );
};

export default UsaPage;