import React from 'react';

const TaglineImage = () => {
    return (
        <div className='taglineImage'>
            <img src='/logos/big_logo.png'/>
        </div>
    );
};

export default TaglineImage;