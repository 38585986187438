import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {INewAddedPositionsList} from "../../../../interfaces/productInterface";
import OpenListComponent from "./OpenListComponent/OpenListComponent";
import './Styles/ImportDataStyles.css'
interface IProps extends PropsWithChildren{
    new_items_list: INewAddedPositionsList[];
    text: string;
}
const FullAddedItemsComponent: FC<IProps> = ({new_items_list, text}) => {
    const [isListOpen, setIsListOpen] = useState(false)
    const [buttonActive, setButtonActive] = useState(true)
    useEffect(() => {
        if (new_items_list.length <= 0){
            setButtonActive(false)
        }
    }, [])


    return (
        <>
            <h2>{text}</h2>
            {buttonActive &&
                <button className='listButton' onClick={() => setIsListOpen(prev => !prev)}>{!isListOpen ? <>Відкрити
                    список...</> : <>Закрити список</>}</button>
            }
            {isListOpen &&
                <>
                    <OpenListComponent new_items_list={new_items_list}/>
                    <button className='listButton'
                            onClick={() => setIsListOpen(prev => !prev)}>Закрити список</button></>}

        </>
    );
};

export default FullAddedItemsComponent;