import React, {FC, PropsWithChildren} from 'react';
interface IProps extends PropsWithChildren{
    name: string | null;
    value:string | null;
}
const InnerInfoContainer:FC<IProps> = ({name, value}) => {
    return (
        <div className='innerInfoContainer'>
           <p>{name}</p>
           <h4>{value}</h4>
        </div>
    );
};

export default InnerInfoContainer;