import React, {FC, PropsWithChildren} from 'react';
import {ILinkImageProps} from "../../../constants/SocialLinksConstants/SocialLinksConstants";
interface IProps extends PropsWithChildren{
    link: ILinkImageProps;
}
const LinkSocialImage:FC<IProps> = ({link}) => {
    return (
        <a href={link.href} target="_blank" rel="noopener noreferrer">
            <img className="webLinkImg" src={link.imageSrc}
                 alt={link.alt}></img>
        </a>
    );
};

export default LinkSocialImage;
