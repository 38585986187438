import {FC, PropsWithChildren} from "react";
import {IProduct} from "../../interfaces/productInterface";
import {Product} from "./Product/Product";
import './ProductsStyle.scss'

interface IProps extends PropsWithChildren{
    products?: IProduct[]
}
export const Products:FC<IProps> = ({products}) => {
    return(
        <div className='products'>
            {products?.map(product => <Product key={product.id} product={product}/>)}
        </div>
    )
}