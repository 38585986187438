import React, {FC} from 'react';
import {IOrderSearchParams} from "../../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import assert from "node:assert";
interface IProps{
    parametr: string
    inputValue: string
    setInputValue: (arg:any)=> void
    handleKeyDown: (event: any) => void;
}
const OrderInnerSearchInput:FC<IProps> = ({parametr, inputValue, setInputValue, handleKeyDown}) => {
    return (
        <>
            <div className='orderInputDiv'>
            <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={`${parametr}`}
                onKeyDown={handleKeyDown}
            />

            </div>
        </>
    );
};

export default OrderInnerSearchInput;