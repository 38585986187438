// import React, {useEffect, useState} from 'react';
// import {useAuth} from "../../../../context/authContext";
// import {router} from "../../../../router";
// import {IAuthMenu} from "../../../../interfaces/authMenuInterface";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import Typography from "@mui/material/Typography";
// import './meMenuStyles.scss'
//
// const MeMenu = () => {
//     const { me, logout, isAuthenticated} = useAuth()
//     const [shortName, setShortName] = useState<string>('JD')
//     const [settings, setSettings] = useState<IAuthMenu[]>([{sign: "Мій профіль", func: () => router.navigate('/me')}, {sign: "Вийти", func: () => logout()}])
//     const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
//     useEffect(() => {
//         if (isAuthenticated){
//             if (me?.profile){
//                 setShortName(me.profile.name[0] + me.profile.surname[0])
//             }else {
//                 setShortName(me.email[0] + me.email[1])
//             }
//             if(me?.is_superuser){
//                 const set = [{sign: "Мій профіль", func: () => router.navigate('/me')},
//                     {sign: "Адмінка", func:() => router.navigate('/api/admin') },
//                     {sign: "Імпорт товару", func:() => router.navigate('/import_data_csv') },
//                     {sign: "Вийти", func: () => logout()}]
//                 setSettings(set)
//             }else {
//                 const set = [{sign: "Мій профіль", func: () => router.navigate('/me')},
//                     {sign: "Вийти", func: () => logout()}]
//                 setSettings(set)
//             }
//         }else {
//             const set = [{sign: "Увійти", func: () => router.navigate('/login')},
//                 {sign: "Зареєструватися", func: () => router.navigate('/register')}]
//             setSettings(set)
//         }
//
//     }, [me, isAuthenticated]);
//     const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
//         setAnchorElUser(event.currentTarget);
//     };
//     const handleCloseUserMenu = () => {
//         setAnchorElUser(null);
//     };
//     return (
//         <div className='me'>
//             <button className="authMenuButton" onClick={handleOpenUserMenu}>
//                 {isAuthenticated ?
//                     <div className='avatar'>{shortName}</div>
//                     : //ELSE
//                     <img src='/headerImages/authImages/account.png'/>
//                 }
//             </button>
//             <Menu sx={{mt: '50px'}} id="menu-appbar"
//                   anchorEl={anchorElUser} anchorOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//             }}
//                   keepMounted
//                   transformOrigin={{
//                       vertical: 'top',
//                       horizontal: 'right',
//                   }}
//                   open={Boolean(anchorElUser)}
//                   onClose={handleCloseUserMenu}>{settings.map((setting) => (
//                 <MenuItem key={setting.sign} onClick={setting.func}>
//                     <Typography textAlign="center">{setting.sign}</Typography>
//                 </MenuItem>
//             ))}
//             </Menu>
//         </div>
//     );
// };
//
// export default MeMenu;
import React, {useState, useEffect, PropsWithChildren, FC} from 'react';
import { useAuth } from "../../../../context/authContext";
import { router } from "../../../../router";
import { IAuthMenu } from "../../../../interfaces/authMenuInterface";
import './meMenuStyles.scss';
import {IHeaderMeMenuConfigs} from "../../../../interfaces/configInterfaces/headerConfigInterface";
interface IProps extends PropsWithChildren{
    configs: IHeaderMeMenuConfigs
}
const MeMenu:FC<IProps> = ({configs}) => {
    const { me, logout, isAuthenticated } = useAuth();
    const [shortName, setShortName] = useState('JD');
    const [settings, setSettings] = useState<IAuthMenu[]>([
        { sign: "Мій профіль", func: () => router.navigate('/me') },
        { sign: "Вийти", func: () => logout() }
    ]);
    const [anchorElUser, setAnchorElUser] = useState(null);

    useEffect(() => {
        if (isAuthenticated) {
            if (me?.profile){
                setShortName(me.profile.name[0] + me.profile.surname[0])
            }else {
                setShortName(me.email[0] + me.email[1])
            }
            let newSettings = [
                { sign: "Мій профіль", func: () => router.navigate('/me') },
                { sign: "Вийти", func: () => logout() }
            ];
            if (me?.is_superuser) {
                newSettings.splice(1, 0, { sign: "Адмінка", func: () => router.navigate('/admin') });
                newSettings.splice(1, 0, { sign: "Редагування", func: () => router.navigate('/admin/change_constants') });
                newSettings.splice(1, 0, { sign: "СуперАдмінка", func: () => router.navigate('/api/admin') });
                newSettings.splice(1, 0, { sign: "Імпорт товару", func: () => router.navigate('/admin/import_data_csv')});

            }
            setSettings(newSettings);
        } else {
            setSettings([
                { sign: "Увійти", func: () => router.navigate('/login') },
                { sign: "Зареєструватися", func: () => router.navigate('/register') }
            ]);
        }
    }, [me, isAuthenticated]);

    const handleOpenUserMenu = (event: any) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <div className='me'>
            <div className="authMenuButton" onMouseEnter={handleOpenUserMenu}>
                {isAuthenticated ? <div className='avatar' style={configs.avatarStyle}>{shortName}</div> : <img src={`/headerImages/authImages/${configs.img}.png`} alt="Account"/>}
            </div>
            {anchorElUser && (
                <div className="meMenu" onMouseLeave={handleCloseUserMenu} style={configs.listStyle.containerStyles}>
                    {settings.map((setting) => (
                        <div key={setting.sign} onClick={setting.func} className="menuItem" style={configs.listStyle.aStyles}>
                            {setting.sign}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MeMenu;
