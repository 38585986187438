import {useEffect, useMemo, useState} from "react";

export const useGetWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const screenWidth = useMemo(() => {
        if (width > 1280) {
            return 1280;
        } else if (width <= 1280 && width > 1000) {
            return 1000;
        } else if (width <= 1000 && width > 770) {
            return 770;
        } else if (width <= 770 && width > 10) {
            return 200;
        }
    }, [width]);

    return screenWidth;
};