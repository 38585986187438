import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {IPasswordRecoveryRequest} from "../../../interfaces/authInterface";
import {authService} from "../../../services/apiServices/auth/authService";
import {LoadingPage} from "../../LoadingPage/LoadingPage";
import './passwordRecoveryStyles.scss'
import {router} from "../../../router";
const RequestPasswordRecovery = () => {
    const {register, reset, handleSubmit} = useForm<IPasswordRecoveryRequest>()
    const [loading, setLoading] = useState<boolean | null>(false);
    useEffect(() => {
    }, [loading]);
    const passwordRecovery:SubmitHandler<IPasswordRecoveryRequest> = async (phone: IPasswordRecoveryRequest) => {
        try {
            setLoading(true)
            await authService.passwordRecoveryRequest(phone)
            await router.navigate('/check_sms')
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
            console.log('end')
        }

    }
    return (
        <>
            {loading ?
                <LoadingPage/>
                :
                <div className="passwordRecoveryPage">
                    <p>Відновлення Пароля</p>
                    <form onSubmit={handleSubmit(passwordRecovery)}>
                        <p>Введіть ваш номер телефону</p>
                        <input type='text' {...register('phone')} placeholder="Введіть номер телефону"/>
                        <button disabled={loading}>Відновити</button>
                    </form>
                </div>
            }
        </>
    );
};

export default RequestPasswordRecovery;