import {StylesConfig} from "react-select";

export const searchInputStyle1200: StylesConfig<any, false, any> = {
    control: (base) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        height: "100%",
        backgroundColor: '#F2F0F8',
        borderRadius: "0",
    }),
    menu: (base) => ({
        ...base,
        fontSize: "20px",
        backgroundColor: '#F2F0F8'
    }),
    placeholder: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "20px",
        color: "#696969"
    }),
    singleValue: (provided) => ({
        ...provided,
        textAlign: "center",
        fontSize: "20px"
    }),
};
export const searchInputStyle1000: StylesConfig<any, false, any> = {
    control: (base) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        height: "100%",
        backgroundColor: '#F2F0F8',
        borderRadius: "0",
    }),
    menu: (base) => ({
        ...base,
        fontSize: "20px",
        backgroundColor: '#F2F0F8'
    }),
    placeholder: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "24px",
        color: "#696969"
    }),
    singleValue: (provided) => ({
        ...provided,
        textAlign: "center",
        fontSize: "20px"
    }),
};
export const searchInputStyle770: StylesConfig<any, false, any> = {
    control: (base) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        height: "100%",
        backgroundColor: '#F2F0F8',
        borderRadius: "0",
    }),
    menu: (base) => ({
        ...base,
        fontSize: "16px",
        backgroundColor: '#F2F0F8'
    }),
    placeholder: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "20px",
        color: "#696969"
    }),
    singleValue: (provided) => ({
        ...provided,
        textAlign: "center",
        fontSize: "16px"
    }),
};