import React from 'react';
import ChangeEvacPhones from "../../../../components/AdminComponents/ChangeForms/ChangeEvacPhones/ChangeEvacPhones";
import ChangeEvacPrices from "../../../../components/AdminComponents/ChangeForms/ChangeEvacPrices/ChangeEvacPrices";

const ChangeEvacConstantsContainer = () => {
    return (
        <div className='changeConstantsHorizontalContainer'>
            <ChangeEvacPhones/>
            <ChangeEvacPrices/>
        </div>
    );
};

export default ChangeEvacConstantsContainer;