import React, {FC, useCallback, useRef} from 'react';
import './imagesGalleryStyles.scss'
import ImageGallery, {ReactImageGalleryItem} from "react-image-gallery";
interface IProps{
    images: string[]
}
const ImagesGallery:FC<IProps> = ({images}) => {
    const newImages = images.map(url => ({ original: url, }));
    return (
        <div className='imageGalleryContainer'>
            <ImageGallery   useBrowserFullscreen={false}  showThumbnails={false} items={newImages} showPlayButton={false} autoPlay={true}/>
        </div>
    );
};

export default ImagesGallery;