import {useEffect, useState} from "react";
import {Cart} from "../../components/CartComponents/Cart/Cart";
import {Outlet} from "react-router-dom";
import {MakeOrderForm} from "../../components/CartComponents/MakeOrderForm/MakeOrderForm";
import './cartPageStyles.scss'
import {
    ICartInfo,
    ICartItem,
    ICartProblem,
    ICartToCreate
} from "../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {LoadingPage} from "../LoadingPage/LoadingPage";
import {CartProblemComponent} from "../../components/CartComponents/Cart/CartProblemComponent/CartProblemComponent";
import {ecommerceService} from "../../services/apiServices/ecommerce/ecommerceService";
import {useCache} from "../../context/cacheContext";
import {useGetWidth} from "../../hooks/screenHooks/useGetWidth";
import TotalPriceContainer from "../../components/CartComponents/Cart/TotalPrice/TotalPriceContainer";
import {useAuth} from "../../context/authContext";
import {Simulate} from "react-dom/test-utils";
import copy = Simulate.copy;


export const CartPage = () => {
    const [cart, setCart] = useState<ICartItem[]>([]);
    const [cartInfo, setCartInfo] = useState<ICartInfo | null>(null)
    const [canBePaid, setCanBePaid] = useState(false)
    const [trigger, setTrigger] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [problemInfo, setProblemInfo] = useState<ICartProblem | null>(null)
    const [total, setTotal] = useState<number | null>(null)
    const [butIsActive, setButIsActive] = useState<boolean>(true)
    const {currencyToUah} = useCache()
    const {setCartTrigger} = useAuth()
    const [cur, setCur] = useState<number>(40)
    const [isMobile, setIsMobile] = useState(false)
    const screenWidth = useGetWidth()
    useEffect(() => {
        handleResize()
    }, [screenWidth]);
    useEffect(() => {
        if(currencyToUah){
            setCur(currencyToUah)
        }else {
            setCur(40)
        }
    }, [currencyToUah]);
    useEffect(() => {

    }, [problemInfo]);
    useEffect(() => {

    }, [cart]);
    useEffect(() => {
        let localCart = localStorage.getItem('cart');

        if (localCart) {
            try {
                const parsedCart = JSON.parse(localCart) as ICartItem[];
                if (Array.isArray(parsedCart)){
                    if(parsedCart.length != 0)
                    {
                        if (parsedCart.every(item => typeof item.id === 'number' && (typeof item.name === 'string' || item.name === null) && (typeof item.brand === 'string' || item.brand === null) && (typeof item.model === 'string' || item.model === null) && (typeof item.price === 'number' || item.price === null) && (typeof item.image === 'string' || item.image === null)))
                        {
                            setCart(parsedCart);
                            problemInfo && setProblemInfo(null)

                        } else {
                            console.log("The cart in localStorage does not conform to the expected format or is empty");
                            setProblemInfo({h2: 'Сталась помилка', h4: '3Спробуйте додати товар до кошику ще раз'})
                            localStorage.removeItem("cart");
                            localStorage.removeItem("cartInfo")
                        }
                    }else {
                        setProblemInfo({h2: 'Кошик пустий', h4: 'Спочатку додайте товар до кошику'})
                    }

                }else {
                    console.log('CartInOrder In local storage is not array')
                    setProblemInfo({h2: 'Сталась помилка', h4: '2Спробуйте додати товар до кошику ще раз'})
                    localStorage.removeItem("cart");
                    localStorage.removeItem("cartInfo")
                }
            } catch (error) {
                console.log("Error parsing cart from localStorage", error);
                setProblemInfo({h2: 'Сталась помилка', h4: '1Спробуйте додати товар до кошику ще раз'})
                localStorage.removeItem("cart");
                localStorage.removeItem("cartInfo")
            }
        } else {
            console.log("No cart in localStorage");
            setProblemInfo({h2: 'Кошик пустий', h4: 'Спочатку додайте товар до кошику'})
        }
        const cartInfoStr = localStorage.getItem('cartInfo');
        if (cartInfoStr) {
            try {
                const localCartInfo: ICartInfo = JSON.parse(cartInfoStr);
                if (localCartInfo && typeof localCartInfo === 'object' && localCartInfo.id && localCartInfo.can_be_paid !== undefined) {
                    setCartInfo(localCartInfo);
                    if(localCartInfo.can_be_paid){
                        setCanBePaid(true)
                    }else {
                        setCanBePaid(false)
                    }
                } else {
                    setProblemInfo({h2: 'Сталась помилка', h4: '5Спробуйте додати товар до кошику ще раз'})
                    localStorage.removeItem("cart")
                    localStorage.removeItem("cartInfo")
                    console.log("The cartInfo in localStorage does not conform to the expected format");
                }
            } catch (error) {
                setProblemInfo({h2: 'Сталась помилка', h4: '6Спробуйте додати товар до кошику ще раз'})
                localStorage.removeItem("cart")
                localStorage.removeItem("cartInfo")
                console.log("Error parsing cartInfo from localStorage ", error);
            }

        } else {
            setProblemInfo({h2: 'Кошик пустий', h4: 'Спочатку додайте товар до кошику'})
            localStorage.removeItem("cart")
            localStorage.removeItem("cartInfo")
            console.log("No cartInfo in localStorage");
        }
    }, [trigger]);
    useEffect(() => {
        if (cartInfo?.total_price){
            const newTotal = parseFloat(cartInfo?.total_price)
            setTotal(newTotal)
        }
    }, [cartInfo?.total_price]);
    const handleResize = () => {
        if (screenWidth === 200) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };
    const deleteItemFromCart = async (id:number) => {
        try {
            for (let i = 0; i < cart.length; i++) {
                if (cart[i].id === id) {
                    const newCart = [...cart]
                    newCart.splice(i, 1)
                    setCart(newCart)
                }
            }
            let cartCopy = [...cart]
            cartCopy = cartCopy.filter(item => item.id != id);

            if (cartCopy.length === 0) {
                localStorage.removeItem("cart")
                localStorage.removeItem("cartInfo")
            }
            else {

            }
            let cartForCreate: ICartToCreate = {items: cartCopy}
            let cartId: number
            if (!cartInfo) {
                const localCartInfo = localStorage.getItem('cartInfo');
                if (localCartInfo) {
                    const cartInfo: ICartInfo = JSON.parse(localCartInfo)
                    cartId = cartInfo.id
                } else {
                    console.log("Cannot int id")
                }
            } else {
                cartId = cartInfo.id
            }
            const response = await ecommerceService.updateCart(cartForCreate, cartId);
            const newCartInfo: ICartInfo = {
                id: response.data.id,
                can_be_paid: response.data.can_be_paid,
                total_price: response.data.total_price,
                total_items: response.data.items.length
            }
            setCartInfo(newCartInfo)
            setCart(cartCopy);
            const cartInfoStr = JSON.stringify(newCartInfo)
            localStorage.setItem('cartInfo', cartInfoStr)
            const cartString = JSON.stringify(cartCopy)
            localStorage.setItem('cart', cartString)
            setTrigger(!trigger)
            setCartTrigger(prev => !prev)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }


    }
    return (
        <>
            {
                loading ?
                    <LoadingPage/>
                    :  //ELSE
                    <>
                    { !problemInfo ?
                        <div className='cartPage'>
                            <Cart total_price={cartInfo?.total_price} cartItems={cart} deleteItemFromCart={deleteItemFromCart} isMobile={isMobile}/>
                            <div className='firstTotalPrice'>
                                <TotalPriceContainer total={total} cur={cur} canBePaid={canBePaid}/>
                            </div>
                            <MakeOrderForm setButIsActive={setButIsActive} setLoading={setLoading} problemInfo={problemInfo} cartInfo={cartInfo} canBePaid={canBePaid}/>

                            <div className='cartBottomContainer'>
                                {!isMobile &&
                                    <TotalPriceContainer total={total} cur={cur} canBePaid={canBePaid}/>
                                }
                                <button  form="orderForm" type="submit" disabled={!butIsActive}>Замовити</button>
                            </div>
                        </div>

                        :
                        <CartProblemComponent info={problemInfo}/>
                    }
                    </>
            }
        </>
    );
}