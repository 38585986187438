import React from 'react';
import {Link} from "react-router-dom";
import './page404styles.scss'
const Page404 = () => {
    return (
        <div className='errorContainer'>
            <p>Упс, сторінки не знайдено</p>
            <h1>404</h1>
            <img src='/errorImages/errorImage.png' alt={'error'}/>
            <Link id='errorLink' to={'main'}>На головну</Link>
        </div>
    );
};

export default Page404;