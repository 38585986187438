export const cacheAutoServiceDefault ={
    servicePhones: ['+380678597770'],
    partPhones: ["+380669017770", "+380739017770"],
    address: {
        text: "м. Харків, вʼїзд Станційний, 3",
        link:"https://www.google.com/maps/place/%D0%A1%D0%90%D0%9A%D0%9E,+%D0%90%D0%92%D0%A2%D0%9E%D0%A1%D0%95%D0%A0%D0%92%D0%98%D0%A1/@49.9557772,36.1649233,17z/data=!3m1!4b1!4m6!3m5!1s0x41279f82216d1eb9:0x5f89a0bd8ad09036!8m2!3d49.9557772!4d36.1649233!16s%2Fg%2F1tfc0kjh?authuser=0&hl=uk&entry=ttu"
    },
    advantages: [
        "Повний спектр послуг: від заміни масла до капітального ремонту двигуна.",
        "Професійний підхід: наші досвідчені механіки використовують сучасне обладнання та якісні запчастини.",
        "Швидкість та якість: гарантуємо швидке виконання робіт без шкоди для якості.",
        "Доступні ціни: у нас вигідні ціни та гнучка система знижок для постійних клієнтів.",
        "Гарантія на роботи: ми впевнені у своїй роботі, тому надаємо гарантію на всі види послуг"
    ],

}
export const cacheUsaDefault ={
    servicePhones: ['+380678597770'],
    partPhones: ["+380669017770", "+380739017770"],
    advantages: ['Кваліфікований підбір автомобіля',
                'Розмитнення та сертифікація',
                'Безкоштовне зберігання',
                'Швидка доставка',
                'Якісний ремонт',
                'Вигідні умови',],
    services: ['підбір автомобіля',
        'перевірка його технічного стану та юридичної чистоти',
        'допомога з оформленням необхідних документів']

}