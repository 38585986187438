import React from 'react';
import ChangeAdminEmail from "../../../../components/AdminComponents/ChangeForms/ChangeAdminEmail/ChangeAdminEmail";
import ChangeCurrency from "../../../../components/AdminComponents/ChangeForms/ChangeCurrency/ChangeCurrency";
import ChangeSocialLinks from "../../../../components/AdminComponents/ChangeForms/ChangeSocialLinks/ChangeSocialLinks";
import ChangePhones from "../../../../components/AdminComponents/ChangeForms/ChangePhones/ChangePhones";
import ChangeMessengerPhones
    from "../../../../components/AdminComponents/ChangeForms/ChangeMessengerPhones/ChangeMessengerPhones";
import ChangeSupportEmail
    from "../../../../components/AdminComponents/ChangeForms/ChangeSupportEmail/ChangeSupportEmail";

const ChangeMainConstantsContainer = () => {
    return (
        <>
            <div className='changeConstantsHorizontalContainer'>
                <ChangeAdminEmail/>
                <ChangeSupportEmail/>

            </div>
            <ChangeCurrency/>
            <div className='changeConstantsHorizontalContainer'>
                <ChangeSocialLinks/>
                <ChangePhones/>
            </div>

            <ChangeMessengerPhones/>
        </>
    );
};

export default ChangeMainConstantsContainer;