import React from 'react';
import {Link} from "react-router-dom";
import "../../../CheckPageStyles.scss"

const SuccessPaymentPage = () => {
    return(
        <div className="checkEmailPage">
            <div className="ActivateText">Оплата пройшла успішно</div>
            <Link className="linkToLogin" to={'/main'}><div>Головна</div></Link>
        </div>
    )
};

export default SuccessPaymentPage;