import React, {useEffect, useState} from 'react';
import {useCache} from "../../../../context/cacheContext";
import {ICacheSocialLinks} from "../../../../interfaces/cacheInterface";
import {LoadingPage} from "../../../../pages/LoadingPage/LoadingPage";
import {cacheService} from "../../../../services/apiServices/cache/cacheService";
import {AdminLoadingPage} from "../../AdminLoadingPage/AdminLoadingPage";

const ChangeSocialLinks = () => {
    const {socialLinks, getSocialLinks} = useCache()
    const [facebook, setFacebook] = useState<string | null>(null)
    const [instagram, setInstagram] = useState<string | null>(null)
    const [olx, setOlx] = useState<string | null>(null)
    const [avtoPro, setAvtoPro] = useState<string | null>(null)
    const [changeInfo, setChangeInfo] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if(socialLinks){
            setFacebook(socialLinks.facebook)
            setInstagram(socialLinks.instagram)
            setOlx(socialLinks.olx)
            setAvtoPro(socialLinks.avtoPro)
        }else {
            console.log("no links")
            getSocialLinks()
        }
    }, [socialLinks]);
    useEffect(() => {

    }, [changeInfo]);
    const handleSubmit = async (event : any) => {
        const data:ICacheSocialLinks ={
            instagram: instagram,
            facebook: facebook,
            olx: olx,
            avtoPro: avtoPro
        }
        try {
            setLoading(true)
            const res = await cacheService.socialLinksService.setSocialLinks(data)
            setChangeInfo('Посилання успішно додано')
            return res
        }catch (e){
            console.log(`Error: ${e}`)
            setChangeInfo('Помилка сервера: ' + e.toString())
            return null
        }finally {
            setLoading(false)
        }
    }
    return (
        <>

            {
                loading ?
                    <AdminLoadingPage/>
                    :
                    <div className='changeSocialLinks'>
                        <form className='changeSocialLinksForm' onSubmit={handleSubmit}>
                            <div className='innerChangeSocialLinksFormDiv'>
                                <div className='InputContainer'>
                                    <h2>Посилання на мережі</h2>
                                    <div className='input-labelHorizontalContainer'>
                                        <p>Instagram</p>
                                        <input type='text' placeholder='Посилання на Instagram' required minLength={5}
                                               value={instagram}
                                               onChange={e => {
                                                   const newInput = e.target.value
                                                   setInstagram(newInput)
                                               }}
                                               className="phoneInput"/>
                                    </div>
                                    <div className='input-labelHorizontalContainer'>
                                        <p>Facebook</p>
                                        <input type='text' placeholder='Посилання на Facebook' required minLength={5}
                                               value={facebook}
                                               onChange={e => {
                                                   const newInput = e.target.value
                                                   setFacebook(newInput)
                                               }}
                                               className="phoneInput"/>
                                    </div>
                                    <div className='input-labelHorizontalContainer'>
                                        <p>OLX</p>
                                        <input type='text' placeholder='Посилання на OLX' required minLength={5}
                                               value={olx}
                                               onChange={e => {
                                                   const newInput = e.target.value
                                                   setOlx(newInput)
                                               }}
                                               className="phoneInput"/>
                                    </div>
                                    <div className='input-labelHorizontalContainer'>
                                        <p>AvtoPro</p>
                                        <input type='text' placeholder='Посилання на AvtoPro' required minLength={5}
                                               value={avtoPro}
                                               onChange={e => {
                                                   const newInput = e.target.value
                                                   setAvtoPro(newInput)
                                               }}
                                               className="phoneInput"/>
                                    </div>


                                </div>
                            </div>
                            {changeInfo && <label>{changeInfo}</label>}
                            <button>Зберегти</button>
                        </form>

                    </div>

            }

        </>
    );
};

export default ChangeSocialLinks;