import React from 'react';
import MapContainer from "./MapContainer/MapContainer";

const FooterInfoRightContainer = () => {
    return (
        <div className='footerInfoInnerContainer' id='footerRight'>
            <MapContainer/>
            <img className='footerLogoImg' src='/logos/big_logo.png' alt={'logo'}/>
        </div>
    );
};

export default FooterInfoRightContainer;