import React from 'react';

const TaglineText = () => {
    return (
        <div className='taglineText'>
            <h1>Надійність кожної деталі - наше кредо</h1>
        </div>
    );
};

export default TaglineText;