import React, {useEffect, useState} from 'react';
import {useCache} from "../../../../context/cacheContext";
import {
    ICacheAdminEmailForCreate,
    ICacheCurrencyToUah,
    ICacheCurrencyToUahForCreate
} from "../../../../interfaces/cacheInterface";
import {cacheService} from "../../../../services/apiServices/cache/cacheService";
import {LoadingPage} from "../../../../pages/LoadingPage/LoadingPage";
import {AdminLoadingPage} from "../../AdminLoadingPage/AdminLoadingPage";

const ChangeCurrency = () => {
    const {currencyToUah, getCurrencyToUah, setLocalCurrencyToUah} = useCache()
    const [inputCurrencyToUah, setInputCurrencyToUah] = useState<number>(40)
    const [changeInfo, setChangeInfo] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    useEffect(() => {
        if(currencyToUah){
            setInputCurrencyToUah(currencyToUah)
        }
        else {
            setError('no email')
            getCurrencyToUah()
        }
    }, [currencyToUah]);
    useEffect(() => {

    }, [changeInfo]);
    const handleSubmit = async (event:any) => {
        const data:ICacheCurrencyToUahForCreate = {currency: inputCurrencyToUah}
        try {
            setLoading(true)
            const res = await cacheService.currencyService.setCurrencyToUah(data)
            setLocalCurrencyToUah(res.data.currency)
            setChangeInfo('Курс до ГРН успішно додано')
            return res
        }catch (e){
            console.log(`Error: ${e}`)
            setChangeInfo('Помилка сервера: ' + e.toString())
            return null
        }finally {
            setLoading(false)
        }
    };
    return (
        <>

            {
                loading ?
                    <AdminLoadingPage/>
                    :
                    <div className='changeEmail'>
                        <form className='changeEmailForm' onSubmit={handleSubmit}>
                            <div className='innerChangeEmailFormDiv'>
                                <div className='InputContainer'>
                                    <h2>Курс USD до UAH</h2>
                                    <input type='number' placeholder='Курс USD до UAH' required value={inputCurrencyToUah}
                                           onChange={e => {
                                               const newInput = e.target.value
                                               try {
                                                   const floatInput = parseFloat(newInput)
                                                   setInputCurrencyToUah(floatInput)
                                               }catch (e){
                                                   setInputCurrencyToUah(40)
                                               }
                                           }}
                                           step={0.1}
                                           className="phoneInput"/>
                                </div>
                            </div>
                            {changeInfo && <label>{changeInfo}</label>}
                            <button>Зберегти</button>
                        </form>

                    </div>

            }

        </>
    );
};

export default ChangeCurrency;