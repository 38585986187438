import React, {useEffect, useState} from 'react';
import './evacFormStyles.scss'
import {SubmitHandler, useForm} from "react-hook-form";
import {IEvacRequest} from "../../../interfaces/evacInterfaces/evacRequestInterface";
import {handlePhoneChange} from "../../../services/helpers/registerHelpers";
import {evacRequestService} from "../../../services/apiServices/evac/evacServices";
import {AxiosError} from "axios";
import {useLocation} from "react-router-dom";
import {LoadingPage} from "../../../pages/LoadingPage/LoadingPage";
const EvacForm = () => {

    const {register, reset, handleSubmit} = useForm<IEvacRequest>()
    const [loading, setLoading] = useState(false);
    const [errorOther, setErrorOther] = useState(false)
    const [errorPhone, setErrorPhone] = useState(false)
    const [isOk, setIsOk] = useState(false)
    const location = useLocation();
    useEffect(() => {
        if (location.hash === '#evacForm') {
            const element = document.getElementById('evacForm');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    useEffect(() => {
    }, [isOk, errorPhone, errorOther]);
    const createRequest:SubmitHandler<IEvacRequest> = async (data) => {

        try {
            setLoading(true)
            const res =  await evacRequestService.createRequest(data)
            setErrorPhone(false)
            setErrorOther(false)
            setIsOk(true)
            reset()
        } catch(e){
            setIsOk(false)
            const err = e as AxiosError
            if (err.response) {
                console.log(`Помилка сервера: ${err.response.status} - ${err.response.data}`)
                console.log(err.response.data)
                setErrorPhone(true)
            } else {
                console.error('Помилка: ', err.message);
                setErrorOther(true)
            }
        }finally {
            setLoading(false)
        }

    }
    return (
        <>
            {loading ?
                <LoadingPage/>
                :
                <div className='evacFormDiv' id='evacForm'>
                    <form className='evacForm' onSubmit={handleSubmit(createRequest)}>
                        <p>Замовити евакуатор</p>
                        <input type={'text'} placeholder={'Імʼя'} {...register('name')} minLength={2} required/>
                        <input type={'text'} placeholder={'Ваш номер телефону'} {...register('phone')} minLength={10} maxLength={13} required/>
                        <input type={'text'} placeholder={'Звідки(населений пункт, адреса'} {...register('address')} minLength={2} required/>
                        <input type={'text'} placeholder={'Куди(населений пункт, адреса'} {...register('destination')} minLength={2} required/>
                        <input type={'text'} placeholder={'Автомобіль(легковий, вантажний ..)'} {...register('car')} minLength={2}/>
                        <input type={'text'} placeholder={'Бажана дата та час'} {...register('datetime')} minLength={2} required/>
                        <input type={'text'} placeholder={'Коментар(необовʼязково'} {...register('message')} minLength={2}/>
                        <button>Замовити</button>
                        {isOk && <span style={{color:"green"}}>Успішно</span>}
                        {errorPhone && <span style={{color:"red"}}>Невірний формат телефону</span>}
                        {errorOther && <span style={{color:"red"}}>Вибачте, помилка з нашого боку, звʼяжіться з нами</span>}
                    </form>
                </div>
            }
        </>
    )
};

export default EvacForm;