import React, {FC} from 'react';
import {Link} from "react-router-dom";
import './errorComponentStyles.scss'
interface IProps{
    to: string;
    error: string
}
const ErrorComponent:FC<IProps> = ({to, error}) => {
    return (
        <div className='errorContainer'>
            <p>Упс, сталася помилка, можливо вийшов чаc...</p>
            <img src='/errorImages/errorImage.png' alt={'error'}/>
            <Link id='errorLink' to={to}>Спробувати ще раз</Link>
            <span>{error}</span>
        </div>
    );
};

export default ErrorComponent;