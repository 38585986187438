import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import './mainPageInfoComponentStyles.scss'
import {useCache} from "../../../context/cacheContext";
interface IProps extends PropsWithChildren{
    sign: string;
    backGround: string;
}
const MainPageInfoComponent:FC<IProps> = ({sign, backGround}) => {
    const {supportEmail, getSupportEmail} =useCache()
    

    return (
        <div className='mainPageInfo' style={{backgroundColor: backGround}}>
            <div className='mainPageInfoContainer'>
                <p>{sign}</p>
                <a href={`mailto: ${supportEmail}`}>{supportEmail}</a>
            </div>
        </div>
    );
};

export default MainPageInfoComponent;