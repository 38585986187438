import {IRes} from "../apiServices/apiService";
import {
    IDeliveryCitiesPage,
    IDeliveryRegionPage, IDeliveryWareHousesPage
} from "../../interfaces/externalInterfaces/deliversInterfaces";
import axios from "axios";
import {deliveryBaseURL, externalUrls} from "../../constants/externalUrls";

const deliveryApiService= axios.create({baseURL: deliveryBaseURL})

const deliversServices = {
    getCities: async (id: string):Promise<IRes<IDeliveryCitiesPage>> => deliveryApiService.get(`${externalUrls.deliveryUrls.cities}?regionId=${id}`),
    getRegions: async ():Promise<IRes<IDeliveryRegionPage>> => deliveryApiService.get(externalUrls.deliveryUrls.regions),
    getWareHouses: async (id: string):Promise<IRes<IDeliveryWareHousesPage>> => deliveryApiService.get(`${externalUrls.deliveryUrls.wareHouses}?CityId=${id}`)
}
export {
    deliversServices
}