import React, {FC, PropsWithChildren} from 'react';
import './horizontalRadioGroupStyles.scss'
interface IProps extends PropsWithChildren{
    imgSrc: string;
    valueName: string;
    inputName:string;
    label:string
    func:(arg:string)=> void;
    condition: boolean | null

}
const HorizontalRadioGroup:FC<IProps> = ({imgSrc, valueName, inputName, label, func, condition}) => {
    return (
        <div className='horizontalRadioGroup'>
            <div className='img-label'>
                <img src={imgSrc}
                     alt={valueName}/>
                <label htmlFor={valueName}>{label}</label>
            </div>

            <input className='radioCircle' type="radio" id={valueName} name={inputName}
                   onChange={() => func(valueName)} value={valueName} disabled={!condition}/>
        </div>
    );
};

export default HorizontalRadioGroup;