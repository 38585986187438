export const handlePhoneChange = (phone: string) => {
    if (phone.length < 10 && phone.length > 13 )
    {
        return null
    }
    if (phone.startsWith('0')) {
        phone = '+38' + phone;
    } else if (phone.startsWith('38')) {
        phone = '+' + phone;
    } else if (phone.startsWith('+38')) {
    } else {
        return null
    }
    return phone
}