import React, {useEffect, useState} from 'react';
import {useGetWidth} from "../../hooks/screenHooks/useGetWidth";
import {useCache} from "../../context/cacheContext";
import {IHeaderConfig} from "../../interfaces/configInterfaces/headerConfigInterface";
import {usaHeaderMessengerLinksData} from "../../constants/SocialLinksConstants/SocialLinksConstants";
import {IFooterConfig} from "../../interfaces/configInterfaces/footerConfigInterface";
import {Header} from "../../components/Header/Header";
import './carsFromUsaLayoutStyles.scss'
import {Footer} from "../../components/Footer/Footer";
import UsaPage from "../../pages/UsaPage/UsaPage";
const CarsFromUsaLayout = () => {
    const [isMobile, setIsMobile] = useState(false)
    const screenWidth = useGetWidth()
    const {usaPhones, getUsaPhones, messengerPhones, getMessengerPhones} = useCache()
    useEffect(() => {
        if(!usaPhones){
            getUsaPhones()
        }
    }, [usaPhones]);
    useEffect(() => {
        if(!messengerPhones){
            getMessengerPhones()
        }
    }, [messengerPhones]);
    useEffect(() => {
        handleResize()
    }, [screenWidth]);
    const handleResize = () => {
        if (screenWidth === 200) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };
    const usaHeaderConfigs: IHeaderConfig = {
        style: {
            backgroundColor: "#3E3E40",
        },
        downHeaderStyle: {
            backgroundImage: 'linear-gradient(to left, #499080 0%, #3E3E40 70%)'
            // backgroundImage: 'linear-gradient(to left, rgba(73, 144, 128, 0.5) 0%, rgba(62, 62, 64, 0.5) 50%)'

        },

        contactsConfigs: {
            contactsStyles:{
                aStyles: {
                    color: '#F2F0F8'
                },
                containerStyles: {
                    backgroundColor: '#3E3E40'
                }
            },
            phones: usaPhones,
            mobileImg: 'usaPhoneImage'
        },
        rightConfigs: {
            messengerLinksConfigs:{
                headerMessengerLinksData: usaHeaderMessengerLinksData,
                phones:messengerPhones
            },
            cartConfigs:{
                img: 'evacCart'
            },
            meMenuConfigs:{
                img: 'usaAccount',
                avatarStyle:{
                    color: '#3E3E40',
                    backgroundColor: '#499080'
                },
                listStyle:{
                    aStyles: {
                        color: '#F2F0F8'
                    },
                    containerStyles: {
                        backgroundColor: '#3E3E40'
                    }
                },

            }
        }

    }
    const usaFooterConfigs: IFooterConfig = {
        style:{
            backgroundImage: 'linear-gradient(to top, #499080 30%, #3E3E40 100%)'
        },
        infoDivClass: 'footerInfoDivUsa',
        phones: usaPhones,
        messengerPhones: messengerPhones

    }
    return (
        <div className='usaLayout'>
            <Header isMobile={isMobile} configs={usaHeaderConfigs}/>
            <div className='usaContentContainer'>
                <UsaPage/>
            </div>
            <Footer isMobile={isMobile} configs={usaFooterConfigs}/>
        </div>
    );
};

export default CarsFromUsaLayout;