import React from 'react';
import {Link} from "react-router-dom";
import './usaImgStyles.scss'
const UsaImageComponent = () => {
    return (
        <div className='usaImageContainer'>
            <img src={'/UsaImages/usaMainImg.svg'} alt={'mercedes'}/>
            <p className='usaP'>Авто твоєї мрії вже в дорозі</p>
            <Link className='button' to='/cars_from_usa#usaForm'>Замовити консультацію</Link>
        </div>
    );
};

export default UsaImageComponent;