import React, {useEffect, useState} from 'react';
import {useCache} from "../../../../context/cacheContext";
import ChangePhonesInputContainer from "../Containers/ChangePhonesInputContainer/ChangePhonesInputContainer";
import {
    ICacheAdminEmail,
    ICacheAdminEmailForCreate,
    ICachePhonesForCreate
} from "../../../../interfaces/cacheInterface";
import {cacheService} from "../../../../services/apiServices/cache/cacheService";
import {LoadingPage} from "../../../../pages/LoadingPage/LoadingPage";
import {AdminLoadingPage} from "../../AdminLoadingPage/AdminLoadingPage";

const ChangeAdminEmail = () => {
    const {adminEmail, getAdminEmail} = useCache()
    const [inputAdminEmail, setInputAdminEmail] = useState<string>()
    const [changeInfo, setChangeInfo] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    useEffect(() => {
        if(adminEmail){
            setInputAdminEmail(adminEmail)
        }
        else {
            setError('no email')
            getAdminEmail()
        }
    }, [adminEmail]);
    useEffect(() => {

    }, [changeInfo]);
    const handleSubmit = async (event:any) => {
        const data:ICacheAdminEmailForCreate = {email: inputAdminEmail}
        try {
            setLoading(true)
            const res = await cacheService.emailService.setEmailAdmin(data)
            setChangeInfo('Email успішно додано')
            return res
        }catch (e){
            console.log(`Error: ${e}`)
            setChangeInfo('Помилка сервера: ' + e.toString())
            return null
        }finally {
            setLoading(false)
        }
    };
    return (
        <>

            {
                loading ?
                    <AdminLoadingPage/>
                    :
                    <div className='changeEmail'>
                        <form className='changeEmailForm' onSubmit={handleSubmit}>
                            <div className='innerChangeEmailFormDiv'>
                                <div className='InputContainer'>
                                    <h2>Основний email</h2>
                                    <input type='email' placeholder='email' required minLength={5} value={inputAdminEmail}
                                       onChange={e => {
                                           const newInput = e.target.value
                                           setInputAdminEmail(newInput)
                                       }}
                                       className="phoneInput"/>
                                </div>
                            </div>
                            {changeInfo && <label>{changeInfo}</label>}
                            <button>Зберегти</button>
                        </form>

                    </div>

            }

        </>
    );
};

export default ChangeAdminEmail;