import React, {FC, PropsWithChildren} from 'react';
import {IEvacPrice} from "../../../../../interfaces/evacInterfaces/evacPricesInterfaces";
interface IProps extends PropsWithChildren{
    prices: IEvacPrice[] | null;
    changeInfo: string | null;
    header: string
    inputs: IEvacPrice[] | null;
    setInputs: (prices: IEvacPrice[]) => void;
}
const ChangePricesInputContainer:FC<IProps> = ({prices, changeInfo, inputs, setInputs, header}) => {
    const handleAddInput = () => {
        if (inputs.length <= 10){
            setInputs([...inputs, {service: '', price: ''}]);
        }
    };

    const handleDeleteInput = () => {
        if (inputs.length > 1) {
            setInputs(inputs.slice(0, -1));
        }
    };
    return (
        <>
            <div className='phonesInputContainer'>
                <h2>{header}</h2>

                {inputs.map((input, index) => (
                    <div className='pricesInputsHorizontalContainer'>
                        <input
                            key={index}
                            type='text'
                            placeholder='Введіть назву'
                            required
                            value={input.service}
                            onChange={e => {
                                const newInputs = [...inputs];
                                newInputs[index].service = e.target.value;
                                setInputs(newInputs);
                            }}
                            className="phoneInput"
                        />
                        <h1>-</h1>
                        <input
                            key={index}
                            type='text'
                            placeholder='Введіть ціну'
                            value={input.price}
                            onChange={e => {
                                const newInputs = [...inputs];
                                newInputs[index].price = e.target.value;
                                setInputs(newInputs);
                            }}
                            className="phoneInput"
                        />
                    </div>
                ))}
                {changeInfo && <label>{changeInfo}</label>}
                <p>Для того щоб видалити вже існуючий поставте обидва або одне значення на "00"(два нуля)</p>
            </div>
            <div className="buttons">
                <button onClick={handleAddInput} className="addButton">+</button>
                <button onClick={handleDeleteInput} className="deleteButton">-</button>
            </div>
        </>
    );
};

export default ChangePricesInputContainer;