import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import './headerCartStyles.scss'
import {IHeaderCartConfigs} from "../../../../interfaces/configInterfaces/headerConfigInterface";
import {ICartInfo, ICartItem} from "../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {useAuth} from "../../../../context/authContext";
interface IProps extends PropsWithChildren{
    configs: IHeaderCartConfigs
}

const HeaderCart:FC<IProps> = ({configs}) => {
    const [totalItems, setTotalItems] = useState<number>(0)
    const {cartTrigger} = useAuth()
    useEffect(() => {
        const cartInfo = localStorage.getItem("cartInfo")
        if (cartInfo) {
            const parsedCartInfo = JSON.parse(cartInfo) as ICartInfo;
            setTotalItems(parsedCartInfo.total_items)
        }else {
            setTotalItems(0)
        }
    }, [cartTrigger]);
    return (
        <>
            <Link className="cart" to={'/cart'}>
                {totalItems !== 0 && <div className='cartCounter'>{totalItems}</div>}
                <img src={`/headerImages/cartImages/${configs.img}.png`} alt="Кошик"/>
            </Link>
        </>
    );
};

export default HeaderCart;