import React, {useEffect, useState} from 'react';
import {useGetWidth} from "../../hooks/screenHooks/useGetWidth";
import {Header} from "../../components/Header/Header";
import BinotelWidget from "../../widgets/BinotelWidget/BinotelWidget";
import {Outlet} from "react-router-dom";
import './layoutDefaultStyles.scss'
import HeaderAdmin from "../../components/AdminComponents/HeaderAdmin/HeaderAdmin";
const AdminLayout = () => {
    const [isMobile, setIsMobile] = useState(false)
    const screenWidth = useGetWidth()
    useEffect(() => {
        handleResize()
    }, [screenWidth]);
    const handleResize = () => {
        if (screenWidth === 200) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };
    return (
        <div className='adminLayout'>
            <HeaderAdmin/>
            <div className="contentContainer">
                <Outlet/>
            </div>
        </div>
    );
};

export default AdminLayout;