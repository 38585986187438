import React, {FC, PropsWithChildren} from 'react';
import {IOrder} from "../../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
interface IProps extends PropsWithChildren{

    order: IOrder
}
const OrderDeliverInfo:FC<IProps> = ({order}) => {
    return (
        <div className='orderRowInfo' id='orderDeliverInfo'>
            {order?.deliver_type == 'self' ? <p>Самовивіз</p>
                :
                <>
                    {
                        order?.deliver_type == 'nova' ? <p>Нова Пошта</p>
                            :
                            order.deliver_type == 'delivery' ?
                            <p>Delivery</p>
                                :
                            <p>Не вдалося відобразити спосіб доставки</p>
                    }
                    <p>{order.this_user_region}</p>
                    <p>{order.this_user_city}</p>
                    <p>{order.deliver_adress}</p>
                </>
            }

        </div>
    );
};

export default OrderDeliverInfo;