import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {handlePhoneChange} from "../../../services/helpers/registerHelpers";
import {AxiosError} from "axios";
import './autoServiceFormStyles.scss'
import {useLocation} from "react-router-dom";
import {IAutoServiceRequest} from "../../../interfaces/autoServiceInterfaces/autoServiceInterfaces";
import {autoServiceService} from "../../../services/apiServices/autoService/autoServiceServices";
const AutoServiceForm = () => {
    const {register, reset, handleSubmit} = useForm<IAutoServiceRequest>()
    const [loading, setLoading] = useState(false);
    const [errorOther, setErrorOther] = useState(false)
    const [errorPhone, setErrorPhone] = useState(false)
    const [isOk, setIsOk] = useState(false)
    const location = useLocation();
    useEffect(() => {
        if (location.hash === '#serviceForm') {
            const element = document.getElementById('serviceForm');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    useEffect(() => {
    }, [isOk, errorPhone, errorOther]);
    const createRequest:SubmitHandler<IAutoServiceRequest> = async (data) => {
        data.phone = handlePhoneChange(data.phone)
        if (!data.phone) {
            setErrorPhone(true)
            return
        }
        try {
            setLoading(true)
            const res = await autoServiceService.createRequest(data)
            setErrorPhone(false)
            setErrorOther(false)
            setIsOk(true)
            reset()
        } catch (e) {
            setIsOk(false)
            const err = e as AxiosError
            if (err.response) {
                console.log(`Помилка сервера: ${err.response.status} - ${err.response.data}`)
                console.log(err.response.data)
                setErrorPhone(true)
            } else {
                console.error('Помилка: ', err.message);
                setErrorOther(true)
            }
        } finally {
            setLoading(false)
        }
        }
        return (
        <div className='serviceFormDiv' id='serviceForm'>
            <form className='serviceForm' onSubmit={handleSubmit(createRequest)}>
                <p>Записатися на сервіс</p>
                <input type={'text'} placeholder={'Імʼя'} {...register('name')} minLength={2} required/>
                <input type={'text'} placeholder={'Ваш номер телефону'} {...register('phone')} minLength={10}
                       maxLength={13} required/>
                <input type={'text'} placeholder={'Опишіть проблему'} {...register('problem')}
                       minLength={2} required/>
                <input type={'text'} placeholder={'Автомобіль(легковий, вантажний ..)'} {...register('car')}
                       minLength={2}/>
                <input type={'text'} placeholder={'Бажана дата та час'} {...register('datetime')} minLength={2}
                       required/>
                <input type={'text'} placeholder={'Коментар(необовʼязково'} {...register('message')} minLength={2}/>
                <button>Записатися</button>
                {isOk && <span style={{color: "green"}}>Успішно</span>}
                {errorPhone && <span style={{color: "red"}}>Невірний формат телефону</span>}
                {errorOther && <span style={{color: "red"}}>Вибачте, помилка з нашого боку, звʼяжіться з нами</span>}
            </form>
        </div>
    );
};

export default AutoServiceForm;