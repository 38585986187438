import React, {useEffect, useState} from 'react';
import ChangePhones from "../../../components/AdminComponents/ChangeForms/ChangePhones/ChangePhones";
import {useAuth} from "../../../context/authContext";
import YouAreNotAdmin from "../../../components/AdminComponents/YouAreNotAdmin/YouAreNotAdmin";
import ChangeMessengerPhones from "../../../components/AdminComponents/ChangeForms/ChangeMessengerPhones/ChangeMessengerPhones";
import './changeConstantsPageStyles.scss'
import ChangeAdminEmail from "../../../components/AdminComponents/ChangeForms/ChangeAdminEmail/ChangeAdminEmail";
import ChangeCurrency from "../../../components/AdminComponents/ChangeForms/ChangeCurrency/ChangeCurrency";
import ChangeSocialLinks from "../../../components/AdminComponents/ChangeForms/ChangeSocialLinks/ChangeSocialLinks";
import ChangeEvacPhones from "../../../components/AdminComponents/ChangeForms/ChangeEvacPhones/ChangeEvacPhones";
import ChangeEvacPrices from "../../../components/AdminComponents/ChangeForms/ChangeEvacPrices/ChangeEvacPrices";
import ChangeServicePhones
    from "../../../components/AdminComponents/ChangeForms/AutoService/ChangeAutoServicePhones/ChangeServicePhones/ChangeServicePhones";
import ChangeServicePartsPhones
    from "../../../components/AdminComponents/ChangeForms/AutoService/ChangeAutoServicePhones/ChangePartsPhones/ChangeServicePartsPhones";
import ChangeAutoServiceAddress
    from "../../../components/AdminComponents/ChangeForms/AutoService/ChangeAutoServiceAddress/ChangeAutoServiceAddress";
import ChangeAutoServiceAdvantages
    from "../../../components/AdminComponents/ChangeForms/AutoService/ChangeAutoServiceAdvantages/ChangeAutoServiceAdvantages";
import ChangeMainConstantsContainer from "./ChangeMainConstants/ChangeMainConstantsContainer";
import ChangeEvacConstantsContainer from "./ChangeEvacConstants/ChangeEvacConstantsContainer";
import ChangeAutoServiceConstantsContainer from "./ChangeAutoServiceConstants/ChangeAutoServiceConstantsContainer";
import ChangeConstantsHeader from "./ChangeConstantsHeader/ChangeConstantsHeader";
import ChangeUsaPhones from "../../../components/AdminComponents/ChangeForms/UsaChangeForms/ChangeUsaPhones/ChangeUsaPhones";
import ChangeUsaConstants from "./ChangeUsaConstants/ChangeUsaConstants";
const ChangeConstantsPage = () => {
    const {me, isAuthenticated} = useAuth()
    const [isAllowed, setIsAllowed] = useState(false)
    const [isMain, setIsMain] = useState(true)
    const [isEvac, setIsEvac] = useState(false)
    const [isService, setIsService] = useState(false)
    const [isUsa, setIsUsa] = useState(false)
    useEffect(() => {
        if (isAuthenticated && me && me?.is_superuser){
                setIsAllowed(true)
        }else {
            setIsAllowed(false)
        }
    }, [isAuthenticated, me]);
    useEffect(() => {

    }, [isMain, isEvac, isService, isUsa]);
    return (
        <>
            {isAllowed ?
                <div className='changeConstants'>
                    <div className='changeConstantsContainer'>
                        <ChangeConstantsHeader isOpen={isMain} setIsOpen={setIsMain} text={'Основні значення'}/>
                        {isMain && <ChangeMainConstantsContainer/>}
                        <ChangeConstantsHeader isOpen={isEvac} setIsOpen={setIsEvac} text={'Значення евакуатора'}/>
                        {isEvac && <ChangeEvacConstantsContainer/>}
                        <ChangeConstantsHeader isOpen={isService} setIsOpen={setIsService} text={'Значення СТО'}/>
                        {isService && <ChangeAutoServiceConstantsContainer/>}
                        <ChangeConstantsHeader isOpen={isUsa} setIsOpen={setIsUsa} text={'Значення Авто з США'}/>
                        {isUsa && <ChangeUsaConstants/>}
                    </div>
                </div>
                :
                <YouAreNotAdmin/>
            }

        </>


    );
};

export default ChangeConstantsPage;