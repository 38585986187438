import React, {FC, PropsWithChildren, useEffect} from 'react';
import './autoServiceTextContainerStyles.scss'
import {useCache} from "../../../context/cacheContext";
import {cacheAutoServiceDefault} from "../../../constants/cacheConstants/cacheConstants";
interface IProps extends PropsWithChildren{
    phone: string
}
const AutoServiceTextContainer:FC<IProps> = ({phone}) => {
    const {autoServiceAdvantages, getAutoServiceAdvantages,  autoServiceAddress, getAutoServiceAddress} = useCache()

    useEffect(() => {
        if (!autoServiceAddress){
            getAutoServiceAddress()
        }
        if (!autoServiceAdvantages){
            getAutoServiceAdvantages()
        }
    }, [autoServiceAdvantages, autoServiceAddress]);
    return (
        <div className='serviceTextContainer'>
            <h2>Станція технічного обслуговування</h2>
            <p>Втомилися шукати надійний автосервіс? Досить витрачати час та гроші на сумнівні СТО! Станція технічного обслуговування "BOLTOREZ" пропонує найкращі умови для вашого авто:</p>
            {autoServiceAdvantages &&
                <ul className='servicesUl'>
                    {autoServiceAdvantages ?
                        <>
                            {autoServiceAdvantages.map(advantage => <li>{advantage}</li>)}
                        </>
                            : //ELSE
                        <>
                            {cacheAutoServiceDefault.advantages.map(advantage => <li>{advantage}</li>)}
                        </>
                    }
                </ul>}

            <p>📞 Зв'яжіться з нами вже сьогодні!</p>
            <p>Телефон: <a href={`tel:${phone}`}>{phone}</a> </p>
            <p id='addressP'>Адреса: <a target="_blank" href={autoServiceAddress ? autoServiceAddress.link : cacheAutoServiceDefault.address.link}> {autoServiceAddress ? autoServiceAddress.text : "м. Харків, вʼїзд Станційний, 3"}</a></p>
            <span>У звʼязку з військовим станом неможливо оновити назву в google maps</span>
            <p>Довірте свій автомобіль професіоналам! СТО "BOLTOREZ" - надійність та якість, перевірені часом!</p>
        </div>
    );
};

export default AutoServiceTextContainer;