import React from 'react';
import {Link} from "react-router-dom";

const FooterPolicyContainer = () => {
    return (
        <div className='footerPolicyContainer'>
            <div className="footerRightsDiv">
                <p>Boltorez інтернет-магазин автозапчастин © 2024 all rights reserved</p>

            </div>
            <div className='madeWithContainer'>
                <h4>Thanks for the content to:</h4>
                <ul>All icons on this page created by
                    <li><a
                    href="https://www.flaticon.com/free-icons/bonus"
                    title="bonus, Settings, Customer Support, Handshake, Responsible, Shield icons">Freepik -
                    Flaticon</a></li>
                    <li><a href="https://icons8.com/">icons8</a></li>
                </ul>

            </div>

        </div>
    );
};

export default FooterPolicyContainer;