import React from 'react';
import './headerAdminStyles.scss'
import HeaderLeftSideContainer from "../../Header/LeftSideContainer/HeaderLeftSideContainer";
import HeaderRightSideContainer from "../../Header/RightSideContainer/HeaderRightSideContainer";
import HeaderSocialLinks from "../../Header/RightSideContainer/SocialLinks/HeaderSocialLinks";
import HeaderCart from "../../Header/RightSideContainer/Cart/HeaderCart";
import MeMenu from "../../Header/RightSideContainer/MeMenu/MeMenu";
import {Link} from "react-router-dom";
const HeaderAdmin = () => {
    return (
        <div className='headerAdmin'>
            <div className="headerAdminContainer">
                <HeaderLeftSideContainer/>
                <h2>Адмін</h2>
                <div className='linksContainer'>
                    <Link to={'/admin/orders_list'}>Замовлення</Link>
                    <Link to={'/admin/change_constants'}>Редагування</Link>
                    <Link to={'/api/admin'} target="_blank">Суперадмінка</Link>
                    <Link to={'/admin/import_data_csv'}>Імпорт</Link>

                </div>
            </div>
        </div>
    );
};

export default HeaderAdmin;