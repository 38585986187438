export interface ILinkImageProps{
    id: number,
    imageSrc: string,
    href: string,
    alt: string,
}
const fileFormatSocLinks = 'png'
const fileFormatMesLinks = 'svg'
const footerSocialLinksPath = '/footerImages/socialLinks'
const footerMessengerLinksPath = '/webLinks'
const headerMessengerLinksPath = '/headerImages/socialLinksIcons'
export const footerSocialLinksData:ILinkImageProps[] = [
    {
        id: 0,
        imageSrc: `${footerSocialLinksPath}/olx.${fileFormatSocLinks}`,
        href: 'https://avtorazborkamercedes.olx.ua/uk/home/',
        alt: 'olx'
    },
    {
        id: 1,
        imageSrc: `${footerSocialLinksPath}/avtopro.${fileFormatSocLinks}`,
        href: 'https://avto.pro/seller/razborka-mercedes-w220/',
        alt: 'avtopro'
    },
    {
        id: 2,
        imageSrc: `${footerSocialLinksPath}/facebook.${fileFormatSocLinks}`,
        href: 'https://www.instagram.com/boltorez.com.ua/',
        alt: 'facebook'
    },
    {
        id: 3,
        imageSrc: `${footerSocialLinksPath}/instagram.${fileFormatSocLinks}`,
        href: 'https://www.instagram.com/boltorez.com.ua/',
        alt: 'instagram'
    },

]
export const footerMessengerLinksData:ILinkImageProps[] = [
    {
        id: 0,
        imageSrc: `${footerMessengerLinksPath}/telegram.${fileFormatMesLinks}`,
        href: "https://t.me/",
        alt: 'telegram'
    },
    {
        id: 1,
        imageSrc: `${footerMessengerLinksPath}/viber.${fileFormatMesLinks}`,
        href: "viber://chat?number=",
        alt: 'viber'
    },
    {
        id: 2,
        imageSrc: `${footerMessengerLinksPath}/whatsapp.${fileFormatMesLinks}`,
        href: "https://wa.me/",
        alt: 'whatsapp'
    }

]
export const headerMessengerLinksData:ILinkImageProps[] = [
    {
        id: 0,
        imageSrc: `${headerMessengerLinksPath}/viberImage.png`,
        href: "viber://chat?number=",
        alt: 'viber'
    },
    {
        id: 1,
        imageSrc: `${headerMessengerLinksPath}/whatsappImage.png`,
        href: "https://wa.me/",
        alt: 'whatsapp'
    },
    {
        id: 2,
        imageSrc: `${headerMessengerLinksPath}/telegramImage.png`,
        href: "https://t.me/",
        alt: 'telegram'
    },
]
export const evacHeaderMessengerLinksData:ILinkImageProps[] = [
    {
        id: 0,
        imageSrc: `${headerMessengerLinksPath}/evacViberImage.png`,
        href: "viber://chat?number=",
        alt: 'viber'
    },
    {
        id: 1,
        imageSrc: `${headerMessengerLinksPath}/evacWhatsappImage.png`,
        href: "https://wa.me/",
        alt: 'whatsapp'
    },
    {
        id: 2,
        imageSrc: `${headerMessengerLinksPath}/evacTelegramImage.png`,
        href: "https://t.me/",
        alt: 'telegram'
    },
]
export const serviceHeaderMessengerLinksData:ILinkImageProps[] = [
    {
        id: 0,
        imageSrc: `${headerMessengerLinksPath}/serviceViberImage.png`,
        href: "viber://chat?number=",
        alt: 'viber'
    },
    {
        id: 1,
        imageSrc: `${headerMessengerLinksPath}/serviceWhatsappImage.png`,
        href: "https://wa.me/",
        alt: 'whatsapp'
    },
    {
        id: 2,
        imageSrc: `${headerMessengerLinksPath}/serviceTelegramImage.png`,
        href: "https://t.me/",
        alt: 'telegram'
    },
]
export const usaHeaderMessengerLinksData:ILinkImageProps[] = [
    {
        id: 0,
        imageSrc: `${headerMessengerLinksPath}/usaViberImage.png`,
        href: "viber://chat?number=",
        alt: 'viber'
    },
    {
        id: 1,
        imageSrc: `${headerMessengerLinksPath}/usaWhatsappImage.png`,
        href: "https://wa.me/",
        alt: 'whatsapp'
    },
    {
        id: 2,
        imageSrc: `${headerMessengerLinksPath}/usaTelegramImage.png`,
        href: "https://t.me/",
        alt: 'telegram'
    },
]