export const orderStatuses = [
    {
        id: 0,
        value: 'new',
        translate: 'Не оброблений'
    },
    {
        id: 1,
        value: 'in_progress',
        translate: 'В роботі'
    },
    {
        id: 2,
        value: 'done',
        translate: 'Завершений'
    },
    {
        id: 3,
        value: 'canceled',
        translate: 'Скасований'
    },
]