import React from 'react';
import ChangeUsaPhones from "../../../../components/AdminComponents/ChangeForms/UsaChangeForms/ChangeUsaPhones/ChangeUsaPhones";
import ChangeUsaAdvantages
    from "../../../../components/AdminComponents/ChangeForms/UsaChangeForms/ChangeUsaAdvantages/ChangeUsaAdvantages";
import ChangeUsaServices
    from "../../../../components/AdminComponents/ChangeForms/UsaChangeForms/ChangeUsaServices/ChangeUsaServices";

const ChangeUsaConstants = () => {
    return (
        <>
            <ChangeUsaPhones/>
            <div className='changeConstantsHorizontalContainer'>
                <ChangeUsaAdvantages/>
                <ChangeUsaServices/>
            </div>
        </>
    );
};

export default ChangeUsaConstants;