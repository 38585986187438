import React, {FC, PropsWithChildren} from 'react';
import {ICartProblem} from "../../../../interfaces/ecommerceInterfaces/ecommerceInterface";

interface IProps extends PropsWithChildren{
    info: ICartProblem
}
export const CartProblemComponent:FC<IProps> = ({info}) => {
    return (
        <div className='problemContainer'>
            <h2>{info.h2}</h2>
            <h4>{info.h4}</h4>
        </div>
    );
};

