import React, {FC, PropsWithChildren} from 'react';
import {footerMessengerLinksData} from "../../../../../constants/SocialLinksConstants/SocialLinksConstants";
import LinkContactImage from "../../../../LinkImagesComponents/LinkContactImage/LinkContactImage";
import {useCache} from "../../../../../context/cacheContext";
interface IProps extends PropsWithChildren{
    infoDivClass: string
}
const FooterContacts:FC<IProps> = ({infoDivClass}) => {
    const {phones} = useCache()
    return (
        <div className={infoDivClass}>
            <h5>Контакти:</h5>
            {phones?.map(phone => <a key={phone} href={`tel: ${phone}`}>{phone}</a>)}
            <div className="footerMessengerLinks">
                {footerMessengerLinksData.map(link => <LinkContactImage key={link.id} link={link}/>)}
            </div>
        </div>
    );
};

export default FooterContacts;