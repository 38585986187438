import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import './downHeaderStyles.scss'
import {Link} from "react-router-dom";
import {IDownHeaderStyle, IHeaderStyle} from "../../../interfaces/configInterfaces/headerConfigInterface";
interface IProps extends PropsWithChildren{
    isMobile: boolean;
    style: IDownHeaderStyle
}
const DownHeader:FC<IProps> = ({isMobile, style}) => {
    return (
        <div className='downHeader' style={style}>
            <div className='headerDownContainer' >
                {!isMobile &&
                    <div className='downHeaderInnerContainer' id={style?.aId}>
                        <Link to='/main#tagLine'>ГОЛОВНА</Link>
                        <Link to="/main#advantages">ПОСЛУГИ</Link>
                        {/*<Link to='/main'>ПРО НАС</Link>*/}
                        <Link to='#footer'>КОНТАКТИ</Link>
                    </div>
                }

                <div className='downHeaderInnerContainer' id={style?.aId}>
                    <Link to='/main'>АВТОЗАПЧАСТИНИ</Link>
                    <Link to='/service'>АВТОСЕРВІС</Link>
                    <Link to='/evac'>ЕВАКУАТОР</Link>
                    <Link to='/cars_from_usa'>ПРИГОН АВТО</Link>
                </div>
            </div>
        </div>
    );
};

export default DownHeader;