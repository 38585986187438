import React, {useEffect, useState} from 'react';
import {useCache} from "../../../../context/cacheContext";
import {ICachePhonesForCreate} from "../../../../interfaces/cacheInterface";
import {cacheService} from "../../../../services/apiServices/cache/cacheService";
import {AdminLoadingPage} from "../../AdminLoadingPage/AdminLoadingPage";
import ChangePhonesInputContainer from "../Containers/ChangePhonesInputContainer/ChangePhonesInputContainer";

const ChangeEvacPhones = () => {
    const {evacPhones, getEvacPhones} = useCache()
    const [inputs, setInputs] = useState(['']);
    const [changeInfo, setChangeInfo] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (evacPhones) {
            const newInputs = evacPhones.map(phone => phone);
            setInputs(newInputs)
        }else {
            console.log('no phones')
            getEvacPhones()
        }

    }, [evacPhones]);
    useEffect(() => {
    }, [changeInfo]);
    const handleSubmit = async (event:any) => {
        const data:ICachePhonesForCreate = {phones: inputs}
        try {
            setLoading(true)
            const res = await cacheService.evacPhones.setPhones(data)
            setChangeInfo('Телефони евакуатора успішно додано')
            setInputs(res.data.phones)
            return res
        }catch (e){
            console.log(`Error: ${e}`)
            setChangeInfo('Помилка сервера: ' + e.toString())
            return null
        }finally {
            setLoading(false)
        }
    }
    return (
        <>
            {
                loading ?
                    <AdminLoadingPage/>
                    :
                    <div className='changePhones'>
                        <form className='changePhonesForm' onSubmit={handleSubmit}>
                            <div className='innerChangeMessengerFormDiv'>
                                <ChangePhonesInputContainer phones={evacPhones} changeInfo={changeInfo}
                                                            header={"Телефони евакуатора"} inputs={inputs} setInputs={setInputs}/>
                            </div>
                            <button>Зберегти</button>
                        </form>
                    </div>
            }
        </>
    );
};

export default ChangeEvacPhones;