import {createContext, useContext, useState, ReactNode, FC, useEffect} from 'react';
import {ILocalMe, IUser} from "../interfaces/userInterface";
import {authService} from "../services/apiServices/auth/authService";
import {router} from "../router";
import {updateDataTime} from "../constants/timers";

type AuthContextType = {
    isAuthenticated: boolean;
    me: IUser | null;
    login: (me:IUser) => void
    logout: () => void;
    fetchMe: () => Promise<IUser>
    getMe: () => Promise<IUser>;
    setMe: (me: IUser | null) => void;
    setLocalMe: (me: IUser | null) => void;
    setIsEmailActivated: (isEmailActivated: boolean) => void;
    isEmailActivated: boolean;
    cartTrigger: boolean;
    setCartTrigger:  (value: React.SetStateAction<boolean>) => any;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
    children: ReactNode;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [isEmailActivated, setIsEmailActivated] = useState<boolean>(false)
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [me, setMe] = useState<IUser | null>(null)
    const [cartTrigger, setCartTrigger] = useState(false)
    // const [brands, setBrands] = useState()
    useEffect(() => {
        const localMeStr = localStorage.getItem("localMe")
        if (localMeStr){
            try {
                const localMe: ILocalMe = JSON.parse(localMeStr)
                if (localMe.time && localMe.data){
                    const savedTime = new Date(localMe.time.toString()).getTime()
                    const now = new Date().getTime()
                    const dif = now - savedTime
                    if (dif > updateDataTime){
                        getMe()
                    }else {
                        setMe(localMe?.data)
                        setIsAuthenticated(true)
                        setIsEmailActivated(localMe?.data.is_verified_email)
                    }
                }else {
                    getMe()
                    console.log("Error to parse data")
                }
            }catch (e){
                getMe()
                console.log("Error to parse data:" + e)
            }
        }
        else {
            getMe()
        }

    }, [])
    const login = (me:IUser) => {
        setIsAuthenticated(true);
        setMe(me)
        setLocalMe(me)
    };
    const logout = () => {
        localStorage.removeItem('access')
        localStorage.removeItem('refresh')
        localStorage.removeItem('localMe')
        setIsAuthenticated(false);
        setMe(null)
        router.navigate('/login')
    };
    const fetchMe = async () => {
        try {
            const meResponse = await authService.me()
            return meResponse.data
        }catch (e){
            console.log('Authentication failed. Error:  '+e)
            return null
        }
    }
    const getMe = async () => {
        const newMe = await fetchMe()
        if (newMe){
            setMe(newMe)
            setIsAuthenticated(true)
            setLocalMe(newMe)
            setIsEmailActivated(newMe.is_verified_email)
        }
        else {
            setIsAuthenticated(false)
            setMe(null)
        }
        return newMe
    }
    const setLocalMe = (localMe:IUser) => {
        if(localMe){
            const meObj: ILocalMe = {
                time: new Date(),
                data: localMe
            }
            localStorage.setItem("localMe", JSON.stringify(meObj))
        }

    }
    return (
        <AuthContext.Provider value={{isAuthenticated, login, logout , me, getMe, fetchMe, setMe, setLocalMe, setIsEmailActivated, isEmailActivated, cartTrigger, setCartTrigger}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
