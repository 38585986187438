import './footerStyles.scss'
import React, {FC, PropsWithChildren, useEffect} from "react";
import VerifyEmailContainer from "../MainPageComponents/VerifyEmailContainer/VerifyEmailContainer";
import FooterInfoContainer from "./InfoContainer/FooterInfoContainer";
import MapContainer from "./InfoContainer/RightContainer/MapContainer/MapContainer";
import {useAuth} from "../../context/authContext";
import {useLocation} from "react-router-dom";
import FooterPolicyContainer from "./PolicyContainer/FooterPolicyContainer";
import {IFooterConfig} from "../../interfaces/configInterfaces/footerConfigInterface";
interface IProps extends PropsWithChildren{
    isMobile: boolean;
    configs: IFooterConfig;
}
export const Footer:FC<IProps> = ({isMobile, configs})=> {
    const location = useLocation();
    useEffect(() => {
        if (location.hash === '#footer') {
            const element = document.getElementById('footer');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (
        <footer className="footer" id="footer" style={configs.style}>
            <FooterInfoContainer isMobile={isMobile} infoDivClass={configs.infoDivClass}/>
            <FooterPolicyContainer/>
        </footer>
    );
};


