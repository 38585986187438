import React, {useEffect} from 'react';
import EvacPrice from "./EvacPrice/EvacPrice";
import './evacPriceStyles.scss'
import {useCache} from "../../../context/cacheContext";
import {cacheService} from "../../../services/apiServices/cache/cacheService";

const EvacPricesComponent = () => {
    const {evacPrices, getEvacPrices} = useCache()
    useEffect(() => {
        if(!evacPrices){
            getEvacPrices()
        }
    }, [evacPrices]);
    return (
        <div className='evacPrices'>
            <div className='evacPricesContainer'>
                <p>Ціни:</p>
                {evacPrices?.map(price => <EvacPrice key={price.service} price={price}/>)}
            </div>
        </div>
    );
};

export default EvacPricesComponent;