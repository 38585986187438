import React, {FC, PropsWithChildren, useEffect} from 'react';
import './autoServicePageStyles.scss'
import AutoServiceImageComponent
    from "../../components/AutoServiceComponents/AutoServiceImageComponent/AutoServiceImageComponent";
import AutoServiceFeatures from "../../components/AutoServiceComponents/AutoServiceFeatures/AutoServiceFeatures";
import AutoServiceTextContainer
    from "../../components/AutoServiceComponents/AutoServiceTextContainer/AutoServiceTextContainer";
import AutoServiceForm from "../../components/AutoServiceComponents/AutoServiceForm/AutoServiceForm";
import {useCache} from "../../context/cacheContext";
import MailTelInfoContainer from "../../components/InfoComponets/MailTelInfoContainer/MailTelInfoContainer";
interface IProps extends PropsWithChildren{
    phones: string[]
    partPhones: string[]
}
const AutoServicePage:FC<IProps> = ({phones, partPhones}) => {
    const {supportEmail, getSupportEmail} = useCache()
    
    return (
        <div className='servicePage'>
            <AutoServiceImageComponent/>
            <MailTelInfoContainer sign={'Контактний телефон СТО'} method={"tel"} info={phones ? phones[0] : '+380678597770'} backgroundColor={'#599fd2'}/>
            <AutoServiceFeatures/>
            <MailTelInfoContainer sign={'Замовити нові запчастини'} method={'tel'} info={partPhones ? partPhones[0] : '+380669017770'} backgroundColor={'#599fd2'}/>
            <AutoServiceTextContainer phone={phones ? phones[0] : '+380663887770'}/>
            <MailTelInfoContainer sign={'Ваші скарги та пропозиціі'} method={'mailto'} info={supportEmail} backgroundColor={'#599fd2'}/>
            <AutoServiceForm/>
        </div>
    );
};

export default AutoServicePage;