import React, {FC, PropsWithChildren} from 'react';
import {ICartItem} from "../../../../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {useCache} from "../../../../../../../context/cacheContext";
interface IProps extends PropsWithChildren{
    item?: ICartItem
}
const ItemInCart:FC<IProps> = ({item}) => {
    const {currencyToUah} = useCache()
    return (
        <div className="itemInOrder">
            <div className='itemInfoTop'>
                <p>Артикул: <h4>{item.id}</h4></p>
                <a href={`/product_details/${item.id}`} target="_blank" rel="noopener noreferrer"><h3>{item.name}</h3></a>
            </div>

            <div className='itemInfoBottom'>
            <div className='itemInfoInnerBottom'>
                    <p>{item?.brand}</p>
                    <p>{item?.model}</p>
                    <p>${item.price} / {item.price * currencyToUah}грн</p>
                </div>

                <img src={item?.image} alt='photo'/>
            </div>


        </div>
    );
};

export default ItemInCart;