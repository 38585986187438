import React from 'react';
import {Link} from "react-router-dom";
import "../../../CheckPageStyles.scss"

const UnsuccessOrder = () => {
    return(
        <div className="checkEmailPage">
            <div className="ActivateText">Сталася помилка при замовленні</div>
            <Link className="linkToLogin" to={'/main'}><div>Головна</div></Link>
        </div>
    )
};

export default UnsuccessOrder;