import React, {FC, PropsWithChildren} from 'react';
import {INewAddedPositionsList} from "../../../../../../interfaces/productInterface";

interface IProps extends PropsWithChildren{
    item: INewAddedPositionsList
}
const AddedItemComponent:FC<IProps> = ({item}) => {
    return (
        <div className="item">
            <p>Артикул: {item.id}</p>
            <p>Назва: {item.name}</p>
        </div>
    );
};

export default AddedItemComponent;