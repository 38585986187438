import {SubmitHandler, useForm} from "react-hook-form";
import {IAuth} from "../../../interfaces/authInterface";
import {authService} from "../../../services/apiServices/auth/authService";
import axios, {AxiosError} from "axios";
import {IRegister} from "../../../interfaces/registerInterface";
import {IProfile} from "../../../interfaces/profileInterface";
import {ChangeEvent, useState} from "react";
import {router} from "../../../router";
import {LoadingPage} from "../../LoadingPage/LoadingPage";
import {handlePhoneChange} from "../../../services/helpers/registerHelpers";

export const RegisterPage = () => {
    const {register, reset, handleSubmit} = useForm<IRegister>()
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorOther, setErrorOther] = useState(false)
    const [errorRepeatPassword, setErrorRepeatPassword] = useState(false)
    const [passwordRepeat, setPasswordRepeat] = useState<string>('')
    const [loading, setLoading] = useState(false);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPasswordRepeat(event.target.value)
    }
    const registerUser:SubmitHandler<IRegister> = async (data) =>{
        const phone = handlePhoneChange(data.phone)
        if(!phone){
            setErrorEmail(true)
            return
        }
        const profile: IProfile = {
            name: data.profile.name,
            surname: data.profile.surname,
            city: data.profile.city
            // age: Number(data.profile.age)
        }
        const user: IRegister = {
            email: data.email,
            phone: phone,
            password: data.password,
            profile: profile
        };
        console.log(data)
        console.log(user)
        if (passwordRepeat === data.password) {
            try {
                setLoading(true);
                const responce = await authService.register(user)
                setErrorEmail(false)
                setErrorOther(false)
                await router.navigate('/check_sms')
                setErrorRepeatPassword(false)
                reset()
            } catch (e) {

                const err = e as AxiosError
                if (err.response) {
                    console.log(`Помилка сервера: ${err.response.status} - ${err.response.data}`)
                    console.log(err.response.data)
                    setErrorEmail(true)
                } else {
                    console.error('Помилка: ', err.message);
                    setErrorOther(true)
                }
            }finally {
                setLoading(false)
            }
        }else {
            setErrorRepeatPassword(true)
        }

    }



    return(
        <>
            {
                loading?
                    <LoadingPage/>
                    :  //ELSE
                <div className='LoginRegisterFormDiv'>
                    <form onSubmit={handleSubmit(registerUser)}>
                        <input type='text' placeholder={'Телефон'} {...register('phone')} minLength={10} maxLength={13} required/>
                        {errorEmail&&<span>Такий телефон вже зареєстрований, або неправильно вказаний</span>}
                        <input type='email' placeholder={'e-mail'} {...register('email')} required/>
                        <input type='password' placeholder={'Пароль'} {...register('password')} minLength={8} title="Пароль повинен містити принаймні одну велику літеру, одну маленьку літеру, спецсимвол, одну цифру і бути не менше 8 символів та не більше 128"/>
                        <input type='password' placeholder={'Пароль повторно'} value={passwordRepeat} onChange={handleInputChange}/>
                        {errorRepeatPassword&&<span>Пароль не співпадає</span>}
                        <input type='text' placeholder={'Імʼя'} {...register('profile.name')} minLength={2} maxLength={50} required/>
                        <input type='text' placeholder={'Прізвище (необовʼязково)'} {...register('profile.surname')} minLength={2}/>
                        <button>Зареєструватися</button>
                        {errorOther&&<span>Упс, невідома помилка сервера, спробуйте будь сласка пізніше або напишіть нам</span>}
                    </form>
                </div>
            }
        </>
    )
}