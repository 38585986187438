import React, {FC, useEffect, useState} from 'react';
import EvacImageComponent from "../../components/EvacComponents/EvacImageComponent/EvacImageComponent";
import MailTelInfoContainer from "../../components/InfoComponets/MailTelInfoContainer/MailTelInfoContainer";
import EvacFeatures from "../../components/EvacComponents/EvacFeatures/EvacFeatures";
import EvacPricesComponent from "../../components/EvacComponents/EvacPricesComponent/EvacPricesComponent";
import EvacForm from "../../components/EvacComponents/EvacForm/EvacForm";
import './evacPageStyles.scss'
import {useCache} from "../../context/cacheContext";
interface IProps{
    phones: string[] | null
}
const EvacPage:FC<IProps> = ({phones}) => {
    const {supportEmail, getSupportEmail} = useCache()
    const [evacPhones, setEvacPhones] = useState<string[]>(["+380663887770", "+380673887770"])
    useEffect(() => {
        if(phones && phones.length > 1){
            setEvacPhones(phones)
        }
    }, [phones]);
    
    return (
        <div className='evacPage'>
            <EvacImageComponent/>
            <MailTelInfoContainer sign={'Цілодобова гаряча лінія'} info={evacPhones[0]} method={'tel'} backgroundColor='#F0D267'/>
            <EvacFeatures/>
            <MailTelInfoContainer sign={'Ваші скарги та пропозиції'} info={supportEmail} method={'mailto'} backgroundColor='#F0D267'/>
            <EvacPricesComponent/>
            <EvacForm/>
        </div>
    );
};

export default EvacPage;