import React, {ChangeEvent, FC, PropsWithChildren} from 'react';
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {IVerifyEmail} from "../../../../interfaces/authInterface";
import './mailFormStyles.scss'
interface IProps extends PropsWithChildren{
    sendEmail: (userEmailObj: IVerifyEmail) => void;
    userEmail: string;
    handleEmailInputChange: (e: ChangeEvent<HTMLInputElement>)=> void
    handleCheckboxChange: () => void
    isChecked: boolean
}

const MainMailForm:FC<IProps> = ({sendEmail, userEmail, handleEmailInputChange, isChecked, handleCheckboxChange}) => {
    const {handleSubmit} = useForm<IVerifyEmail>()

    return (
        <form onSubmit={handleSubmit(sendEmail)} className='mailForm'>
            <div className='mailInputDiv'>
                <input
                    type="email"
                    placeholder="Ваш e-mail"
                    value={userEmail}
                    onChange={handleEmailInputChange}
                    required
                />
                <button className='footerInputButton' disabled={!isChecked}>
                    Підписатися
                </button>
            </div>
            <div className='checkBoxDiv'>
                <input
                    type='checkbox'
                    checked={isChecked}
                    onChange={handleCheckboxChange}

                    required// Додано обробник події onChange для чекбоксу
                />
                <label>Я погоджуюся з <Link to='/security_policy'>політикою безпеки</Link></label>
            </div>
        </form>
    );
};

export default MainMailForm;