import React, {useEffect, useState} from 'react';
import {useCache} from "../../../../context/cacheContext";
import ChangePhonesInputContainer from "../Containers/ChangePhonesInputContainer/ChangePhonesInputContainer";
import '../changePhonesFormsStyles.scss'
import {ICacheMessengersPhones, ICachePhonesForCreate} from "../../../../interfaces/cacheInterface";
import {cacheService} from "../../../../services/apiServices/cache/cacheService";
import {LoadingPage} from "../../../../pages/LoadingPage/LoadingPage";
import {AdminLoadingPage} from "../../AdminLoadingPage/AdminLoadingPage";
const ChangeMessengerPhones = () => {
    const {messengerPhones, getMessengerPhones, setLocalMessengerPhones} = useCache()
    const [inputsTelegram, setInputsTelegram] = useState(['']);
    const [inputsViber, setInputsViber] = useState(['']);
    const [inputsWhatsapp, setInputsWhatsapp] = useState(['']);
    const [changeInfo, setChangeInfo] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(messengerPhones){
            const newInputsTelegram = messengerPhones.telegram.map(telegramPhone => telegramPhone)
            setInputsTelegram(newInputsTelegram)
            const newInputsViber = messengerPhones.viber.map(viberPhone => viberPhone)
            setInputsViber(newInputsViber)
            const newInputsWhatsapp = messengerPhones.whatsapp.map(whatsappPhone => whatsappPhone)
            setInputsWhatsapp(newInputsWhatsapp)
        }else {
            console.log('no phones')
            getMessengerPhones()
        }

    }, [messengerPhones]);
    useEffect(() => {

    }, [changeInfo]);
    const handleSubmit = async (event:any) => {
        const data:ICacheMessengersPhones = {
            telegram: inputsTelegram,
            viber: inputsViber,
            whatsapp: inputsWhatsapp
        }
        try {
            setLoading(true)
            const res = await cacheService.messengerService.setMessengerPhones(data)
            setChangeInfo('Телефони успішно додано')
            setLocalMessengerPhones(res.data)
            return res
        }catch (e){
            console.log(`Error: ${e}`)
            setChangeInfo('Помилка сервера: ' + e.toString())
            return null
        }finally {
            setLoading(false)
        }
    };
    return (
        <>
            {loading ?
                <AdminLoadingPage/>
                :
                <form onSubmit={handleSubmit} className='changeMessengerPhonesForm'>
                    <div className='innerChangeMessengerFormDiv'>
                        <ChangePhonesInputContainer phones={messengerPhones?.telegram} changeInfo={changeInfo}
                                                    header={"Телефони telegram"} inputs={inputsTelegram}
                                                    setInputs={setInputsTelegram}/>
                        <ChangePhonesInputContainer phones={messengerPhones?.viber} changeInfo={changeInfo}
                                                    header={"Телефони Viber"} inputs={inputsViber}
                                                    setInputs={setInputsViber}/>
                        <ChangePhonesInputContainer phones={messengerPhones?.whatsapp} changeInfo={changeInfo}
                                                    header={"Телефони WhatsApp"} inputs={inputsWhatsapp}
                                                    setInputs={setInputsWhatsapp}/>
                    </div>
                    <button>Зберегти</button>
                </form>
            }

        </>

    );
};

export default ChangeMessengerPhones;