import React, {FC, useEffect, useState} from 'react';
interface IProps{
    inputType: string;
    register: (name: any, options?: any) => any
    registerName: string
    text: string | null;
    val: string | null;
    isChange: boolean
    minLength: number
}
const MeInfoInnerContainer:FC<IProps> = ({text, val, isChange, inputType, register, registerName, minLength}) => {
    const [newValue, setNewValue] = useState('')
    useEffect(() => {
        setNewValue(val)
    }, [val]);
    return (
        <div className='meInfoInnerContainer'>
           <p>{text}:</p>
            {
                !isChange ? < h5 > {val}</h5>
                    :
                    <input type={inputType} placeholder={text} {...register(registerName)} minLength={minLength} value={newValue}
                           onChange={e => {const newInput = e.target.value
                        setNewValue(newInput)}} />
            }

</div>
    );
};

export default MeInfoInnerContainer;