import {createContext, useContext, useState, ReactNode, FC, useEffect, useMemo} from 'react';
import {router} from "../router";
import {
    ICacheAdminEmail, ICacheCurrencyToUah,
    ICacheMessengersPhones,
    ICachePhones, ICacheSocialLinks, ICacheSocialLinksRes, ILocalCacheSocialLinks, ILocalCurrencyToUah, ILocalEmail,
    ILocalMessengerPhones,
    ILocalPhones
} from "../interfaces/cacheInterface";
import {cacheService} from "../services/apiServices/cache/cacheService";
import {updateDataTime} from "../constants/timers";
import {apiService} from "../services";
import {IEvacPrice, IEvacPrices} from "../interfaces/evacInterfaces/evacPricesInterfaces";
import {
    IAutoServiceAddress,
    IAutoServiceAddressData,
    ICacheAdvantages, ICacheServices
} from "../interfaces/autoServiceInterfaces/cacheAutoServiceInterfaces";
import {cacheAutoServiceDefault, cacheUsaDefault} from "../constants/cacheConstants/cacheConstants";
import {useStableQueryArgs} from "@reduxjs/toolkit/dist/query/react/useSerializedStableValue";
type CacheContextType = {
    phones: string[] | null;
    getPhones: () => Promise<ICachePhones | null>;
    setLocalPhones:(localPhones: string[]) => void;
    messengerPhones: ICacheMessengersPhones | null;
    getMessengerPhones: () => Promise<ICacheMessengersPhones | null>;
    setLocalMessengerPhones:(localMessengerPhones: ICacheMessengersPhones) => void;
    adminEmail: string | null;
    getAdminEmail: () => Promise<ICacheAdminEmail> | null;
    supportEmail: string | null;
    getSupportEmail: () => Promise<ICacheAdminEmail> | null;
    currencyToUah: number | null;
    getCurrencyToUah: () => Promise<ICacheCurrencyToUah | null>
    setLocalCurrencyToUah: (localCur: number) => void
    socialLinks: ICacheSocialLinks | null;
    getSocialLinks: () => Promise<ICacheSocialLinksRes | null>;
    evacPhones: string[] | null;
    getEvacPhones: () => Promise<ICachePhones | null>;
    evacPrices: IEvacPrice[] | null;
    getEvacPrices:() => Promise<IEvacPrice[] | null>;
    autoServicePhones: string[] | null ;
    getAutoServicePhones:() => Promise<ICachePhones | null> ;
    autoServicePartsPhones: string[] | null;
    getAutoServicePartPhones:() => Promise<ICachePhones | null> ;
    autoServiceAddress: IAutoServiceAddress | null;
    getAutoServiceAddress:() => Promise<IAutoServiceAddressData | null> ;
    autoServiceAdvantages: string[] | null;
    getAutoServiceAdvantages:() => Promise<ICacheAdvantages[] | null> ;
    usaPhones: string[] | null;
    getUsaPhones: () => Promise<ICachePhones | null>
    usaAdvantages: string[] | null
    getUsaAdvantages: () => Promise<ICacheAdvantages | null>
    usaServices: string[] | null;
    getUsaServices: () => Promise<ICacheServices | null>
}
const CacheContext = createContext<CacheContextType | undefined>(undefined)

type CacheProviderProps = {
    children: ReactNode;
};
export const CacheProvider: FC<CacheProviderProps> = ({children}) =>{
    const [phones, setPhones] = useState<string[] | null>(["+380730113400", "+380670113400", "+380990113400"]);
    const [messengerPhones, setMessengerPhones] = useState<ICacheMessengersPhones | null>(null);
    const [adminEmail, setAdminEmail] = useState<string | null>(null)
    const [supportEmail, setSupportEmail] = useState<string | null>(null)
    const [currencyToUah, setCurrencyToUah] = useState<number | null>()
    const [socialLinks, setSocialLinks] = useState<ICacheSocialLinks | null>(null)
    const [evacPhones, setEvacPhones] = useState<string[] | null>(null)
    const [evacPrices, setEvacPrices] = useState<IEvacPrice[] | null>(null)
    const [autoServicePhones, setAutoServicePhones] = useState<string [] | null>(null)
    const [autoServicePartsPhones, setAutoServicePartsPhones] = useState<string [] | null>(null)
    const [autoServiceAddress, setAutoServiceAddress] = useState<IAutoServiceAddress | null>(null)
    const [autoServiceAdvantages, setAutoServiceAdvantages] = useState<string[] | null>(null)
    const [usaPhones, setUsaPhones] = useState<string[] | null>(null)
    const [usaAdvantages, setUsaAdvantages] = useState<string[] | null>(null)
    const [usaServices, setUsaServices] = useState<string[] | null>(null)
    const [error, setError] = useState<string | null>(null)
    useEffect(() => {
        const localPhonesStr = localStorage.getItem("localPhones")
        if (localPhonesStr){
            try {
                const localPhones: ILocalPhones = JSON.parse(localPhonesStr)
                if(localPhones.time && localPhones.data){
                    const savedTime = new Date(localPhones.time.toString()).getTime()
                    const now = new Date().getTime()
                    const dif = now - savedTime
                    if (dif > updateDataTime){
                        getPhones()
                    }else {
                        setPhones(localPhones?.data)
                    }
                }else {
                    getPhones()
                    console.log("Error to parse data")
                }

            }catch (e){
                getPhones()
                console.log("Error to parse data:" + e)
            }
        } else {
            getPhones()
        }

    }, []);
    useEffect(() => {
        const localMessengerPhonesStr = localStorage.getItem("localMessengerPhones")
        if (localMessengerPhonesStr){
            try {
                const localMessengerPhones: ILocalMessengerPhones = JSON.parse(localMessengerPhonesStr)
                if(localMessengerPhones.time && localMessengerPhones.data){
                    const savedTime = new Date(localMessengerPhones.time.toString()).getTime()
                    const now = new Date().getTime()
                    const dif = now - savedTime
                    if (dif > updateDataTime){
                        getMessengerPhones()
                    }else {
                        setMessengerPhones(localMessengerPhones?.data)
                    }
                }else {
                    getMessengerPhones()
                    console.log("Error to parse data")
                }

            }catch (e){
                getMessengerPhones()
                console.log("Error to parse data:" + e)
            }
        } else {
            getMessengerPhones()
        }

    }, []);
    useEffect(() => {
        const localCurrencyToUahStr = localStorage.getItem('localCurrencyToUah')
        if(localCurrencyToUahStr){
            try{
                const localCurrencyToUah = JSON.parse(localCurrencyToUahStr)
                if(localCurrencyToUah.time && localCurrencyToUah.data){
                    const savedTime = new Date(localCurrencyToUah.time.toString()).getTime()
                    const now = new Date().getTime()
                    const dif = now - savedTime
                    if (dif > updateDataTime){
                        getCurrencyToUah()
                    }else {
                        setCurrencyToUah(localCurrencyToUah?.data)
                    }
                }else {
                    getCurrencyToUah()
                    console.log("Error to parse data")
                }

            }catch (e){
                getCurrencyToUah()
                console.log("Error to parse data:" + e)
            }
        } else {
            getCurrencyToUah()
        }
    }, []);
    useEffect(() => {
        const localSocialLinksStr = localStorage.getItem('localSocialLinks')
        if(localSocialLinksStr){
            try {
                const localSocialLinks = JSON.parse(localSocialLinksStr)
                if (localSocialLinks.time && localSocialLinks.data){
                    const savedTime = new Date(localSocialLinks.time.toString()).getTime()
                    const now = new Date().getTime()
                    const dif = now - savedTime
                    if (dif > updateDataTime){
                        getSocialLinks()
                    }else {
                        setSocialLinks(localSocialLinks?.data)
                    }
                }else {
                    getSocialLinks()
                    console.log("Error to parse data")
                }

            }catch (e){
                getSocialLinks()
                console.log("Error to parse data:" + e)
            }
        }else {
            getSocialLinks()
        }
    }, []);
    useEffect(() => {
        const localSupportEmailStr = localStorage.getItem('localSupportEmail')
        if(localSupportEmailStr){
            try {
                const localSupportEmail = JSON.parse(localSupportEmailStr)
                if (localSupportEmail.time && localSupportEmail.data){
                    const savedTime = new Date(localSupportEmail.time.toString()).getTime()
                    const now = new Date().getTime()
                    const dif = now - savedTime
                    if (dif > updateDataTime){
                        console.log('get')
                        getSupportEmail()
                    }else {
                        setSupportEmail(localSupportEmail?.data)
                    }
                }else {
                    getSupportEmail()
                    console.log("Error to parse data")
                }
            }catch (e){
                getSupportEmail()
                console.log("Error to parse data:" + e)
            }
        }else {
            getSupportEmail()
        }
    }, []);
    const fetchData = async (service: () => any) => {
        try {
            const data = await service()
            return data.data
        }catch (e){
            console.log(`Error: ${e}`)
            setError(e.toString())
            return null
        }
    }
    const getPhones = async () => {
        const newPhones = await fetchData(cacheService.phoneServices.getPhones)
        if(newPhones){
            console.log(newPhones)
            setPhones(newPhones.phones)
            setLocalPhones(newPhones.phones)
        }else {
            setPhones(["+380730113400","+380670113400","+380990113400"])
            const er = "No phones in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newPhones;
    };
    const setLocalPhones = (localPhones:string[]) => {
        if (localPhones){
            const phonesObj:ILocalPhones = {
                time: new Date(),
                data: localPhones
            }
            localStorage.setItem("localPhones", JSON.stringify(phonesObj))
        }
    }
    const getMessengerPhones = async () => {
        const newPhones = await fetchData(cacheService.messengerService.getMessengerPhones)
        if(newPhones){
            setMessengerPhones(newPhones)
            setLocalMessengerPhones(newPhones)
        }else {
            setPhones(["+380730113400","+380670113400","+380990113400"])
            const er = "No phones in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newPhones;
    };
    const setLocalMessengerPhones = (localPhones:ICacheMessengersPhones) => {
        if (localPhones){
            const phonesObj:ILocalMessengerPhones = {
                time: new Date(),
                data: localPhones
            }
            localStorage.setItem("localMessengerPhones", JSON.stringify(phonesObj))
        }
    }
    const getAdminEmail = async () => {
        const newEmail = await fetchData(cacheService.emailService.getEmailAdmin)
        if(newEmail){
            setAdminEmail(newEmail.email)
        }else {
            setAdminEmail('vadik71117111@gmail.com')
            const er = "No email in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newEmail;
    }
    const getSupportEmail = async () => {
        const newEmail = await fetchData(cacheService.emailService.getEmailSupport)
        if(newEmail){
            setSupportEmail(newEmail.email)
            setLocalSupportEmail(newEmail.email)
        }else {
            setSupportEmail('ea777@i.ua')
            const er = "No email in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newEmail;
    }
    const setLocalSupportEmail = (email: string) => {
        if(email){
            const emailObj:ILocalEmail = {
                time: new Date(),
                data: email
            }
            localStorage.setItem("localSupportEmail", JSON.stringify(emailObj))
        }
    }

    const getCurrencyToUah = async () => {
        const newCurrencyToUah = await fetchData(cacheService.currencyService.getCurrencyToUah)
        if (newCurrencyToUah){
            setCurrencyToUah(newCurrencyToUah.currency)
            setLocalCurrencyToUah(newCurrencyToUah.currency)
        }else {
            setCurrencyToUah(40)
            setLocalCurrencyToUah(40)
            const er = "No currency in response, default 40 is provide"
            console.log(er)
            setError(er)
        }
        return newCurrencyToUah
    }
    const setLocalCurrencyToUah = (localCur: number) => {
        if (localCur){
            const currencyToUahObj:ILocalCurrencyToUah = {
                time: new Date(),
                data: localCur
            }
            localStorage.setItem("localCurrencyToUah", JSON.stringify(currencyToUahObj))
        }
    }
    const getSocialLinks = async () => {
        const newSocialLinks:ICacheSocialLinksRes = await fetchData(cacheService.socialLinksService.getSocialLinks)
        if (newSocialLinks){
            setSocialLinks(newSocialLinks.links)
            setLocalSocialLinks(newSocialLinks.links)
        }else {
            const links :ICacheSocialLinks = {
                facebook: "https://www.instagram.com/boltorez.com.ua/",
                    instagram: "https://www.instagram.com/boltorez.com.ua/",
                    avtoPro: "'https://avto.pro/seller/razborka-mercedes-w220/",
                    olx: "https://avtorazborkamercedes.olx.ua/uk/home/" }
            setSocialLinks(links)
            const er = "No links in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newSocialLinks
    }
    const setLocalSocialLinks = (localLinks: ICacheSocialLinks) => {
        if (localLinks){
            const socialLinksObj:ILocalCacheSocialLinks= {
                time: new Date(),
                data: localLinks
            }
            localStorage.setItem("localSocialLinks", JSON.stringify(socialLinksObj))
        }
    }
    const getEvacPhones = async () => {
        const newEvacPhones = await fetchData(cacheService.evacPhones.getPhones)
        if (newEvacPhones){
            setEvacPhones(newEvacPhones.phones)
        }else {
            setEvacPhones(['+380673887770', '+380663887770'])
            const er = "No evac phones in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newEvacPhones
    }
    const getEvacPrices = async () => {
        const newEvacPrices = await fetchData(cacheService.evacPrices.getPrices)
        if (newEvacPrices){
            setEvacPrices(newEvacPrices.prices)
        }else {
            setEvacPrices( [{service:"Мототехніка", price: "500"},
                                {service:"Легкові автомобілі",price: "600"},
                                {service:"Джипи та мікроавтобуси", price: "800"},
                                {service:"Негабаритні вантажі", price: ''}])
            const er = "No evac prices in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newEvacPrices
    }
    const getAutoServicePhones = async () => {
        const newPhones = await fetchData(cacheService.autoService.servicePhones.getPhones)
        if (newPhones){
            setAutoServicePhones(newPhones.phones)
        }else {
            setAutoServicePhones(cacheAutoServiceDefault.servicePhones)
            const er = "No autoService phones in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newPhones
    }
    const getAutoServicePartPhones = async () => {
        const newPhones = await fetchData(cacheService.autoService.partPhones.getPhones)
        if (newPhones){
            setAutoServicePartsPhones(newPhones.phones)
        }else {
            setAutoServicePartsPhones(cacheAutoServiceDefault.partPhones)
            const er = "No autoService phones in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newPhones
    }
    const getAutoServiceAddress = async () => {
        const newAddress = await fetchData(cacheService.autoService.address.getAddress)
        if (newAddress){
            setAutoServiceAddress(newAddress.address)
        }else {
            setAutoServiceAddress(cacheAutoServiceDefault.address)
            const er = "No autoService address in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newAddress
    }
    const getAutoServiceAdvantages = async () => {
        const newAdvantages = await fetchData(cacheService.autoService.advantages.getAdvantages)
        if (newAdvantages){
            setAutoServiceAdvantages(newAdvantages.advantages)
        }else {
            setAutoServiceAdvantages(cacheAutoServiceDefault.advantages)
            const er = "No autoService advantages in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newAdvantages
    }
    const getUsaPhones = async () => {
        const newPhones = await fetchData(cacheService.usa.usaPhones.getPhones)
        if(newPhones){
            setUsaPhones(newPhones.phones)
        }else {
            setUsaPhones(cacheUsaDefault.servicePhones)
            const er = "No usaPhones phones in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newPhones

    }
    const getUsaAdvantages = async () => {
        const newAdvantages = await fetchData(cacheService.usa.usaAdvantages.getAdvantages)
        if (newAdvantages){
            setUsaAdvantages(newAdvantages.advantages)
        }else {
            setUsaAdvantages(cacheUsaDefault.advantages)
            const er = "No usa advantages in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newAdvantages
    }
    const getUsaServices = async () => {
        const newServices = await fetchData(cacheService.usa.usaServices.getServices)
        if(newServices){
            setUsaServices(newServices.services)
        }else {
            setUsaServices(cacheUsaDefault.services)
            const er = "No usa advantages in response, default is provide"
            console.log(er)
            setError(er)
        }
        return newServices
    }

    return(
        <CacheContext.Provider value={{phones, getPhones, setLocalPhones, messengerPhones,
            getMessengerPhones, setLocalMessengerPhones, adminEmail, getAdminEmail,
            supportEmail, getSupportEmail, currencyToUah, getCurrencyToUah, setLocalCurrencyToUah, socialLinks, getSocialLinks,
            evacPhones, getEvacPhones, evacPrices, getEvacPrices, autoServicePhones, getAutoServicePhones,
            autoServicePartsPhones, getAutoServicePartPhones, autoServiceAdvantages, getAutoServiceAdvantages,
        autoServiceAddress, getAutoServiceAddress, usaPhones, getUsaPhones, usaAdvantages, getUsaAdvantages, usaServices, getUsaServices}}>
            {children}
        </CacheContext.Provider>
    )
}
export const useCache = ():CacheContextType => {
    const context = useContext(CacheContext)
    if (!context) {
        throw new Error('useCache must be used within an CacheProvider');
    }
    return context;
}