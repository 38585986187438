import React, {useEffect, useState} from 'react';
import HeaderLogo from "./Logo/HeaderLogo";
import MenuButton from "./MenuButton/MenuButton";
import './headerLeftSideContainerStyles1280.scss'
import {useGetWidth} from "../../../hooks/screenHooks/useGetWidth";

const HeaderLeftSideContainer = () => {
    return (
        <div className="HeaderLeftSide">
            <HeaderLogo/>
        </div>
    );
};

export default HeaderLeftSideContainer;