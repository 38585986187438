import React, {FC, PropsWithChildren} from 'react';
import './mailTelInfoStyles.scss'
interface IProps extends PropsWithChildren{
    sign: string;
    method: string;
    info: string;
    backgroundColor?: string;
    backgroundImage?: string;
    pAid?: string;

}
const MailTelInfoContainer:FC<IProps> = ({sign, info, method, backgroundColor, backgroundImage, pAid}) => {
    return (
        <div className='evacInfo' style={{backgroundColor: backgroundColor, backgroundImage: backgroundImage}}>
            <div className='evacInfoContainer' >
                <p id={`${pAid}P`}>{sign}</p>
                <a href={`${method}: ${info}`} id={`${pAid}A`}>{info}</a>
            </div>
        </div>
    );
};
export default MailTelInfoContainer;