import React, {FC, useEffect, useState} from 'react';
import {IUser} from "../../../interfaces/userInterface";
import './meInfoStyles.scss'
import MeInfoInnerContainer from "./MeInfoInnerContainer/MeInfoInnerContainer";
import {SubmitHandler, useForm} from "react-hook-form";
import {handlePhoneChange} from "../../../services/helpers/registerHelpers";
import {IProfile} from "../../../interfaces/profileInterface";
import {IRegister} from "../../../interfaces/registerInterface";
import {authService} from "../../../services/apiServices/auth/authService";
import {LoadingPage} from "../../../pages/LoadingPage/LoadingPage";
interface IProps{
    me: IUser;
    isChange: boolean;
    setIsChange: (arg: boolean) => void
}
const MeInfo:FC<IProps> = ({me, isChange, setIsChange}) => {

    const {register, handleSubmit} = useForm<IUser>()
    const [errorPhone, setErrorPhone] = useState(false)
    const [errorOther, setErrorOther] = useState<string | null>(null)
    const [isOk, setIsOk] = useState(false)
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if(isChange){
            setIsOk(false)
        }
    }, [isOk, errorPhone, errorOther, isChange]);
    const save:SubmitHandler<IUser> = async (data:any) => {
        const phone = handlePhoneChange(data.phone)
        if(!phone){
            setErrorPhone(true)
            return
        }
        const profile: IProfile = {
            name: data.name,
            surname: data.surname,
            city: data.city
        }
        const user: IUser = {
            email: data.email,
            phone: phone,
            profile: profile
        };
        try{
            setLoading(true);
            const responce = await authService.setMe(user)
            setErrorPhone(false)
            setIsOk(true)
            setIsChange(false)
        }catch (e){
            setIsOk(false)
            setErrorOther(e.toString())
        }finally {
            setLoading(false)
        }
    }
    return (
        <div className='meInfo'>
            {loading ?
                <LoadingPage/>
            :
            <>
                <form id='changeMeInfoForm' onSubmit={handleSubmit(save)}>
                    <MeInfoInnerContainer text={'Імʼя'} val={me?.profile.name} isChange={isChange}  register={register} registerName={'name'} minLength={2}  inputType={'text'}/>
                    <MeInfoInnerContainer text={'Прізвище'} val={me?.profile.surname} isChange={isChange} register={register} registerName={'surname'} minLength={3}  inputType={'text'}/>
                    <MeInfoInnerContainer text={'Телефон'} val={me?.phone} isChange={isChange} register={register} registerName={'phone'} minLength={10}  inputType={'text'}/>
                    <MeInfoInnerContainer text={'email'} val={me?.email} isChange={isChange} register={register} registerName={'email'} minLength={5}  inputType={'email'}/>
                    <MeInfoInnerContainer text={'Населений'} val={me?.profile.city} isChange={isChange} register={register} registerName={'city'} minLength={3}  inputType={'text'}/>

                </form>
                {isOk && <span>Успішно збережено</span>}
                {isChange && < button  form='changeMeInfoForm' className='saveBtn'>Зберегти</button>}
            </>
            }

        </div>
    );
};

export default MeInfo;