import React from 'react';
import {Link} from "react-router-dom";

const HeaderLogo = () => {
    return (
        <>
            <Link className='logoLink' to={'/main'}>
                <img className='logo' src='/headerImages/logo/logo95.png'/>
            </Link>
        </>
    );
};

export default HeaderLogo;