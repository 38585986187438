import React from 'react';
import {Link} from "react-router-dom";
import "../../CheckPageStyles.scss"

const CheckEmail = () => {
    return(
        <div className="checkEmailPage">
            <div className="ActivateText">Ми надіслали вам на пошту інструкцію</div>
            <Link className="linkToLogin" to={'/main'}>На головну</Link>
        </div>
    )
};

export default CheckEmail;