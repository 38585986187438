import {FC, PropsWithChildren, useEffect, useState} from "react";
import {ICartItem} from "../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {useNavigate} from "react-router-dom";
import './cartItemStyles.scss'
import CartItemInnerInfo from "./CartItemInnerInfo/CartItemInnerInfo";
import {useCache} from "../../../../context/cacheContext";
interface IProps extends PropsWithChildren{
    cartItem?: ICartItem,
    deleteItemFromCart: (id:number) => void
    isMobile: boolean;
}

export const CartItem: FC<IProps> = ({cartItem, deleteItemFromCart, isMobile}) => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(`/product_details/${cartItem.id}`);
    };
    const {currencyToUah} = useCache()
    const [cur, setCur] = useState<number>(40)

    useEffect(() => {
        if(currencyToUah){
            setCur(currencyToUah)
        }else {
            setCur(40)
        }
    }, [currencyToUah]);
    return (
        <div className="cartItemContainer" >
            <img src={cartItem?.image} alt="Дякуємо за ваше замовлення"/>
            <div className='cartItemInfoContainer'>
                <div className='itemHeader' onClick={handleNavigate}>{cartItem?.name} {cartItem.brand} {cartItem.model} {cartItem?.oem}</div>
                {!isMobile &&
                    <div className='cartItemInnerInfoContainer'>
                        <CartItemInnerInfo sign={'Код товару'} info={cartItem?.id}/>
                        <CartItemInnerInfo sign={'Бренд'} info={cartItem?.brand}/>
                        <CartItemInnerInfo sign={'Модель'} info={cartItem?.model}/>
                        {cartItem?.oem !== '' &&
                            <CartItemInnerInfo sign={'Каталожний номер'} info={cartItem?.oem}/>
                        }
                    </div>
                }

                <div className='cartPriceContainer'>
                    <p>Ціна</p>
                        <div className='cartPriceInnerContainer'>
                            <h4>${cartItem?.price}</h4>
                            <p>{cartItem?.price * cur} грн</p>
                        </div>


                </div>
            </div>
            <button onClick={(e) => {
                e.stopPropagation();
                deleteItemFromCart(cartItem.id);}}>
                <img src="/cartImages/deleteButton.png" alt="Видалити"/>
            </button>
        </div>
    );
};