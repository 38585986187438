import React, {FC} from 'react';
import {useForm} from "react-hook-form";
import {IProductImport} from "../../../../interfaces/productInterface";
import './importDataFormStyles.scss'
interface IProps{
    importData: (url: IProductImport) => void;
}
const ImportDataLinkForm: FC<IProps> = ({importData}) => {
    const {register, reset, handleSubmit} = useForm<IProductImport>()

    return (
        <div className="importCsvFormDiv">
            <form onSubmit={handleSubmit(importData)}>
                <input type="text" {...register('url')}
                       placeholder={'Введіть посилання на імпорт csv файлу'} required minLength={30}/>
                <button>Імпортувати</button>
            </form>
        </div>
    );
};

export default ImportDataLinkForm;