import React, {useEffect} from 'react';
import {cacheUsaDefault} from "../../../constants/cacheConstants/cacheConstants";
import './usaTextContainerStyles.scss'
import {useCache} from "../../../context/cacheContext";
const UsaTextContainer = () => {
    const {usaServices, getUsaServices} = useCache()
    useEffect(() => {
        if(!usaServices){
            getUsaServices()
        }
    }, [usaServices]);
    return (
        <div className='usaTextContainer'>
            <h2>Доставка авто з США та КОРЕЇ</h2>
            <p>Бажаєте придбати якісне авто з-за кордону без зайвих турбот?  Ми пропонуємо професійні послуги з пригону автомобілів з США та Кореї.</p>
            <h4>Наші послуги:</h4>
            {usaServices &&
                <ul className='servicesUl'>
                    {usaServices ?
                        <>
                            {usaServices.map(advantage => <li>{advantage}</li>)}
                        </>
                        : //ELSE
                        <>
                            {cacheUsaDefault.services.map(advantage => <li>{advantage}</li>)}
                        </>
                    }
                </ul>}

            <p>📞 Зв'яжіться з нами вже сьогодні!</p>
            <p>Телефон: <a href={`tel:${cacheUsaDefault.servicePhones[0]}`}>{cacheUsaDefault.servicePhones[0]}</a></p>
            <p>Ми забезпечуємо безпечну та швидку доставку прямо до вашого дому. Довіртеся нам, і ваша мрія про ідеальний автомобіль стане реальністю!</p>
        </div>
    );
};

export default UsaTextContainer;