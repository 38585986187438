import {SubmitHandler, useForm} from "react-hook-form";
import {IAuth} from "../../../interfaces/authInterface";
import {authService} from "../../../services/apiServices/auth/authService";
import {AxiosError} from "axios";
import {useState} from "react";
import {useAuth} from "../../../context/authContext";
import {router} from "../../../router";
import {Link} from "react-router-dom";
import {LoadingPage} from "../../LoadingPage/LoadingPage";

export const LoginPage = () => {
    const {login} = useAuth()
    const {register, reset, handleSubmit} = useForm<IAuth>()
    const [loading, setLoading] = useState(false);
    const [incorrectData, setIncorrectData] = useState(false)
    const [errorOther ,setErrorOther] = useState(false)
    const loginUser:SubmitHandler<IAuth> = async (user) => {
        try {
            setLoading(true);
            const me = await authService.login(user)
            setIncorrectData(false)
            setErrorOther(false)
            login(me)
            await router.navigate('/main')
            return me
        } catch (e) {
            const err = e as AxiosError
            if (err.response) {
                console.log(`Помилка сервера: ${err.response.status} - ${err.response.data}`)
                console.log(err.response.data)
                console.log('lox')
                setIncorrectData(true)
            } else {
                console.error('Помилка: ', err.message);
                setErrorOther(true)
            }
        }
        finally {
            setLoading(false)
        }

    }

        return (
            <>
                {
                    loading?
                        <LoadingPage/>
                        :  //ELSE
                        <div className='LoginRegisterFormDiv'>
                            <form onSubmit={handleSubmit(loginUser)}>
                                <input type='text' placeholder={'Телефон'} {...register('phone')} required maxLength={13} minLength={13}/>

                                <input type='password' placeholder={'Пароль'} {...register('password')}

                                       title="Пароль повинен містити принаймні одну велику літеру, одну маленьку літеру, спецсимвол, одну цифру і бути не менше 8 символів та не більше 128" required/>
                                {incorrectData&&<span>Неправильний телефон або пароль</span>}
                                {errorOther&&<span>Упс, невідома помилка сервера, спробуйте будь сласка пізніше або напишіть нам</span>}
                                <button disabled={loading}>Увійти</button>
                            </form>
                            <Link id='registerLink' to='/register'>Не маєте аккаунта? Зареєструйтеся щоб отримувати бонуси</Link>
                            <Link id='registerLink' to='/password_recovery_request'>Забули пароль?</Link>
                        </div>
                }

            </>
        )
    }