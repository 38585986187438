import React, {FC, PropsWithChildren, useState} from 'react';
import {IProductImportCsvResponse} from "../../../../../interfaces/productInterface";
import AddedItemComponent from "./AddedItemComponent/AddedItemComponent";
import FullAddedItemsComponent from "../FullAddedItemsComponent";
interface IProps extends PropsWithChildren{
    data: IProductImportCsvResponse
}

const AddedItemsComponent: FC<IProps> = ({data}) => {
    return (
        <div>
            {data.success ?
                <>
                    <h1>Імпорт успішний</h1>
                    <FullAddedItemsComponent new_items_list={data.result.new_products_list}
                                             text={`Товарів додано: ${data.result.new_products}`}/>

                    <FullAddedItemsComponent new_items_list={data.result.products_deleted_list}
                                             text={`Товарів деактивовано: ${data.result.products_deleted}`}/>

                    <FullAddedItemsComponent new_items_list={data.result.new_brands_list}
                                             text={`Марок додано: ${data.result.new_brands}`}/>

                    <FullAddedItemsComponent new_items_list={data.result.new_models_list}
                                             text={`Моделей додано: ${data.result.new_models}`}/>

                    <FullAddedItemsComponent new_items_list={data.result.new_manufacturers_list}
                                             text={`Виробників додано: ${data.result.new_manufacturers}`}/>

                    <FullAddedItemsComponent new_items_list={data.result.new_categories_list}
                                             text={`Категорій додано: ${data.result.new_categories}`}/>
                </>
                : //ELSE
                <>
                    <h2>Якась помилка, спробуйте ще</h2>
                </>
            }
        </div>
    );
};

export default AddedItemsComponent;