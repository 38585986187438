import React, {useEffect, useState} from 'react';
import {useCache} from "../../../../../../context/cacheContext";
import {ICachePhonesForCreate} from "../../../../../../interfaces/cacheInterface";
import {cacheService} from "../../../../../../services/apiServices/cache/cacheService";
import {AdminLoadingPage} from "../../../../AdminLoadingPage/AdminLoadingPage";
import ChangePhonesInputContainer from "../../../Containers/ChangePhonesInputContainer/ChangePhonesInputContainer";

const ChangeServicePartsPhones = () => {
    const {autoServicePartsPhones, getAutoServicePartPhones} = useCache()
    const [inputs, setInputs] = useState(['']);
    const [changeInfo, setChangeInfo] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (autoServicePartsPhones) {
            const newInputs = autoServicePartsPhones.map(phone => phone);
            setInputs(newInputs)
        }else {
            console.log('no phones')
            getAutoServicePartPhones()
        }

    }, [autoServicePartsPhones]);
    useEffect(() => {
    }, [changeInfo]);
    const handleSubmit = async (event:any) => {
        const data:ICachePhonesForCreate = {phones: inputs}
        try {
            setLoading(true)
            const res = await cacheService.autoService.partPhones.setPhones(data)
            setChangeInfo('Телефони запчастин з авто сервіса успішно додано')
            setInputs(res.data.phones)
            return res
        }catch (e){
            console.log(`Error: ${e}`)
            setChangeInfo('Помилка сервера: ' + e.toString())
            return null
        }finally {
            setLoading(false)
        }
    }
    return (
        <>
            {
                loading ?
                    <AdminLoadingPage/>
                    :
                    <div className='changePhones'>
                        <form className='changePhonesForm' onSubmit={handleSubmit}>
                            <div className='innerChangeMessengerFormDiv'>
                                <ChangePhonesInputContainer phones={autoServicePartsPhones} changeInfo={changeInfo}
                                                            header={"Телефони запчастин з авто-сервіса"} inputs={inputs} setInputs={setInputs}/>
                            </div>
                            <button>Зберегти</button>
                        </form>
                    </div>
            }
        </>
    );
};

export default ChangeServicePartsPhones;