import React, {ChangeEvent, useEffect, useState} from 'react';
import {ecommerceService} from "../../../services/apiServices/ecommerce/ecommerceService";
import {IOrderPage} from "../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {useSearchParams} from "react-router-dom";
import Orders from "../../../components/AdminComponents/Orders/Orders";
import {Pagination} from "@mui/material";
import {LoadingPage} from "../../LoadingPage/LoadingPage";
import './OrdersListStyles.scss'
import {AdminLoadingPage} from "../../../components/AdminComponents/AdminLoadingPage/AdminLoadingPage";
import OrderSearchInputComponent from "./SearchOrdersComponents/OrderSearchInputComponent";

const OrdersListPage = () => {
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState<IOrderPage | null>(null)
    const [query, setQuery] = useSearchParams({page:'1'})
    const [pagesCount, setPagesCount] = useState(5)
    const [trigger, setTrigger] = useState(false)
    useEffect(() => {
        FetchData()
    }, [query.get('page'), trigger]);
    useEffect(() => {

    }, [orders]);
    const FetchData = async () => {
        try {
            setLoading(true)
            const response = await ecommerceService.getOrderList(+query.get('page'))
            if (response?.data.results && Array.isArray(response?.data.results)) {
                setOrders(response?.data)
                setPagesCount(response.data.total_pages)
            }else {
                console.error('Отримані дані не є масивом');
            }
        }catch (e){
            console.log(e)
        }
        finally {
            setLoading(false)
        }
    }
    const handleChangePage = (event: ChangeEvent<unknown>, page:number) => {
        setQuery(prev => {prev.set('page', (page).toString())
            return prev
        })
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    return (
        <>
            {
            loading?
                <AdminLoadingPage/>
            : //ELSE
                <div className='orders'>
                    <OrderSearchInputComponent setOrders={setOrders}/>

                    <Orders setLoading={setLoading} orders={orders?.results} setTrigger={setTrigger}/>
                    {pagesCount > 1 &&
                        <div className='paginationDiv'>
                            <Pagination count={pagesCount} page={+query.get("page")} onChange={handleChangePage}/>
                        </div>
                    }

                </div>
            }

        </>

    );
};

export default OrdersListPage;