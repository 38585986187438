import {apiService, IRes} from "../apiService";
import {
    ICacheAdminEmail, ICacheAdminEmailForCreate, ICacheCurrencyToUah, ICacheCurrencyToUahForCreate,
    ICacheMessengersPhones,
    ICachePhones,
    ICachePhonesForCreate, ICacheSocialLinks, ICacheSocialLinksRes
} from "../../../interfaces/cacheInterface";
import {urls} from "../../../constants";
import {IEvacPrice, IEvacPrices} from "../../../interfaces/evacInterfaces/evacPricesInterfaces";
import {dark} from "@mui/material/styles/createPalette";
import {
    IAutoServiceAddressData,
    ICacheAdvantages, ICacheServices
} from "../../../interfaces/autoServiceInterfaces/cacheAutoServiceInterfaces";

export const cacheService = {
    phoneServices:{
        getPhones: ():IRes<ICachePhones> => apiService.get(urls.cache.phones),
        setPhones: (data: ICachePhonesForCreate):IRes<ICachePhonesForCreate> => apiService.post(urls.cache.phones, data)
    },
    messengerService:{
        getMessengerPhones: ():IRes<ICacheMessengersPhones> => apiService.get(urls.cache.messengersPhones),
        setMessengerPhones: (data: ICacheMessengersPhones):IRes<ICacheMessengersPhones> => apiService.post(urls.cache.messengersPhones, data)
    },
    emailService:{
        getEmailAdmin: ():IRes<ICacheAdminEmail> => apiService.get(urls.cache.adminEmail),
        setEmailAdmin: (data: ICacheAdminEmailForCreate): IRes<ICacheAdminEmail> => apiService.post(urls.cache.adminEmail, data),
        getEmailSupport: ():IRes<ICacheAdminEmail> => apiService.get(urls.cache.supportEmail),
        setEmailSupport: (data: ICacheAdminEmail): IRes<ICacheAdminEmail> => apiService.post(urls.cache.supportEmail, data),

    },
    currencyService:{
        getCurrencyToUah: ():IRes<ICacheCurrencyToUah> => apiService.get(urls.cache.currencyToUAH),
        setCurrencyToUah: (data: ICacheCurrencyToUahForCreate): IRes<ICacheCurrencyToUah> => apiService.post(urls.cache.currencyToUAH, data)
    },
    socialLinksService:{
        getSocialLinks: ():IRes<ICacheSocialLinksRes> => apiService.get(urls.cache.socialLinks),
        setSocialLinks:(data: ICacheSocialLinks): IRes<ICacheSocialLinksRes> => apiService.post(urls.cache.socialLinks, data)
    },
    evacPhones:{
        getPhones: ():IRes<ICachePhones> => apiService.get(urls.cache.evacPhones),
        setPhones: (data: ICachePhonesForCreate):IRes<ICachePhonesForCreate> => apiService.post(urls.cache.evacPhones, data)
    },
    evacPrices:{
        getPrices: ():IRes<IEvacPrices> => apiService.get(urls.cache.evacPrices),
        setPrices: (data: IEvacPrices):IRes<IEvacPrices> => apiService.post(urls.cache.evacPrices, data)
    },
    autoService:{
        servicePhones:{
            getPhones: ():IRes<ICachePhones> => apiService.get(urls.cache.autoServicePhones),
            setPhones: (data: ICachePhonesForCreate):IRes<ICachePhonesForCreate> => apiService.post(urls.cache.autoServicePhones, data)
        },
        partPhones:{
            getPhones: ():IRes<ICachePhones> => apiService.get(urls.cache.autoServicePartsPhones),
            setPhones: (data: ICachePhonesForCreate):IRes<ICachePhonesForCreate> => apiService.post(urls.cache.autoServicePartsPhones, data)
        },
        address:{
            getAddress: ():IRes<IAutoServiceAddressData> => apiService.get(urls.cache.autoServiceAddress),
            setAddress: (data: IAutoServiceAddressData):IRes<IAutoServiceAddressData> => apiService.post(urls.cache.autoServiceAddress, data)
        },
        advantages:{
            getAdvantages: ():IRes<ICacheAdvantages> => apiService.get(urls.cache.autoServiceAdvantages),
            setAdvantages: (data:ICacheAdvantages) => apiService.post(urls.cache.autoServiceAdvantages, data)
        }
    },
    usa:{
        usaPhones:{
            getPhones: ():IRes<ICachePhones> => apiService.get(urls.cache.usaPhones),
            setPhones: (data: ICachePhonesForCreate):IRes<ICachePhonesForCreate> => apiService.post(urls.cache.usaPhones, data)
        },
        usaAdvantages: {
            getAdvantages: ():IRes<ICacheAdvantages> => apiService.get(urls.cache.usaAdvantages),
            setAdvantages: (data:ICacheAdvantages) => apiService.post(urls.cache.usaAdvantages, data)
        },
        usaServices: {
            getServices: ():IRes<ICacheServices> => apiService.get(urls.cache.usaServices),
            setServices: (data:ICacheServices):IRes<ICacheServices> => apiService.post(urls.cache.usaServices, data)
        }
    }
}