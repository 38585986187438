import React, {FC, PropsWithChildren} from 'react';
import {deliverTypeData} from "../../../../constants/MakeOrderFormConstants/makeOrderFormConstants";
import HorizontalRadioGroup from "../HorizontalRadioGroup/HorizontalRadioGroup";
interface IProps extends PropsWithChildren{
    deliverType: string
    setDeliverType: (type: string) => void
}
const DeliverTypeComponent:FC<IProps>= ({deliverType, setDeliverType}) => {
    return (
        <div className='makeOrderInnerFormContainer' id='orderFormLeftInnerContainer'>
            <h3>Оберіть спосіб доставки</h3>
            <div className='radioGroupContainer'>
                {deliverTypeData.map(data => <HorizontalRadioGroup key={data.id} imgSrc={data.imgSrc} valueName={data.valueName} inputName={data.inputName} label={data.label} func={setDeliverType} condition={true}/>)}

            </div>
        </div>
    );
};

export default DeliverTypeComponent;