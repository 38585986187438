import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import './headerContactsStyles.scss'
import {useCache} from "../../../context/cacheContext";
import HeaderSocialLinks from "../RightSideContainer/SocialLinks/HeaderSocialLinks";
import {IHeaderConfig, IHeaderContactsConfigs} from "../../../interfaces/configInterfaces/headerConfigInterface";
interface IProps extends PropsWithChildren{
    isMobile: boolean;
    configs: IHeaderConfig
}
const HeaderContacts:FC<IProps> = ({isMobile, configs}) => {
    const [showMenu, setShowMenu] = useState(false);
    const phones = configs.contactsConfigs.phones
    const [mainPhone, setMainPhone] = useState('');
    const [additionalPhones, setAdditionalPhones] = useState([]);
    useEffect(() => {
        if (phones && phones.length > 0) {
            if (isMobile){
                setAdditionalPhones(phones)
            }else {
                setMainPhone(phones[0]);
                setAdditionalPhones(phones.slice(1));
            }
        }

    }, [phones]);
    const handleMouseEnter = () => {
        setShowMenu(true);
    };

    const handleMouseLeave = () => {
        setShowMenu(false);
    };

    return (
        <>
            {!isMobile ?
                <div className="contacts" onMouseEnter={handleMouseEnter}>
                    <a href={`tel:${mainPhone}`} style={configs?.contactsConfigs?.contactsStyles?.aStyles}>{mainPhone} </a>
                    <img className='openButtonImg' src='/headerImages/contactImages/OpenBtn.png' alt='open'/>
                    {showMenu && (
                        <div className="additionalContacts" style={configs?.contactsConfigs?.contactsStyles?.containerStyles}>
                            {additionalPhones.map((phone, index) => (
                                <a key={index} href={`tel:${phone}`} style={configs?.contactsConfigs?.contactsStyles?.aStyles}>{phone}</a>
                            ))}
                        </div>
                    )}
                </div>
                :
                <div className="mobileHeaderContacts" onMouseEnter={handleMouseEnter}>
                    <img src = {`/headerImages/contactImages/${configs.contactsConfigs.mobileImg}.png`}alt='phones'/>
                    {showMenu && (
                        <div className="additionalContacts" style={configs?.contactsConfigs?.contactsStyles?.containerStyles}>
                            <HeaderSocialLinks configs={configs?.rightConfigs?.messengerLinksConfigs}/>
                            <div className='additionalPhones' style={configs?.contactsConfigs?.contactsStyles?.containerStyles}>
                                {additionalPhones.map((phone, index) => (
                                    <a key={index} href={`tel:${phone}`} style={configs?.contactsConfigs?.contactsStyles?.aStyles}>{phone}</a>
                                ))}
                            </div>

                        </div>
                    )}
                </div>
            }
        </>

    );
};

export default HeaderContacts;
