import React from 'react';
import {Link} from "react-router-dom";
import './youAreNotAdminStyles.scss'
const YouAreNotAdmin = () => {
    return (
        <div className='youAreNotAdmin'>
            <h3>Ви не адмін</h3>
            <Link to='/main'>Повернутись на головну</Link>
            <p>або</p>
            <Link to='/login'>Увійти</Link>
        </div>
    );
};

export default YouAreNotAdmin;