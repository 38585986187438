import React, {FC, PropsWithChildren, useEffect} from 'react';
import './tagLineStyles.scss'
import {useLocation} from "react-router-dom";
import TaglineText from "./TaglineText/TaglineText";
import TaglineImage from "./TaglineImage/TaglineImage";

const TagLineComponent = () => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash === '#tagLine') {
            const element = document.getElementById('tagLine');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (

        <div className='tagLine' id="tagLine">
            <div className='tagLineContainer'>
                <TaglineText/>
                <TaglineImage/>

            </div>
        </div>

    );
};

export default TagLineComponent;