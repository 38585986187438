import React from 'react';
import {featuresDataInEvac} from "../../../constants/featuresConstants/featuresConstants";
import EvacFeature from "./EvacFeature/EvacFeature";
import './evacFeaturesStyles.scss'
const EvacFeatures = () => {
    return (
        <div className='evacFeatures'>
            <div className='evacFeaturesContainer'>
                {featuresDataInEvac.map(feature => <EvacFeature key={feature.id} props={feature}/>)}
            </div>
        </div>
    );
};

export default EvacFeatures;