import React, {FC, PropsWithChildren} from 'react';
import {Link} from "react-router-dom";
interface IProps extends PropsWithChildren{
    infoDivClass: string
}
const FooterAbout:FC<IProps> = ({infoDivClass}) => {
    return (
        <div className={infoDivClass}>
            <h5>Інформація:</h5>
            <Link to='/main'>Про нас</Link>
            <Link to='/main'>Доставка та гарантія</Link>
            <Link to='/main'>Політика безпеки</Link>
            <Link to='/main'>Умови гарантії</Link>
            <Link to='/main'>Мапа сайту</Link>
        </div>
    );
};

export default FooterAbout;