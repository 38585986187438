import React, {useEffect} from 'react';
import './usaAdvantagesStyles.scss'
import {useCache} from "../../../context/cacheContext";
const UsaAdvantages = () => {
    const {usaAdvantages, getUsaAdvantages} = useCache()
    useEffect(() => {
        if(!usaAdvantages){
            getUsaAdvantages()
        }
    }, [usaAdvantages]);
    return (
        <div className='usaAdvantages'>
            <img src={'/UsaImages/usaAdvantagesImage.svg'}/>
            <h3>Наші переваги:</h3>
            <div className='usaAdvantagesContainer'>
                {usaAdvantages &&
                <>
                    {usaAdvantages.map(advantage => <p id='usaAdvantage'>- {advantage}</p>)
                }
                </>}
            </div>
        </div>
    );
};

export default UsaAdvantages;