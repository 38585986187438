import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import './VerifyEmailContainerStyles.scss'
import {authService} from "../../../services/apiServices/auth/authService";
import {IVerifyEmail} from "../../../interfaces/authInterface";
import {router} from "../../../router";
import {SubmitHandler} from "react-hook-form";
import {useAuth} from "../../../context/authContext";
import MainMailForm from "./MailForm/MainMailForm";
interface IProps{
    isMobile: boolean;
}
const VerifyEmailContainer:FC<IProps> = ({isMobile}) => {
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false); // Доданий стан для чекбоксу
    const {me, isAuthenticated} = useAuth()
    const [userEmail, setUserEmail] = useState<string | null>('')
    const [isEmailInput, setIsEmailInput] = useState(true)
    const [canVerifyEmail, setCanVerifyEmail] = useState(false)
    useEffect(() => {
        if(isAuthenticated){
            if(me && me.email){
                setUserEmail(me.email)
            }
            setCanVerifyEmail(true)
        }
    }, [isEmailInput, me]);
    const sendEmail: SubmitHandler<IVerifyEmail> = async (userEmailObj) => {
        if(canVerifyEmail){
            userEmailObj.email = userEmail
            try{
                await authService.verifyEmail(userEmailObj)
                setIsEmailInput(false)
            }catch (e){
                console.log("error: " + e)
                setIsEmailInput(true)
            }finally {
                setLoading(false)
            }
        }else {
            router.navigate('/register')
        }

    }
    const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUserEmail(e.target.value)
    }
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    return (
        <div className='mail'>
            <div className='mailContainer'>
                {isEmailInput ?
                    <>
                        {!isMobile && <img src='/footerImages/mail/mail.png'/>}
                        <div className='mailInnerFormContainer'>
                            <p>100 бонусів за підписку на наші оновлення!</p>
                            <MainMailForm sendEmail={sendEmail} userEmail={userEmail} handleEmailInputChange={handleEmailInputChange} handleCheckboxChange={handleCheckboxChange} isChecked={isChecked}/>
                        </div>
                        {!isMobile && <img src='/footerImages/mail/mail.png'/>}
                    </>
                    : //ELSE
                    <p>Перевірте вашу пошту</p>
                }
            </div>
        </div>
    );
};

export default VerifyEmailContainer;



