import {StylesConfig} from "react-select";

export const novaSelectInputStyle1200: StylesConfig<any, false, any> = {
    control: (base) => ({
        ...base,
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: '1pxSolidBlack',
        boxShadow: "none",
        backgroundColor: '#F2F0F8',
        borderRadius: "0",

    }),
    menu: (base) => ({
        ...base,
        fontSize: "20px",
        backgroundColor: '#F2F0F8'
    }),
    placeholder: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "20px",
        color: "#696969",
        marginBottom: "15px"
    }),
    singleValue: (provided) => ({
        ...provided,
        textAlign: "center",
        fontSize: "20px",
        marginBottom: "15px"
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        marginBottom: "15px"
    })
};
export const novaSelectInputStyle1000: StylesConfig<any, false, any> = {
    control: (base) => ({
        ...base,
        border: '0',
        boxShadow: "none",
        height: "100%",
        backgroundColor: '#F2F0F8',
        borderRadius: "0",
    }),
    menu: (base) => ({
        ...base,
        fontSize: "18px",
        backgroundColor: '#F2F0F8'
    }),
    placeholder: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "20px",
        color: "#696969",
        marginBottom: "15px"
    }),
    singleValue: (provided) => ({
        ...provided,
        textAlign: "center",
        fontSize: "18px",
        marginBottom: "15px"
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        marginBottom: "15px"
    })
};
export const novaSelectInputStyle770: StylesConfig<any, false, any> = {
    control: (base) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        height: "100%",
        backgroundColor: '#F2F0F8',
        borderRadius: "0",
    }),
    menu: (base) => ({
        ...base,
        fontSize: "16px",
        backgroundColor: '#F2F0F8'
    }),
    placeholder: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "16px",
        color: "#696969",
        marginBottom: "15px"
    }),
    singleValue: (provided) => ({
        ...provided,
        textAlign: "center",
        fontSize: "16px",
        marginBottom: "15px"
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        marginBottom: "15px"
    })
};
export const novaSelectInputStyle200: StylesConfig<any, false, any> = {
    control: (base) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        height: "100%",
        backgroundColor: '#F2F0F8',
        borderRadius: "0",
    }),
    menu: (base) => ({
        ...base,
        fontSize: "14px",
        backgroundColor: '#F2F0F8'
    }),
    placeholder: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "14px",
        color: "#696969",
        marginBottom: "13px"
    }),
    singleValue: (provided) => ({
        ...provided,
        textAlign: "center",
        fontSize: "14px",
        marginBottom: "13px"
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        marginBottom: "13px"
    })
};