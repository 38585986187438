import React, {FC, PropsWithChildren} from 'react';
import {IUser, IUserInOrder} from "../../../../../interfaces/userInterface";
import {IOrder} from "../../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
interface IProps extends PropsWithChildren{
    order: IOrder;
}
const UserInOrder:FC<IProps> = ({order}) => {
    return (
        <div className='orderRowInfo' id='orderDeliverInfo'>
                <p>Імʼя: <h4>{order.this_user_name}</h4></p>
                <p>Прізвище: <h4>{order.this_user_surname}</h4></p>
                {order.this_user_middlename && <p>Побатькові: <h4>{order?.this_user_middlename}</h4></p>}
                <p>Телефон: <h4>{order.this_user_phone}</h4></p>
            {order.this_user_email && <p>email: <h4>{order?.this_user_email}</h4></p>}




        </div>
    );
};

export default UserInOrder;