import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {IAdvantagesProps} from "../../../../constants/advantagesConstants/advantagesConstants";
interface IProps extends PropsWithChildren{
    advantage: IAdvantagesProps
}
const Advantage:FC<IProps> = ({advantage}) => {
    const [id, setId] = useState('')
    useEffect(() => {
        if (advantage.id % 2 === 0){
            setId('even')
        }
        else {
            setId('odd')
        }
    }, []);
    return (
        <div className='advantage' id={id}>
            <img src={advantage.imageSrc} alt={advantage.alt}/>
            <div className='advantageSign'>
                <h2>{advantage.header}</h2>
                <p>{advantage.text}</p>
            </div>
        </div>
    );
};

export default Advantage;