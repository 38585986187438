import React, {FC, PropsWithChildren} from 'react';
import './totalPriceStyles.scss'
interface IProps extends PropsWithChildren{
    total: number;
    cur: number;
    canBePaid: boolean;
}
const TotalPriceContainer:FC<IProps> = ({total, cur, canBePaid}) => {
    return (
        <div className='totalPriceContainer'>
            <div className='innerTotalPriceContainer'>
                <p>До сплати:</p>
                <h2>${total}</h2>
                <h3>{total * cur} грн</h3>
            </div>
            {!canBePaid && <p>(Без урахування товару на який не вказана ціна!)</p>}
        </div>
    );
};

export default TotalPriceContainer;