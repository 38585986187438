import React, {FC, PropsWithChildren} from 'react';
import {IOrder} from "../../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {useCache} from "../../../../../context/cacheContext";
import OrderTotalPrice from "./OrderTotalPrice/OrderTotalPrice";
interface IProps extends PropsWithChildren{
    currencyToUah: number
    order: IOrder
}
const AdminOrderPriceContainer:FC<IProps> = ({order, currencyToUah}) => {
    return (
        <div className='orderRowInfo' id='orderDeliverInfo'>
            <OrderTotalPrice currencyToUah={currencyToUah} totalPrice={order.cart.total_price}/>
            {order.isPaid ? <p style={{color:"green"}}>Сплачено</p> : <p>Накладений платіж</p>}
        </div>
    );
};

export default AdminOrderPriceContainer;