import {apiService, IRes} from "../apiService";
import {urls} from "../../../constants";
import {IProduct, IProductPage} from "../../../interfaces/productInterface";



const productService = {
    getByBrand:(id:string, page:number):IRes<IProductPage> => apiService.get(`${urls.product.byBrand}/${id}?page=${page}`),
    getByModel:(modelId:string, page:number):IRes<IProductPage> => apiService.get(`${urls.product.byModel}/${modelId}?page=${page}`),
    getByCategory:(id:string, page:number):IRes<IProductPage> => apiService.get(`${urls.product.byCategory}/${id}?page=${page}`),
    getByCategoryAndBrand: (categoryId:string ,brandId: string, page:number):IRes<IProductPage> => apiService.get(`${urls.product.byCategoryAndBrand}/${categoryId}/${brandId}?page=${page}`),
    getByCategoryAndModel: (categoryId:string ,modelId: string, page:number):IRes<IProductPage> => apiService.get(`${urls.product.byCategoryAndModel}/${categoryId}/${modelId}?page=${page}`),
    getByManufacturer:(id:string, page:number):IRes<IProductPage> => apiService.get(`${urls.product.byManufacturer}/${id}?page=${page}`),
    getBySearch:(params:string, page:number):IRes<IProductPage> => apiService.get(`${urls.product.search}?params=${params}&page=${page}`),
    getDetails: (id: string): IRes<IProduct> => apiService.get(`${urls.product.details}/${id}`),
}
export {
    productService
}