import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {IProductImport, IProductImportCsvResponse} from "../../../interfaces/productInterface";
import {LoadingPage} from "../../LoadingPage/LoadingPage";
import {useAuth} from "../../../context/authContext";
import AddedItemsComponent from "../../../components/AdminComponents/ImportDataCsvComponents/FullAddedItemsComponent/AddedItemsComponents/AddedItemsComponent";
import ImportDataLinkForm from "../../../components/AdminComponents/ImportDataCsvComponents/ImportDataLinkForm/ImportDataLinkForm";
import {testResultForCSV} from "../../../components/TestComponents(For Dev Only)/testObjectForImportData";
import {importDataService} from "../../../services/apiServices/importData/importDataService";
import YouAreNotAdmin from "../../../components/AdminComponents/YouAreNotAdmin/YouAreNotAdmin";
import './ImportDataCSVStyles.scss'
const ImportDataCsvPage = () => {
    const {me} = useAuth()
    const [isSuperuser, setIsSuperuser] = useState<boolean | null>(false)
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IProductImportCsvResponse | null>(null)
    useEffect(() => {
        setIsSuperuser(me?.is_superuser)
    }, [me]);
    const importData:SubmitHandler<IProductImport> = async (url) =>{
        try {
            setLoading(true)
            const response = await importDataService.importDataCsv(url)
            setData(response.data);
        }catch (e){
            console.log("Error: " + e)
        }finally {
            setLoading(false)
        }

    }
    const testImport = () => {
        const response = testResultForCSV
        setData(response);
    }
    return (
        <>
            {loading ?
                    <LoadingPage/>
                    :  //ELSE
                    <div className='importData'>
                        <h2>Імпорт товару до бази даних</h2>
                        <ul>
                            <h4>Попередження:</h4>
                            <li>Рекомендовано робити імпорт тільки на локальному сервері</li>
                            <li>Імпортувати товар можна тільки з Bazon</li>
                            <li>Перед імпортом рекомендовано перевірити чи створена резервна копія БД</li>


                        </ul>
                        <ImportDataLinkForm importData={importData}/>
                        {data && (<AddedItemsComponent data={data}/>)}
                    </div>
            }
        </>
    );
};


export default ImportDataCsvPage;