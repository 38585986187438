import * as React from 'react';
import './LoginRegisterStyles.scss'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {FC, PropsWithChildren, useEffect, useState} from "react";
import {LoginPage} from "./LoginRegister/LoginPage";
import {RegisterPage} from "./LoginRegister/RegisterPage";
import {useAuth} from "../../context/authContext";
import {Link} from "react-router-dom";

interface IProps extends PropsWithChildren{
    startValue: "1" | "2"
}
export const LoginRegisterPage:FC<IProps> = ({startValue}) => {
    const {isAuthenticated} = useAuth()

    const [value, setValue] = useState<"1" | "2">(startValue);
    useEffect(() => {

    }, [isAuthenticated]);
    useEffect(() => {
        setValue(startValue)
    }, [startValue])
    const handleChange = (event: React.SyntheticEvent, newValue: "1" | "2") => {
        setValue(newValue);
    };
    return (
        <div className='loginRegisterPage'>
            {!isAuthenticated ?
                <Box sx={{
                    width: '100%',
                    typography: 'body1',
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#495990'

                    },
                    '& .MuiButtonBase-root': {
                        color: "#838383",
                        fontFamily: "Montserrat Alternates"
                    },
                    '& .MuiButtonBase-root.Mui-selected': {
                        color: "#495990",
                        fontFamily: "Montserrat Alternates"
                    }
                }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList  onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Увійти" value="1" />
                                <Tab label="Зареєструватися" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <LoginPage/>
                        </TabPanel>
                        <TabPanel value="2">
                            <RegisterPage/>
                        </TabPanel>
                    </TabContext>
                </Box>
                :
                <div style={{display:"flex", alignItems:'center', flexDirection:"column"}}>
                    <h2>Вже залогований</h2>
                    <Link to={'/main'} style={{textDecoration:"none"}}>На головну</Link>
                </div>
            }

        </div>
    );
};