import React, {FC, PropsWithChildren} from 'react';
interface IProps extends PropsWithChildren{
    infoDivClass: string
}
const FooterWorkTime:FC<IProps> = ({infoDivClass}) => {
    return (
        <div className={infoDivClass}>
            <h5>Час роботи:</h5>
            <div className='footerWorkTimeText'>
                <p>Пн-Пт: </p>
                <p>9.00 - 18.00</p>
            </div>
            <div className='footerWorkTimeText'>
                <p>Сб:</p>
                <p>9.00 - 15.00</p>
            </div>
        </div>
    );
};

export default FooterWorkTime;