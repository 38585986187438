import TagLineComponent from "../../components/MainPageComponents/MainPageTopContainer/TagLineComponent/TagLineComponent";
import {SelectCarComponent} from "../../components/MainPageComponents/MainPageTopContainer/SelectCarComponent/SelectCarComponent";
import AdvantagesComponent from "../../components/MainPageComponents/AdvantagesComponent/AdvantagesComponent";
import FeaturesComponent from "../../components/MainPageComponents/FeaturesComponent/FeaturesComponent";
import {useAuth} from "../../context/authContext";
import React, {useEffect, useState} from "react";
import VerifyEmailContainer from "../../components/MainPageComponents/VerifyEmailContainer/VerifyEmailContainer";
import TaglineText from "../../components/MainPageComponents/MainPageTopContainer/TagLineComponent/TaglineText/TaglineText";
import TaglineImage from "../../components/MainPageComponents/MainPageTopContainer/TagLineComponent/TaglineImage/TaglineImage";
import './mainPageStyles.scss'
import {useGetWidth} from "../../hooks/screenHooks/useGetWidth";
import MainPageTopContainer from "../../components/MainPageComponents/MainPageTopContainer/MainPageTopContainer";
import MainPageInfoComponent from "../../components/MainPageComponents/MainPageInfoComponent/MainPageInfoComponent";
const MainPage = () => {
    const [isMobile, setIsMobile] = useState(false)
    const screenWidth = useGetWidth()
    useEffect(() => {
        handleResize()
    }, [screenWidth]);
    const handleResize = () => {
        if (screenWidth === 200) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };
    const {isEmailActivated, isAuthenticated} = useAuth()
    useEffect(() => {

    }, [isEmailActivated]);
    return (
        <div className='mainPage'>
            {!isMobile ?
            <>
                <MainPageTopContainer/>
                {!isEmailActivated && isAuthenticated && <VerifyEmailContainer isMobile={isMobile}/>}

                <FeaturesComponent/>
                <MainPageInfoComponent sign={'Ващі скарги та пропозиції'} backGround={'#495990'} />
                <AdvantagesComponent/>
            </>
                :
            <div className='mobileMainPageContainer'>
                <div className="mainPageTopContainer">
                    <TaglineImage/>
                    <SelectCarComponent/>
                    <TaglineText/>
                    <FeaturesComponent/>
                </div>
                {!isEmailActivated && isAuthenticated && <VerifyEmailContainer isMobile={isMobile}/>}
                <MainPageInfoComponent sign={'Ващі скарги та пропозиції'} backGround={'#495990'} />
                <AdvantagesComponent/>
            </div>
            }

        </div>
    );
};

export default MainPage;