import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import './changeConstantsHeaderStyles.scss'
interface IProps extends PropsWithChildren{
    isOpen: boolean;
    setIsOpen: (arg: any) => void
    text: string
}
const ChangeConstantsHeader:FC<IProps> = ({isOpen, setIsOpen, text}) => {
    const [rotation, setRotation] = useState('-90')

    useEffect(() => {
        if(isOpen){
            setRotation('0')
        }else {
            setRotation('-90')
        }
    }, [isOpen]);
    const handleOpen = () => {
        const newValue = !isOpen
        setIsOpen((prevIsOpen:boolean) => !prevIsOpen);
    }
    return (
        <div className='changeConstantsHeader' onClick={handleOpen}>
            <img src='/headerImages/contactImages/OpenBtn.png'  style={{transform: `rotate(${rotation}deg)`}}/>
            <p>{text}</p>
        </div>
    );
};

export default ChangeConstantsHeader;