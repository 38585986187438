import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import Select from 'react-select'
import {ISearchOption} from "../../interfaces/carInterface";
import {searchInputStyle1200, searchInputStyle1000, searchInputStyle770} from "../../constants/SearchInputsStyles/SearchInputStyle";
import {useGetWidth} from "../../hooks/screenHooks/useGetWidth";
interface IProps extends PropsWithChildren{
    option: ISearchOption[]
    handleChange: (id: string) => void,
    placeHolder: string;
}
const CarSearchSelect:FC<IProps> = ({option, handleChange, placeHolder}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputStyle, setInputStyle] = useState(searchInputStyle1000)
    const options = option?.map(item => ({
        value: item.id,
        label: item.name
    }));

    useEffect(() => {
        if(!options){
            setSelectedOption(null)
        }
    }, [options]);

    const screenWidth = useGetWidth()
    useEffect(() => {
        handleResize()
    }, [screenWidth]);
    const handleResize = () => {

        if (screenWidth === 1280){
            setInputStyle(searchInputStyle1200)
        }else if (screenWidth === 1000) {
            setInputStyle(searchInputStyle1000)
        } else if (screenWidth === 770) {
            setInputStyle(searchInputStyle770)
        }
    };
    const handleChangeOption = (selectedOption: {value:number, label: string}) => {
        setSelectedOption(selectedOption);
        handleChange(selectedOption?.value?.toString())
    };
    return (
        <>
            <Select
                className='searchSelect'
                options={options}
                isDisabled={!option}
                value={selectedOption}
                onChange={handleChangeOption}
                isClearable={true}
                isSearchable={true}
                placeholder={placeHolder}
                styles={inputStyle}
            />
        </>
    );
};

export default CarSearchSelect;