import React from 'react';
import './usaBottomImgStyles.scss'
const UsaBottomImg = () => {
    return (
        <div className='usaBottomImageContainer'>
            <img src='/UsaImages/usaBottomImage.svg'/>
            <p>Мрія стає реальністю з будь-яким бюджетом</p>
        </div>
    );
};

export default UsaBottomImg;