import {createBrowserRouter, Navigate} from "react-router-dom";
import {MainLayout} from "./layouts";
import {ActivateAccountPage} from "./pages/ActivatePages/ActivateAccountPage/ActivateAccountPage";
import {CheckSms} from "./pages/InfoPages/Auth/CheckSms/CheckSms";
import {MePage} from "./pages/MePage/mePage";
import {ProductsByCategoryPage} from "./pages/ProductPages/ProductsByPages/ProductsByCategoryPage/ProductsByCategoryPage";
import {ProductDetailsPage} from "./pages/ProductPages/ProductDetailsPage/ProductDetailsPage";
import {CartPage} from "./pages/CartPage/CartPage";
import {ProductsByModelPage} from "./pages/ProductPages/ProductsByPages/ProductsByModelPage/ProductsByModelPage";
import SuccessOrder from "./pages/InfoPages/Ecommerce/Order/SuccessOrder/SuccessOrder";
import {LoginRegisterPage} from "./pages/LoginRegisterPages/LoginRegisterPage";
import {PasswordRecoveryPage} from "./pages/ActivatePages/PasswordRecoveryPage/PasswordRecoveryPage";
import ImportDataCsvPage from "./pages/AdminPages/ImportDataCSV/ImportDataCsvPage";
import ProductsByCategoryAndModelPage from "./pages/ProductPages/ProductsByPages/ProductsByCategoryAndModelPage/ProductsByCategoryAndModelPage";
import ProductsByBrandPage from "./pages/ProductPages/ProductsByPages/ProductsByBrandPage/ProductsByBrandPage";
import ProductsByManufacturerPage
    from "./pages/ProductPages/ProductsByPages/ProductsByManufacturerPage/ProductsByManufacturerPage";
import ManufacturersPage from "./pages/ManufacturersPage/ManufacturersPage";
import ProductsBySearchPage from "./pages/ProductPages/ProductsByPages/ProductsBySearchPage/ProductsBySearchPage";
import MainPage from "./pages/MainPage/MainPage";
import ProductsByCategoryAndBrandPage
    from "./pages/ProductPages/ProductsByPages/ProductsByCategoryAndBrand/ProductsByCategoryAndBrandPage";
import CheckEmail from "./pages/InfoPages/Auth/CheckEmail/CheckEmail";
import ActivateEmailPage from "./pages/ActivatePages/ActivateEmailPage/ActivateEmailPage";
import UnsuccessOrder from "./pages/InfoPages/Ecommerce/Order/UnsuccessOrder/UnsuccessOrder";
import SuccessPaymentPage from "./pages/InfoPages/Ecommerce/Payment/SuccessPayment/SuccessPaymentPage";
import UnsuccessPaymentPage from "./pages/InfoPages/Ecommerce/Payment/UnsuccessPayment/UnsuccessPaymentPage";
import OrdersListPage from "./pages/AdminPages/OrdersListPage/OrdersListPage";
import SecurityPolicyPage from "./pages/SecurityPolicyPage/SecurityPolicyPage";
import ChangeConstantsPage from "./pages/AdminPages/ChangeConstantsPage/ChangeConstantsPage";
import AdminLayout from "./layouts/AdminLayout/AdminLayout";
import EvacLayout from "./layouts/EvacLayout/EvacLayout";
import AutoServiceLayout from "./layouts/AutoServiceLayout/AutoServiceLayout";
import RequestPasswordRecovery from "./pages/ActivatePages/PasswordRecoveryPage/RequestPasswordRecovery";
import Page404 from "./pages/Page404/Page404";
import CarsFromUsaLayout from "./layouts/CarsFromUsaLayout/CarsFromUsaLayout";

const router =createBrowserRouter([
    {
        path:'',
        element:<MainLayout/>,
        children:[
            {
                index:true,
                element:<Navigate to={'main'}/>
            },
            {
                path: 'main',
                element: <MainPage/> //
            },
            {
                path: 'login',
                element: <LoginRegisterPage startValue={"1"}/> //
            },
            {
                path: 'register',
                element: <LoginRegisterPage startValue={"2"}/> //
            },
            {
                path:'activate/:token',
                element: <ActivateAccountPage/> //
            },
            {
                path: 'password_recovery_request',//
                element: <RequestPasswordRecovery/>
            },
            {
                path: 'password_recovery/:token',//
                element: <PasswordRecoveryPage/>
            },
            {
                path:'check_sms',//
                element: <CheckSms/>
            },
            {
                path: 'check_email',//
                element: <CheckEmail/>
            },
            {
                path: 'activate_email/:token', //
                element: <ActivateEmailPage/>
            },
            {
                path: 'me',///
                element: <MePage/>
            },
            {
                path: 'part_manufacturers', //зробити посилання
                element: <ManufacturersPage/>
            },
            {
                path: 'products_by_category/:id', //
                element: <ProductsByCategoryPage/>
            },
            {
                path: 'products_by_brand/:id',//
                element: <ProductsByBrandPage/>
            },
            {
                path: 'products_by_model/:id',//
                element: <ProductsByModelPage/>
            },
            {
                path: 'products_by_category_and_model/:category_id/:model_id',//
                element: <ProductsByCategoryAndModelPage/>
            },
            {
                path: 'products_by_category_and_brand/:category_id/:brand_id',//
                element: <ProductsByCategoryAndBrandPage/>
            },
            {
                path: 'products_by_manufacturer/:id',//
                element: <ProductsByManufacturerPage/>
            },
            {
                path: 'products_search',//
                element: <ProductsBySearchPage/>
            },
            {
                path: 'product_details/:id',//
                element: <ProductDetailsPage/>
            },
            {
                path: 'cart',//
                element: <CartPage/>
            },
            {
                path: 'success_order',
                element: <SuccessOrder/>
            },
            {
                path: 'unsuccess_order',
                element: <UnsuccessOrder/>
            },
            {
                path: 'success_payment',
                element: <SuccessPaymentPage/>
            },
            {
                path: 'unsuccess_payment',
                element: <UnsuccessPaymentPage/>
            },
            {
                path: 'security_policy',
                element: <SecurityPolicyPage/>
            },
            {
                path: '*',
                element: <Page404/>
            }
        ]
    },
    {
        path: 'admin',
        element: <AdminLayout/>,
        children:[
            {
                path: 'orders_list',
                element: <OrdersListPage/>
            },
            {
                path: 'change_constants',
                element: <ChangeConstantsPage/>
            },
            {
                path: 'import_data_csv',
                element: <ImportDataCsvPage/>
            },
        ]

    },
    {
        path: 'evac',
        element:<EvacLayout/>
    },
    {
        path: 'service',
        element: <AutoServiceLayout/>
    },
    {
        path: 'cars_from_usa',
        element: <CarsFromUsaLayout/>
    }
    ]
)

export {
    router
}