import React from 'react';
import './MapContainerStyles.scss'
const MapContainer = () => {
    return (
        <div className="mapContainer">
            <iframe className="iFrameMap"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2567.2110177856243!2d36.20306947764226!3d49.95114397150118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4127a19242b142db%3A0x71e384a88f90b7f1!2z0JDQstGC0L7RgNCw0LfQsdC-0YDQutCwIEJvbHRvcmV6INCj0LrRgNCw0LjQvdCwICjQsNCy0YLQvtC30LDQv9GH0LDRgdGC0LgsINCh0KLQnik!5e0!3m2!1sru!2sua!4v1710976704273!5m2!1sru!2sua"
                    title="Map"
                    referrerPolicy="no-referrer-when-downgrade"
                    allowFullScreen={true}>
            </iframe>
        </div>
    );
};

export default MapContainer;