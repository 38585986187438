import React, {useEffect, useState} from 'react';
// import './changePhonesStyles.scss'
import {ICachePhones, ICachePhonesForCreate} from "../../../../interfaces/cacheInterface";
import {useCache} from "../../../../context/cacheContext";
import {cacheService} from "../../../../services/apiServices/cache/cacheService";
import {LoadingPage} from "../../../../pages/LoadingPage/LoadingPage";
import ChangePhonesInputContainer from "../Containers/ChangePhonesInputContainer/ChangePhonesInputContainer";
import {AdminLoadingPage} from "../../AdminLoadingPage/AdminLoadingPage";
const ChangePhones = () => {
    const {phones, setLocalPhones, getPhones} = useCache()
    const [inputs, setInputs] = useState(['']);
    const [changeInfo, setChangeInfo] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (phones) {
            const newInputs = phones.map(phone => phone);
            setInputs(newInputs)
        }else {
            console.log('no phones')
            getPhones()
        }

    }, [phones]);
    useEffect(() => {
    }, [changeInfo]);
    const handleSubmit = async (event:any) => {
        const data:ICachePhonesForCreate = {phones: inputs}
        try {
            setLoading(true)
            const res = await cacheService.phoneServices.setPhones(data)
            setChangeInfo('Телефони успішно додано')
            setLocalPhones(res.data.phones)
            return res
        }catch (e){
            console.log(`Error: ${e}`)
            setChangeInfo('Помилка сервера: ' + e.toString())
            return null
        }finally {
            setLoading(false)
        }
    };

    return (
        <>
        {
            loading ?
                <AdminLoadingPage/>
                :
                <div className='changePhones'>
                    <form className='changePhonesForm' onSubmit={handleSubmit}>
                        <div className='innerChangeMessengerFormDiv'>
                            <ChangePhonesInputContainer phones={phones} changeInfo={changeInfo}
                                                        header={"Основні телефони"} inputs={inputs} setInputs={setInputs}/>
                        </div>
                        <button>Зберегти</button>
                    </form>
                </div>
        }
        </>
    );
};

export default ChangePhones;