import axios from "axios";
import {novaBaseURL} from "../../constants";
import {IRes} from "../apiServices/apiService";
import {
    INovaAreasApiResponse,
    INovaCitiesApiResponse,
    INovaWareHousesAPIResponse
} from "../../interfaces/externalInterfaces/novaInterfaces";

const novaAPIService = axios.create({baseURL :novaBaseURL})
export const novaServices = {
    getAreas: (data: object):IRes<INovaAreasApiResponse>  => novaAPIService.post('', data),
    getCities: (data: object):IRes<INovaCitiesApiResponse> => novaAPIService.post('', data),
    getWareHouses: (data: object):IRes<INovaWareHousesAPIResponse> => novaAPIService.post('', data)
}