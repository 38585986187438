import React, {useEffect, useState} from "react";
import {IProduct} from "../../../interfaces/productInterface";
import {useParams} from "react-router-dom";
import {productService} from "../../../services/apiServices/product/productServices";
import {ICartInfo, ICartItem, ICartToCreate} from "../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {ecommerceService} from "../../../services/apiServices/ecommerce/ecommerceService";
import './ProductDetailsPageStyles.scss'
import {LoadingPage} from "../../LoadingPage/LoadingPage";
import InnerInfoContainer from "../../../components/ProductDetails/InnerInfoContainer/InnerInfoContainer";
import {featuresDataInProductDetails} from "../../../constants/featuresConstants/featuresConstants";
import Feature from "../../../components/MainPageComponents/FeaturesComponent/Feature/Feature";
import {useCache} from "../../../context/cacheContext";
import ImagesGallery from "../../../components/Slider/ImagesGallery/ImagesGallery";
import {useAuth} from "../../../context/authContext";

export const ProductDetailsPage = () => {
    const [details, setDetails] = useState<IProduct | null>(null)
    const [image, setImages] = useState<string[]>([])
    const [isAddToCart, setIsAddToCart] = useState(true)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null)
    const {id} = useParams<{id:string}>()
    const {currencyToUah} = useCache()
    const {setCartTrigger} = useAuth()
    const [cur, setCur] = useState<number>(40)
    useEffect(() => {
        if(currencyToUah){
            setCur(currencyToUah)
        }else {
            setCur(40)
        }
    }, [currencyToUah]);
    useEffect(() => {
        const FetchData = async () => {
            try {
                setLoading(true)
                const response = await productService.getDetails(id)
                setDetails(response.data)
                setError(null)
            }catch (e){
                const errorText = `Error: ${e}`
                console.log(errorText)
                setError(errorText)
            }finally {
                setLoading(false)
            }
        }
        FetchData()

    }, [id])
    useEffect(() => {
        const localCart = localStorage.getItem('cart');

        if (localCart){
            try {
                const parsedCart = JSON.parse(localCart) as ICartItem[];
                for (let i = 0; i < parsedCart.length; i++){
                    if(parsedCart[i].id.toString() === id){
                        setIsAddToCart(false)
                    }
                }
            }catch (e){
                const errorText = `Error to parse cart: ${e}`
                console.log(errorText)
                setError(errorText)
            }

        }
    }, []);
    useEffect(() => {
        if (details && details.image) {
            const baseImageUrl = `http://export-content.baz-on.net`;
            let stArr = details.image.split(",");
            const newImages = stArr.map(s => {
                let index = s.indexOf("/pub");
                return index !== -1 ? baseImageUrl + s.substring(index) : null;
            }).filter(s => s !== null);
            setImages(newImages);
        }
        else {
            setImages(['/no_photo.svg'])
        }
    }, [details]);
    useEffect(() => {

    }, [isAddToCart]);
    let cart: ICartItem[] = []

    const itemToCard: ICartItem = {
        id: details?.id,
        name: details?.name,
        brand: details?.brand,
        model: details?.model,
        price: details?.price,
        oem: details?.oem,
        image: ''
    }

    const addItemToCart = async (item: ICartItem) => {
        setIsAddToCart(false)
        itemToCard.image = image[0]
        const localCart = localStorage.getItem('cart');

        if (localCart) {
            try {
                const parsedCart = JSON.parse(localCart) as ICartItem[];
                cart = [...parsedCart]
            }catch (e){
                const errorText = `Error to parse cart: ${e}`
                console.log(errorText)
                setError(errorText)
                return
            }

        }
        let cartCopy = [...cart];
        let {id} = item;
        let existingItem = cartCopy.find(cartItem => cartItem.id === id);
        if (existingItem) {
            console.log("Item already exist")
            return
        } else {
            cartCopy.push(item)
        }
        let stringCart = JSON.stringify(cartCopy);
        localStorage.setItem("cart", stringCart)

        try {
            let response
            let cartForCreate: ICartToCreate = {items: cartCopy}
            console.log(cartForCreate)
            if (localCart) {
                const localCartInfo = localStorage.getItem('cartInfo');
                let cartId: number
                if (localCartInfo) {
                    const cartInfo: ICartInfo = JSON.parse(localCartInfo)
                    cartId = cartInfo.id
                } else {
                    console.log("Cannot int id")
                }
                response = await ecommerceService.updateCart(cartForCreate, cartId);
                const cartInfo: ICartInfo = {
                    id: response.data.id,
                    can_be_paid: response.data.can_be_paid,
                    total_price: response.data.total_price,
                    total_items: response.data.items.length
                }
                const cartInfoStr = JSON.stringify(cartInfo)
                localStorage.setItem('cartInfo', cartInfoStr)
            } else {
                response = await ecommerceService.addCart(cartForCreate);
                const cartInfo: ICartInfo = {
                    id: response.data.id,
                    can_be_paid: response.data.can_be_paid,
                    total_price: response.data.total_price,
                    total_items: response.data.items.length
                }
                const cartInfoStr = JSON.stringify(cartInfo)
                localStorage.setItem('cartInfo', cartInfoStr)
            }
            if (response) {

            } else {
                console.log("nima")
            }
        } catch (error) {
            console.error('Error while adding item to cart:', error);
            setIsAddToCart(true)
        }

        setCartTrigger(prev => !prev)
    }
    return(
        <>
            {
                loading?
                    <LoadingPage/>
                    :  //ELSE
                    <div className='product-details'>
                        <div className='productDetailsContainer'>
                            <h2>{details?.name} {details?.brand} {details?.model}</h2>
                            <div className='productInfoContainer'>
                                {/*<ProductImagesSlider image={image}/>*/}
                                <ImagesGallery images={image}/>
                                <div className='productInfo'>

                                    <h3 style={{margin: "0"}}>Ціна:</h3>
                                    {details?.price === 0 ?
                                        <p style={{color: '#495990'}}>Уточнити ціну</p>
                                        :
                                        <div className='priceContainer'>
                                            <h3>${details?.price}</h3>
                                            <p>{details?.price * cur} грн</p>
                                        </div>
                                    }
                                    <br/>
                                    <InnerInfoContainer name={'Код товару:'} value={(details?.id)?.toString()}/>
                                    {details?.manufacturer && details?.manufacturer !== "" &&
                                        <InnerInfoContainer name={'Виробник:'} value={details?.manufacturer}/>
                                    }
                                    {details?.oem && details?.oem !== "" &&
                                        <InnerInfoContainer name={'Каталожний номер(OEM):'} value={details?.oem}/>
                                    }
                                    {isAddToCart ?
                                        <button className='buyButton' disabled={!isAddToCart}
                                                onClick={() => addItemToCart(itemToCard)}>
                                            Купити
                                        </button>
                                        : //ELSE
                                        <div className='buyButton' style={{backgroundColor: "#adb9c0", cursor:"auto"}}>Вже у кошику</div>
                                    }
                                </div>

                            </div>
                            <div className='featuresContainer'>
                                {featuresDataInProductDetails.map(feature => <Feature key={feature.id} props={feature}/>)}
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}