import React, {FC, PropsWithChildren} from 'react';
import {ICart} from "../../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import ItemsInCart from "./ItemsInCart/ItemsInCart";
import './cartInOrderStyles.scss'
interface IProps extends PropsWithChildren{
    cart?: ICart
}
const CartInOrder:FC<IProps> = ({cart}) => {
    return (
        <div className='cartInOrder'>
            <ItemsInCart items={cart.items}/>
            {cart.items_without_price.length > 0 &&
                <>
                    <h2>Товари без ціни: </h2>
                    <ItemsInCart items={cart.items_without_price}/>
                </>
            }

        </div>
    );
};

export default CartInOrder;