import React, {useEffect, useState} from 'react';
import {useGetWidth} from "../../hooks/screenHooks/useGetWidth";
import {useCache} from "../../context/cacheContext";
import {IHeaderConfig} from "../../interfaces/configInterfaces/headerConfigInterface";
import {
    evacHeaderMessengerLinksData,
    serviceHeaderMessengerLinksData
} from "../../constants/SocialLinksConstants/SocialLinksConstants";
import {IFooterConfig} from "../../interfaces/configInterfaces/footerConfigInterface";
import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import './autoServiceLayoutStyles.scss'
import AutoServicePage from "../../pages/AutoServicePage/AutoServicePage";
const AutoServiceLayout = () => {
    const [isMobile, setIsMobile] = useState(false)
    const screenWidth = useGetWidth()
    const {autoServicePhones, getAutoServicePhones, autoServicePartsPhones, getAutoServicePartPhones, messengerPhones} = useCache()
    useEffect(() => {
    }, [messengerPhones]);
    useEffect(() => {
        if(!autoServicePhones){
            getAutoServicePhones()
        }
    }, [autoServicePhones]);
    useEffect(() => {
        if(!autoServicePartsPhones){
            getAutoServicePartPhones()
        }
    }, [autoServicePartsPhones]);
    useEffect(() => {
        handleResize()
    }, [screenWidth]);
    const handleResize = () => {
        if (screenWidth === 200) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };
    const serviceHeaderConfigs: IHeaderConfig = {
        style: {
            backgroundColor: "#3E3E40",
        },
        downHeaderStyle: {
            backgroundColor: "#599FD2"
        },

        contactsConfigs: {
            contactsStyles:{
                aStyles: {
                    color: '#F2F0F8'
                },
                containerStyles: {
                    backgroundColor: '#3E3E40'
                }
            },
            phones: autoServicePhones,
            mobileImg: 'servicePhoneImage'
        },
        rightConfigs: {
            messengerLinksConfigs:{
                headerMessengerLinksData: serviceHeaderMessengerLinksData,
                phones:messengerPhones
            },
            cartConfigs:{
                img: 'evacCart'
            },
            meMenuConfigs:{
                img: 'serviceAccount',
                avatarStyle:{
                    color: '#F2F0F8',
                    backgroundColor: '#599fd2'
                },
                listStyle:{
                    aStyles: {
                        color: '#F2F0F8'
                    },
                    containerStyles: {
                        backgroundColor: '#3E3E40'
                    }
                },

            }
        }

    }
    const serviceFooterConfigs: IFooterConfig = {
        style:{
            backgroundImage: 'linear-gradient(to top, #599FD2 30%, #3E3E40 100%)'
        },
        infoDivClass: 'footerInfoDivService',
        phones: autoServicePhones,
        messengerPhones: messengerPhones

    }
    return (
        <div className='serviceLayout'>
            <Header isMobile={isMobile} configs={serviceHeaderConfigs}/>
            <div className='serviceContentContainer'>
                <AutoServicePage phones={autoServicePhones} partPhones={autoServicePartsPhones}/>
            </div>
            <Footer isMobile={isMobile} configs={serviceFooterConfigs}/>
        </div>
    );
};

export default AutoServiceLayout;