import React, {useEffect, useState} from 'react';
import {useGetWidth} from "../../hooks/screenHooks/useGetWidth";
import {IHeaderConfig} from "../../interfaces/configInterfaces/headerConfigInterface";
import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import {useCache} from "../../context/cacheContext";
import {evacHeaderMessengerLinksData} from "../../constants/SocialLinksConstants/SocialLinksConstants";
import {IFooterConfig} from "../../interfaces/configInterfaces/footerConfigInterface";
import './evacLayoutStyles.scss'
import {Outlet} from "react-router-dom";
import EvacPage from "../../pages/EvacPage/EvacPage";
const EvacLayout = () => {
    const [isMobile, setIsMobile] = useState(false)
    const screenWidth = useGetWidth()
    const {evacPhones, getEvacPhones, messengerPhones, getMessengerPhones} = useCache()
    useEffect(() => {
        if(!evacPhones){
            getEvacPhones()
        }
    }, [evacPhones]);
    useEffect(() => {
        if(!messengerPhones){
            getMessengerPhones()
        }
    }, [messengerPhones]);
    useEffect(() => {
        handleResize()
    }, [screenWidth]);
    const handleResize = () => {
        if (screenWidth === 200) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };
    const evacHeaderConfigs: IHeaderConfig = {
        style: {
            backgroundColor: "#3E3E40",
        },
        downHeaderStyle: {
            backgroundColor: "#F0D267",
            aId: 'downHeaderInnerEvac'
        },

        contactsConfigs: {
            contactsStyles:{
                aStyles: {
                    color: '#F2F0F8'
                },
                containerStyles: {
                    backgroundColor: '#3E3E40'
                }
            },
            phones: evacPhones,
            mobileImg: 'evacPhoneImage'
        },
        rightConfigs: {
            messengerLinksConfigs:{
                headerMessengerLinksData: evacHeaderMessengerLinksData,
                phones:messengerPhones
            },
            cartConfigs:{
                img: 'evacCart'
            },
            meMenuConfigs:{
                img: 'evacAccount',
                avatarStyle:{
                    color: '#3E3E40',
                    backgroundColor: '#F0D267'
                },
                listStyle:{
                    aStyles: {
                        color: '#F2F0F8'
                    },
                    containerStyles: {
                        backgroundColor: '#3E3E40'
                    }
                },

            }
        }

    }
    const evacFooterConfigs: IFooterConfig = {
        style:{
            backgroundImage: 'linear-gradient(to top, #F0D267 30%, #3E3E40 100%)'
        },
        infoDivClass: 'footerInfoDivEvac',
        phones: evacPhones,
        messengerPhones: messengerPhones

    }
    return (
        <div className='evacLayout'>
            <Header isMobile={isMobile} configs={evacHeaderConfigs}/>
            <div className='evacContentContainer'>
                <EvacPage phones={evacPhones}/>
            </div>
            <Footer isMobile={isMobile} configs={evacFooterConfigs}/>
        </div>
    );
};

export default EvacLayout;