import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {IUsaRequest} from "../../../interfaces/usaInterfaces/usaServices";
import {useLocation} from "react-router-dom";
import {handlePhoneChange} from "../../../services/helpers/registerHelpers";
import {usaServices} from "../../../services/apiServices/usaServices/usaServices";
import {AxiosError} from "axios";
import './usaFormStyles.scss'
import {LoadingPage} from "../../../pages/LoadingPage/LoadingPage";

const UsaForm = () => {
    const {register, reset, handleSubmit} = useForm<IUsaRequest>()
    const [loading, setLoading] = useState(false);
    const [errorOther, setErrorOther] = useState(false)
    const [errorPhone, setErrorPhone] = useState(false)
    const [isOk, setIsOk] = useState(false)
    const location = useLocation();
    useEffect(() => {
        if (location.hash === '#usaForm') {
            const element = document.getElementById('usaForm');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    useEffect(() => {
    }, [isOk, errorPhone, errorOther]);
    const createRequest:SubmitHandler<IUsaRequest> = async (data) => {
        data.phone = handlePhoneChange(data.phone)
        if(!data.phone){
            setErrorPhone(true)
            return
        }
        try {
            setLoading(true)
            const res =  await usaServices.createRequest(data)
            setErrorPhone(false)
            setErrorOther(false)
            setIsOk(true)
            reset()
        } catch(e){
            setIsOk(false)
            const err = e as AxiosError
            if (err.response) {
                console.log(`Помилка сервера: ${err.response.status} - ${err.response.data}`)
                console.log(err.response.data)
                setErrorPhone(true)
            } else {
                console.error('Помилка: ', err.message);
                setErrorOther(true)
            }
        }finally {
            setLoading(false)
        }
    }
    return (
        <>
        {loading ?
                <LoadingPage/>
                :
                <div className='usaFormDiv' id='usaForm'>

                        <form className='evacForm' onSubmit={handleSubmit(createRequest)}>
                            <p>Замовити безкоштовну консультацію</p>
                            <input type={'text'} placeholder={'Імʼя'} {...register('name')} minLength={2} required/>
                            <input type={'text'} placeholder={'Ваш номер телефону'} {...register('phone')} minLength={10}
                                   maxLength={13} required/>
                            <input type={'text'} placeholder={'Бажане Авто (можна декілька)'} {...register('car')}
                                   minLength={2}/>
                            <input type={'text'} placeholder={'Приблизний бюджет (необовʼязково)'} {...register('budget')}
                                   minLength={2}
                                   required/>
                            <input type={'text'} placeholder={'Коментар(необовʼязково'} {...register('message')} minLength={2}/>
                            <button>Замовити</button>
                            {isOk && <span style={{color: "green"}}>Успішно</span>}
                            {errorPhone && <span style={{color: "red"}}>Невірний формат телефону</span>}
                            {errorOther &&
                                <span style={{color: "red"}}>Вибачте, помилка з нашого боку, звʼяжіться з нами</span>}
                        </form>


                </div>
        }
        </>
    );
};

export default UsaForm;