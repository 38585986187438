import React, {useEffect, useState} from 'react';
import {useCache} from "../../../../../context/cacheContext";
import {ICacheSocialLinks} from "../../../../../interfaces/cacheInterface";
import {cacheService} from "../../../../../services/apiServices/cache/cacheService";
import {
    IAutoServiceAddress,
    IAutoServiceAddressData
} from "../../../../../interfaces/autoServiceInterfaces/cacheAutoServiceInterfaces";
import {AdminLoadingPage} from "../../../AdminLoadingPage/AdminLoadingPage";

const ChangeAutoServiceAddress = () => {
    const {autoServiceAddress, getAutoServiceAddress} = useCache()
    const [addressText, setAddressText] = useState<string | null>(null)
    const [addressLink, setAddressLink] = useState<string | null>(null)
    const [changeInfo, setChangeInfo] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if(autoServiceAddress){
            setAddressText(autoServiceAddress.text)
            setAddressLink(autoServiceAddress.link)
        }else {
            console.log('no address')
            getAutoServiceAddress()
        }
    }, [autoServiceAddress]);
    useEffect(() => {
    }, [changeInfo]);
    const handleSubmit = async (event : any) => {
        const data:IAutoServiceAddressData ={
            address:{
                text: addressText,
                link: addressLink,
            }
        }
        try {
            setLoading(true)
            const res = await cacheService.autoService.address.setAddress(data)
            setChangeInfo('Адрес СТО успішно додано')
            return res
        }catch (e){
            console.log(`Error: ${e}`)
            setChangeInfo('Помилка сервера: ' + e.toString())
            return null
        }finally {
            setLoading(false)
        }
    }
    return (
        <>

            {
                loading ?
                    <AdminLoadingPage/>
                    :
                    <div className='changeSocialLinks'>
                        <form className='changeSocialLinksForm' onSubmit={handleSubmit}>
                            <div className='innerChangeSocialLinksFormDiv'>
                                <div className='InputContainer'>
                                    <h2>Адреса та посилання на google maps СТО</h2>
                                    <div className='input-labelHorizontalContainer'>
                                        <p>Адреса</p>
                                        <input type='text' placeholder='Адреса СТО(текстом)' required minLength={5}
                                               value={addressText}
                                               onChange={e => {
                                                   const newInput = e.target.value
                                                   setAddressText(newInput)
                                               }}
                                               className="phoneInput"/>
                                    </div>
                                    <div className='input-labelHorizontalContainer'>
                                        <p>Посилання</p>
                                        <input type='text' placeholder='Вставте посилання на maps' required minLength={5}
                                               value={addressLink}
                                               onChange={e => {
                                                   const newInput = e.target.value
                                                   setAddressLink(newInput)
                                               }}
                                               className="phoneInput"/>
                                    </div>


                                </div>
                            </div>
                            {changeInfo && <label>{changeInfo}</label>}
                            <button>Зберегти</button>
                        </form>

                    </div>

            }

        </>
    );
};

export default ChangeAutoServiceAddress;