import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {useCache} from "../../../../../context/cacheContext";

interface IProps extends PropsWithChildren{
    phones: string[] | null;
    changeInfo: string | null;
    header: string;
    inputs: string[];
    setInputs: (inputs: string[]) => void;
}
const ChangePhonesInputContainer:FC<IProps> = ({phones, changeInfo, header, inputs, setInputs}) => {
    useEffect(() => {
        if (phones && phones?.length > 0) {
            const newInputs = phones?.map(phone => phone);
            setInputs(newInputs)
        }else {
            console.log('no phones')
        }

    }, [phones]);
    useEffect(() => {

    }, [changeInfo]);
    const handleAddInput = () => {
        if (inputs.length <= 10){
            setInputs([...inputs, '']);
        }
    };

    const handleDeleteInput = () => {
        if (inputs.length > 1) {
            setInputs(inputs.slice(0, -1)); // Видаляємо останній елемент масиву
        }
    };
    return (
        <>
            <div className='phonesInputContainer'>
                <h2>{header}</h2>

                {inputs.map((input, index) => (
                    <input
                        key={index}
                        type='text'
                        placeholder='Введіть телефон'
                        required
                        value={input}
                        onChange={e => {
                            const newInputs = [...inputs];
                            newInputs[index] = e.target.value;
                            setInputs(newInputs);
                        }}
                        className="phoneInput"
                    />
                ))}
                {changeInfo && <label>{changeInfo}</label>}
                <p>Для того щоб видалити вже існуючий телефон поставте його значення на 0</p>
            </div>
            <div className="buttons">
                <button onClick={handleAddInput} className="addButton">+</button>
                <button onClick={handleDeleteInput} className="deleteButton">-</button>
            </div>
        </>
    );
};

export default ChangePhonesInputContainer;