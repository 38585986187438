export interface IAdvantagesProps{
    id: number
    imageSrc: string,
    header: string;
    text: string,
    alt: string,
}
const path = '/advantagesImages'
const fileFormat = 'png'
export const advantagesData:IAdvantagesProps[] = [
    {
        id:0,
        imageSrc: `${path}/evac.${fileFormat}`,
        header: 'Послуги евакуатора',
        text: 'Незалежно від часу доби ми готові прийти на допомогу при транспортуванні автомобіля до потрібного місця',
        alt: 'евакуатор',
    },
    {
        id:1,
        imageSrc: `${path}/parts.${fileFormat}`,
        header: 'Великий вибір запчастин',
        text: 'Широкий асортимент деталей для різних моделей високої якості з тривалим терміном гарантії',
        alt: 'запчастини',
    },
    {
        id:2,
        imageSrc: `${path}/autoService.${fileFormat}`,
        header: 'Власний автосервіс',
        text: 'Станція має надсучасне обладнання для ретельної діагностики та ремонту, який виконають досвідчені майстри',
        alt: 'сервіс',
    },
    {
        id:3,
        imageSrc: `${path}/blackout.${fileFormat}`,
        header: 'Працюємо під час блекаута',
        text: 'Компанія оснащена декількома генераторами та інтернетом STARLINK, але найголовніше що палають наші серця',
        alt: 'блекаут',
    }
]