import React, {FC, PropsWithChildren} from 'react';
import './FooterInfoContainer.scss'
import FooterInfoRightContainer from "./RightContainer/FooterInfoRightContainer";
import FooterInfoMiddleContainer from "./MiddleContainer/FooterInfoMiddleContainer";
import FooterInfoLeftContainer from "./LeftContainer/FooterInfoLeftContainer";
interface IProps extends PropsWithChildren{
    isMobile: boolean;
    infoDivClass: string
}
const FooterInfoContainer:FC<IProps> = ({isMobile, infoDivClass}) => {
    return (
        <div className='footerInfoContainer'>
            {!isMobile ?
                <>
                    <FooterInfoLeftContainer infoDivClass={infoDivClass}/>
                    <FooterInfoMiddleContainer infoDivClass={infoDivClass}/>
                    <FooterInfoRightContainer/>
                </>
                :
                <>
                    <div className='mobileInnerInfoFooterContainer'>
                        <FooterInfoLeftContainer infoDivClass={infoDivClass}/>
                        <FooterInfoMiddleContainer infoDivClass={infoDivClass}/>
                    </div>
                    <FooterInfoRightContainer/>
                </>
            }

        </div>
    );
};

export default FooterInfoContainer;