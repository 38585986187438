import React, {FC, PropsWithChildren} from 'react';
import FooterSocialLinks from "./SocialLinks/FooterSocialLinks";
import FooterContacts from "./Contacts/FooterContacts";
interface IProps extends PropsWithChildren{
    infoDivClass: string
}
const FooterInfoMiddleContainer:FC<IProps> = ({infoDivClass}) => {
    return (
        <div className='footerInfoInnerContainer' id='footerMiddle'>
            <FooterContacts infoDivClass={infoDivClass}/>
            <FooterSocialLinks infoDivClass={infoDivClass}/>
        </div>
    );
};

export default FooterInfoMiddleContainer;