import './headerStyles.scss'

import * as React from "react";
import SearchInputComponent from "./SearchComponents/SearchInputComponent";
import HeaderRightSideContainer from "./RightSideContainer/HeaderRightSideContainer";
import HeaderLeftSideContainer from "./LeftSideContainer/HeaderLeftSideContainer";
import DownHeader from "./DownHeader/DownHeader";
import HeaderContacts from "./ContactsComponent/HeaderContacts";
import {FC, PropsWithChildren, useEffect, useState} from "react";
import HeaderCart from "./RightSideContainer/Cart/HeaderCart";
import MeMenu from "./RightSideContainer/MeMenu/MeMenu";
import {IHeaderConfig, IHeaderStyle} from "../../interfaces/configInterfaces/headerConfigInterface";
interface IProps extends PropsWithChildren{
    isMobile: boolean;
    configs: IHeaderConfig
}
export const Header:FC<IProps> = ({isMobile, configs}) => {
    return(
        <>
            {
                !isMobile ?
                <div className="Header" style={configs.style}>
                    <div className="headerMainContainer">
                        <HeaderLeftSideContainer/>
                        <SearchInputComponent/>
                        <HeaderContacts isMobile={isMobile} configs={configs}/>
                        <HeaderRightSideContainer configs={configs.rightConfigs}/>
                    </div>
                    <DownHeader isMobile={isMobile} style={configs.downHeaderStyle}/>
                </div>
                    : //ELSE
                <>
                    <div className="Header" style={configs.style}>
                        <div className="headerMainContainer">
                            <HeaderLeftSideContainer/>
                            <SearchInputComponent/>
                        </div>
                        <DownHeader isMobile={isMobile} style={configs.downHeaderStyle}/>
                    </div>
                    <div className="mobileBottomHeader" style={configs.style}>
                        <div className="mobileBottomHeaderContainer">
                            <HeaderContacts isMobile={isMobile} configs={configs}/>

                            <MeMenu configs={configs.rightConfigs.meMenuConfigs}/>
                            <HeaderCart configs={configs.rightConfigs.cartConfigs}/>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

