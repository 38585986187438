import React, {FC, PropsWithChildren} from 'react';
import {IOrder} from "../../../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
interface IProps extends PropsWithChildren{
    currencyToUah: number
    totalPrice: string
}
const OrderTotalPrice:FC<IProps> = ({totalPrice, currencyToUah}) => {
    return (
        <>
            {parseFloat(totalPrice) !== 0 ?
                <h4>${totalPrice} / {parseFloat(totalPrice) * currencyToUah} грн</h4>
                : //ELSE
                <p>Немає ціни</p>
            }
        </>
    );
};

export default OrderTotalPrice;