import React from 'react';
import ChangeServicePhones
    from "../../../../components/AdminComponents/ChangeForms/AutoService/ChangeAutoServicePhones/ChangeServicePhones/ChangeServicePhones";
import ChangeServicePartsPhones
    from "../../../../components/AdminComponents/ChangeForms/AutoService/ChangeAutoServicePhones/ChangePartsPhones/ChangeServicePartsPhones";
import ChangeAutoServiceAddress
    from "../../../../components/AdminComponents/ChangeForms/AutoService/ChangeAutoServiceAddress/ChangeAutoServiceAddress";
import ChangeAutoServiceAdvantages
    from "../../../../components/AdminComponents/ChangeForms/AutoService/ChangeAutoServiceAdvantages/ChangeAutoServiceAdvantages";

const ChangeAutoServiceConstantsContainer = () => {
    return (
        <>
            <div className='changeConstantsHorizontalContainer'>
                <ChangeServicePhones/>
                <ChangeServicePartsPhones/>
            </div>
            <div className='changeConstantsHorizontalContainer'>
                <ChangeAutoServiceAddress/>
                <ChangeAutoServiceAdvantages/>
            </div>
        </>
    );
};

export default ChangeAutoServiceConstantsContainer;