import React from 'react';
import {Link} from "react-router-dom";
import "../../../CheckPageStyles.scss"

const UnsuccessPaymentPage = () => {
    return (
        <div className="checkEmailPage">
            <div className="ActivateText">Сталася помилка при оплаті</div>
            <Link className="linkToLogin" to={'/main'}><div>Головна</div></Link>
        </div>
    );
};

export default UnsuccessPaymentPage;