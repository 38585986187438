import React, {FC, PropsWithChildren, useEffect} from 'react';
import {ILinkImageProps} from "../../../constants/SocialLinksConstants/SocialLinksConstants";
import {useCache} from "../../../context/cacheContext";
interface IProps extends PropsWithChildren{
    link: ILinkImageProps;
}
const LinkContactImage:FC<IProps> = ({link}) => {
    const {messengerPhones} = useCache()
    useEffect(() => {
    }, [messengerPhones]);
    const getImgHref = () =>{
        if(messengerPhones){
            const tels = messengerPhones[link.alt]
            if(tels){
                const telIndex = getRandomIndex(tels.length)
                const tel = tels[telIndex]
                const imgHref = link.href + tel
                return imgHref
            }
            return ''
        }
        return ''
    }
    const getRandomIndex = (max: number) => {
        if (max > 0){
            const randomIndex = Math.floor(Math.random() * max);
            return randomIndex
        }
        return 0

    }
    return (
        <a href={getImgHref()} target="_blank" rel="noopener noreferrer">
            <img className="webLinkImg" src={link.imageSrc}
                 alt={link.alt}></img>
        </a>
    );
};

export default LinkContactImage;