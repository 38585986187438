import React, {FC, useEffect, useState} from 'react';
import {IOrder} from "../../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {IUserInOrder} from "../../../../../interfaces/userInterface";
import UserInOrder from "../UserInOrder/UserInOrder";
import OrderDeliverInfo from "../OrderDeliverInfo/OrderDeliverInfo";
import AdminOrderPriceContainer from "../OrderPriceContainer/AdminOrderPriceContainer";
import CartInOrder from "../CartInOrder/CartInOrder";
interface IProps{
    order: IOrder;
    currencyToUah: number
}
const AdminOrderDetails:FC<IProps> = ({order, currencyToUah}) => {
    const [isCart, setIsCart] = useState(false)

    return (
        <div className="adminOrderDetails">
            <h2>Про клієнта</h2>
            <UserInOrder order={order}/>
            <h2>Доставка</h2>
            <OrderDeliverInfo order={order}/>
            <h2>Оплата</h2>
            <AdminOrderPriceContainer currencyToUah={currencyToUah} order={order}/>
            <CartInOrder cart={order.cart}/>
        </div>
    );
};

export default AdminOrderDetails;