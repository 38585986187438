import * as React from 'react';
import {FC, PropsWithChildren, useEffect, useState} from "react";
import Select from "react-select";

import {INovaAreas, INovaCities, INovaWarehouses } from "../../../../../interfaces/externalInterfaces/novaInterfaces";
import {novaServices} from "../../../../../services/externalServices/novaServices";
import {useGetWidth} from "../../../../../hooks/screenHooks/useGetWidth";
import {
    searchInputStyle1000,
    searchInputStyle1200,
    searchInputStyle770
} from "../../../../../constants/SearchInputsStyles/SearchInputStyle";
import {
    novaSelectInputStyle1000, novaSelectInputStyle1200, novaSelectInputStyle200,
    novaSelectInputStyle770
} from "../../../../../constants/SearchInputsStyles/novaSelectInputStyles";

interface IProps extends PropsWithChildren{
    selectedArea: { value: string, label: string } | null;
    setSelectedArea: React.Dispatch<React.SetStateAction<{ value: string, label: string } | null>>;
    selectedCity: { value: string, label: string } | null;
    setSelectedCity: React.Dispatch<React.SetStateAction<{ value: string, label: string } | null>>;
    selectedWarehouse: { value: string, label: string } | null
    setSelectedWarehouse: React.Dispatch<React.SetStateAction<{ value: string, label: string } | null>>;
}

export const NovaForm: FC<IProps> = ({selectedArea, setSelectedArea ,selectedCity, setSelectedCity, selectedWarehouse, setSelectedWarehouse}) => {
    const API_KEY_NOVA = "f7352620dc18d73ab77c319676e17b77";
    const [inputStyle, setInputStyle] = useState(searchInputStyle1200)
    const [areas, setAreas] = useState<INovaAreas[] | null>(null);
    const [cities, setCities] = useState<INovaCities[] | null>(null);
    const [warehouses, setWarehouses] = useState<INovaWarehouses[] | null>(null);
    const screenWidth = useGetWidth()
    useEffect(() => {
        handleResize()
    }, [screenWidth]);
    const handleResize = () => {
        if (screenWidth === 1280){
            setInputStyle(novaSelectInputStyle1200)
        }else if (screenWidth === 1000) {
            setInputStyle(novaSelectInputStyle1000)
        } else if (screenWidth === 770) {
            setInputStyle(novaSelectInputStyle770)
        } else if (screenWidth === 200){
            setInputStyle(novaSelectInputStyle200)
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const areasData = {
                    apiKey: API_KEY_NOVA,
                    modelName: "Address",
                    calledMethod: "getAreas",
                    methodProperties: {}
                };
                const response = await novaServices.getAreas(areasData);
                if (response.data.data && Array.isArray(response.data.data)) {
                    setAreas(response.data.data);
                } else {
                    console.error("Отримані дані не є масивом");
                }
            } catch (e) {
                console.log(`Error: ${e}`);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchCities = async () => {
            if (!selectedArea) return;
            try {
                const citiesData = {
                    apiKey: API_KEY_NOVA,
                    modelName: "Address",
                    calledMethod: "getCities",
                    methodProperties: {
                        AreaRef: selectedArea.value
                    }
                };
                const response = await novaServices.getCities(citiesData);
                if (response.data.data && Array.isArray(response.data.data)) {
                    setCities(response.data.data);
                } else {
                    console.error("Отримані дані не є масивом");
                }
            } catch (e) {
                console.log(`Error: ${e}`);
            }
        };
        fetchCities();
    }, [selectedArea]);
    useEffect(() => {
        const fetchWarehouses = async () => {
            if (!selectedCity) return;
            try {
                const data = {
                    apiKey: API_KEY_NOVA,
                    modelName: "Address",
                    calledMethod: "getWarehouses",
                    methodProperties: {
                        CityRef: selectedCity.value
                    }
                };
                const response = await novaServices.getWareHouses(data);
                if (response.data.data && Array.isArray(response.data.data)) {
                    setWarehouses(response.data.data);
                } else {
                    console.error("Отримані дані не є масивом");
                }
            } catch (e) {
                console.log(`Error: ${e}`);
            }
        };
        fetchWarehouses();
    }, [selectedCity]);

    const handleAreaChange = (selectedOption: any) => {
        setSelectedArea(selectedOption);
        setSelectedCity(null); // Reset selected city when area changes
        setSelectedWarehouse(null);
    };

    const handleCityChange = (selectedOption: any) => {
        setSelectedCity(selectedOption);
        setSelectedWarehouse(null);
    };
    const handleWarehouseChange = (selectedOption: any) => {
        setSelectedWarehouse(selectedOption);
    };



    const areaOptions = areas?.map((area) => ({
        value: area.Ref,
        label: area.Description
    }));

    const cityOptions = cities?.map((city) => ({
        value: city.Ref,
        label: city.Description
    }));
    const warehouseOptions = warehouses?.map((warehouse) => ({
        value: warehouse.Ref,
        label: warehouse.Description
    }));

    return (
        <>
            <Select
                className='deliverySelect'
                value={selectedArea}
                onChange={handleAreaChange}
                options={areaOptions}
                isClearable
                isSearchable
                placeholder="Оберіть область"
                required
                styles={inputStyle}
            />
            <Select
                className='deliverySelect'
                value={selectedCity}
                onChange={handleCityChange}
                options={cityOptions}
                isClearable
                isSearchable
                placeholder="Оберіть місто"
                isDisabled={!selectedArea}
                required
                styles={inputStyle}
            />
            <Select
                className='deliverySelect'
                value={selectedWarehouse}
                onChange={handleWarehouseChange}
                options={warehouseOptions}
                isClearable
                isSearchable
                placeholder="Оберіть відділення"
                isDisabled={!selectedCity}
                required
                styles={inputStyle}
            />
        </>
    );
};

