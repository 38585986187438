import React, {FC, PropsWithChildren} from 'react';
import {IFeaturesProps} from "../../../../constants/featuresConstants/featuresConstants";
interface IProps extends PropsWithChildren{
    props: IFeaturesProps
}
const AutoServiceFeature:FC<IProps> = ({props}) => {
    return (
        <div className='serviceFeature'>
            <div className='serviceImageDiv'>
                <img src={props.imageSrc} alt={props.alt}/>
            </div>

            <div className='serviceTextDiv'>
                <p>{props.text}</p>
            </div>

        </div>
    );
};

export default AutoServiceFeature;