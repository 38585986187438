import React, { useEffect } from 'react';

const BinotelWidget = () => {
    useEffect(() => {
        const widgetHash = 'v8zth7yj4tcznxyw6l54';
        const gcw = document.createElement('script');
        gcw.type = 'text/javascript';
        gcw.async = true;
        gcw.src = '//widgets.binotel.com/getcall/widgets/' + widgetHash + '.js';
        document.head.appendChild(gcw);
        return () => {
            document.head.removeChild(gcw);
        };
    }, []);
    return <></>;
};

export default BinotelWidget;