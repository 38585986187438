import {SubmitHandler, useForm} from "react-hook-form";
import {IAuth, IPasswordRecovery} from "../../../interfaces/authInterface";
import React, {useState} from "react";
import {authService} from "../../../services/apiServices/auth/authService";
import {useParams} from "react-router-dom";
import './passwordRecoveryStyles.scss'
import {LoadingPage} from "../../LoadingPage/LoadingPage";
import {router} from "../../../router";
import ErrorComponent from "../../../components/ErrorComponent/ErrorComponent";

export const PasswordRecoveryPage: React.FC<{  }> = props => {
    const {register, reset, handleSubmit} = useForm<IAuth>()
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(false);
    const {token} = useParams<{token:string}>()
    const passwordRecovery:SubmitHandler<IPasswordRecovery> = async (password: IPasswordRecovery) => {
        try {
            setLoading(true)
            await authService.passwordRecovery(password, token)
            setError(null)
            await router.navigate('/login')
        }catch (e){
            console.log(e)
            setError(e.toString())
        }finally {
            setLoading(false)
        }

    }
    return (
        <>
            {loading ?
                <LoadingPage/>
                :
                <>
                    {!error ?
                        <div className="passwordRecoveryPage">
                            <p>Відновлення Пароля</p>
                            <form onSubmit={handleSubmit(passwordRecovery)} >
                                <p>Введіть новий пароль</p>
                                <input type='text' {...register('password')} placeholder="Введіть новий пароль"/>
                                <button disabled={loading}>Змінити</button>
                            </form>
                        </div>
                        :
                        <ErrorComponent to={'/password_recovery_request'} error={error}/>
                    }
                </>

            }
            </>
    );
};