import React from 'react';
import './evacImgStyles.scss'
import {Link} from "react-router-dom";
const EvacImageComponent = () => {
    return (
        <div className='evacImageContainer'>
            <img src={'/evacImages/evacMainImg.jpg'} alt='evacuator'/>
            <Link className='button' to='/evac#evacForm'>Замовити евакуатор</Link>
        </div>
    );
};

export default EvacImageComponent;