import {apiService, IRes} from "../apiService";
import {urls} from "../../../constants";
import {
    ICart,
    ICartToCreate,
    IOrder,
    IOrderPage, IOrderSearchParams,
    IOrderToCreate
} from "../../../interfaces/ecommerceInterfaces/ecommerceInterface";
export const ecommerceService = {
    // getOrderList: async (page?: number, id?:string, userName?:string, userSurname?:string, userPhone?:string, userCity?:string, productId?:string): Promise<IRes<IOrderPage>> => apiService.get(`${urls.ecommerce.listOrder}?page=${page}`),
    addCart: async (cart:ICartToCreate):Promise<IRes<ICart>> => {return await apiService.post(urls.ecommerce.addCart, cart)},
    updateCart: async (items: ICartToCreate, cartId: number):Promise<IRes<ICart>> => {return await apiService.patch(`${urls.ecommerce.updateCart}/${cartId}`, items)},
    makeOrder: async (order: IOrderToCreate): Promise<IRes<IOrder>> => apiService.post(urls.ecommerce.makeOrder, order),
    changeOrderStatus: async (orderId: string, orderStatus: string): Promise<IRes<IOrder>> => apiService.patch(`${urls.ecommerce.changeStatus}/${orderId}`, {status: orderStatus}),
    getOrderList: async (
        page?: number,
        searchQuery?: IOrderSearchParams | null
    ): Promise<IRes<IOrderPage>> => {
        let query = `${urls.ecommerce.listOrder}?page=${page}`;
        if (searchQuery){
            if (searchQuery.orderId) query += `&id=${searchQuery.orderId}`;
            if (searchQuery.userName) query += `&name=${encodeURIComponent(searchQuery.userName)}`;
            if (searchQuery.userSurname) query += `&surname=${encodeURIComponent(searchQuery.userSurname)}`;
            if (searchQuery.userPhone) query += `&phone=${encodeURIComponent(searchQuery.userPhone)}`;
            if (searchQuery.userCity) query += `&city=${encodeURIComponent(searchQuery.userCity)}`;
            if (searchQuery.productId) query += `&productId=${searchQuery.productId}`;
        }
        return apiService.get(query);
    }
}

//"new" | "in_progress" | "done" | "canceled"