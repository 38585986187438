import {FC, PropsWithChildren, useEffect, useState} from "react";
import {CartItem} from "./CartItem/CartItem";
import {ICartItem} from "../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import './cartStyles.scss'
interface IProps extends PropsWithChildren{
    cartItems?: ICartItem[];
    deleteItemFromCart: (id:number) => void
    total_price: string;
    isMobile: boolean;
}
export const Cart:FC<IProps> = ({cartItems, deleteItemFromCart, total_price, isMobile}) => {
    const [total, setTotal] = useState<number | null>(null)
    useEffect(() => {
        if (total_price){
            const newTotal = parseFloat(total_price)
            setTotal(newTotal)
        }
    }, [total_price]);

    return (
        <div className="cartContainer">
            <h2>Кошик</h2>
            {cartItems?.map((cartItem => <CartItem key={cartItem.id} cartItem={cartItem}
                                                   deleteItemFromCart={deleteItemFromCart} isMobile={isMobile}/>))}


        </div>
    )
}