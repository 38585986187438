import React, {useEffect, useState} from 'react';
import {router} from "../../../router";
import './searchInputComponentStyles.scss'

const SearchInputComponent = () => {
    const [searchQuery, setSearchQuery] = useState('');
    useEffect(() => {
        // Зчитування параметру 'params' з URL
        const params = new URLSearchParams(window.location.search).get('params');
        if (params) {
            setSearchQuery(decodeURIComponent(params));
        }
    }, []);
    const handleSearch = () => {
        const encodedQuery = encodeURIComponent(searchQuery);
        const newUrl = `/products_search?params=${encodedQuery}`;
        if (window.location.pathname.includes('/products_search')) {
            window.location.href = newUrl;
        } else {
            router.navigate(newUrl)
        }
    };
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && searchQuery.trim()) {
            handleSearch();
        }
    };
    return (
        <div className='inputDiv'>
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Введіть текст пошуку"
                onKeyDown={handleKeyDown}
            />
            <button className='inputButton' onClick={handleSearch} disabled={!searchQuery.trim()}>
                <img src="/headerImages/inputImages/inputSearch.png" alt='Пошук'/>
            </button>
        </div>
    );
};

export default SearchInputComponent;
