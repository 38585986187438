import React, {FC, PropsWithChildren} from 'react';
import {ICartItem} from "../../../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import ItemInCart from "./ItemInCart/ItemInCart";
interface IProps extends PropsWithChildren{
    items?: ICartItem[]
}
const ItemsInCart:FC<IProps> = ({items}) => {
    return (
        <div className="itemsInOrderConatiner">
            {items?.map(item => <ItemInCart key={item.id} item={item}/> )}
        </div>
    );
};

export default ItemsInCart;