import {useEffect, useState} from "react";
import {ISearchOption} from "../../../../interfaces/carInterface";
import {categoryService} from "../../../../services/apiServices/category/categoryServices";
import {brandsService, modelsService} from "../../../../services/apiServices/cars/carsService";
import {Link} from "react-router-dom";
import './selectCarStyles.scss'
import CarSearchSelect from "../../../TestComponents(For Dev Only)/CarSearchSelect";
export const SelectCarComponent: React.FC<{  }> = props => {
    const [brands, setBrands] = useState<ISearchOption[] | []>([]);
    const [models, setModels] = useState<ISearchOption[] | null>(null);
    const [categories, setCategories] = useState<ISearchOption[] | null>(null)
    const [selectedBrand, setSelectedBrand] = useState<string>("");
    const [selectedModel, setSelectedModel] = useState<string>("");
    const [selectedCategory, setSelectedCategory] = useState<string>("")
    let linkPath = '';
    if (selectedCategory && selectedModel) {
        linkPath = `/products_by_category_and_model/${selectedCategory}/${selectedModel}`;
    } else if (selectedCategory && selectedBrand && !selectedModel){
        linkPath = `/products_by_category_and_brand/${selectedCategory}/${selectedBrand}`;
    } else if (selectedModel) {
        linkPath = `/products_by_model/${selectedModel}`;
    } else if (selectedBrand) {
        linkPath = `/products_by_brand/${selectedBrand}`;
    }
    useEffect(() => {
        FetchData()
    }, [])
    useEffect(() => {

    }, [linkPath]);
    const FetchData = async () => {
        try {
            const response = await brandsService.getAll()
            if (response.data && Array.isArray(response.data)) {
                setBrands(response.data)
            } else {
                console.error('Data is not array');
            }
        }catch (e){
            console.log(`Error: ${e}`)
        }
    }
    const FetchDataModels = async (brandId:string) => {
        if(brandId){
            try {
                const response = await modelsService.byBrand(brandId);
                if (response.data && Array.isArray(response.data)) {
                    setModels(response.data);
                } else {
                    console.error("Отримані дані не є масивом");
                }
            } catch (e) {
                console.error(`Error: ${e}`);
            }
        }

    }
    const FetchCategoriesByBrand = async (id: string) => {
        if (id){
            try{
                const response = await categoryService.getByBrand(id)
                if (response.data && Array.isArray(response.data)) {
                    setCategories(response.data);
                } else {
                    console.error("Отримані дані не є масивом");
                }

            }catch (e){
                console.log("Error>> " + e)
            }
        }
    }
    const FetchCategoriesByModel = async (id: string) => {
        if (id){
            try{
                const response = await categoryService.getByModel(id)
                if (response.data && Array.isArray(response.data)) {
                    setCategories(response.data);
                } else {
                    console.error("Отримані дані не є масивом");
                }

            }catch (e){
                console.log("Error>> " + e)
            }
        }
    }

    const handleBrandChange = async (id: string) => {
        setSelectedBrand(id);
        setSelectedModel("");
        setSelectedCategory("");
        setModels(null);
        setCategories(null);
        FetchDataModels(id)
        FetchCategoriesByBrand(id)
    };

    const handleModelChange = (id: string) => {
        setSelectedModel(id);
        setSelectedCategory("");
        setCategories(null);
        FetchCategoriesByModel(id)
    };
    const handleCategoryChange = (id: string) => {
        setSelectedCategory(id);
    }
    return (
        <div className='selectCar'>
                <div className="selectCarContainer">
                    <p>Пошук запчастин</p>
                    <div className="selectCarComponent">
                        <CarSearchSelect option={brands} handleChange={handleBrandChange}
                                         placeHolder={"Марка"}/>
                        <CarSearchSelect option={models} handleChange={handleModelChange}
                                         placeHolder={"Модель"}/>
                        <CarSearchSelect option={categories} handleChange={handleCategoryChange}
                                         placeHolder={"Товар"}/>
                        {linkPath ? <Link to={linkPath}>Пошук</Link> : <Link style={{backgroundColor: "#8991a1", cursor:"auto"}} to={linkPath}>Пошук</Link>}
                    </div>
                </div>
        </div>



    );
};