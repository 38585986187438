//const baseURL = 'http://192.168.88.38:8000' // boltorez
// const baseURL = 'http://54.221.40.74/api' //aws
// const baseURL = 'http://172.31.83.158/api' //aws private ip
// const baseURL = 'http://ec2-3-86-76-72.compute-1.amazonaws.com/api' //aws public ip
// const baseURL = 'http://127.0.0.1:8000/api' //localhost
const baseURL = process.env.REACT_APP_API + "/api"
const baseOptions= {
    byBrand: '/by_brand',
    byModel: `/by_model`,
    byCategory:`/by_category`,
    byCategoryAndBrand: `/by_category_and_brand`,
    byCategoryAndModel: `/by_category_and_model`,
    byManufacturer: '/by_manufacturer',
    search: '/search',
    details: `/details`,
}
const requests = '/requests'
const create = '/create'
const auth = '/auth'
const users = '/users'
const category = '/category'
const product = '/products'
const manufacturer = '/manufacturer'
const ecommerce = {
    base: '/ecommerce',
    order: '/order',
    cart: '/cart',
    changeStatus: '/change_status'

}
const importData = '/import_data'
const payment = '/payment'
const cache = '/cache'
const evac = '/evac'
const autoService = '/auto_service'
const usa = '/usa'


const urls = {
    requests:{
        base:requests,
        create: `${requests}${create}`,
        byId: (id: number): string => `${requests}/${id}`
    },
    auth:{
        login:auth,
        refresh: `${auth}/refresh`,
        register: users,
        activate: `${auth}/activate`,
        me: `${auth}/me`,
        password_recovery_request:`${auth}/recovery/request`,
        password_recovery: `${auth}/recovery`,
        verifyEmail: `${users}/verify_email`,
        activateEmail: `${auth}/activate_email`
    },
    category:{
        base: category,
        byBrand: `${category}${baseOptions.byBrand}`,
        byModel: `${category}${baseOptions.byModel}`

    },
    product:{
        byBrand: `${product}${baseOptions.byBrand}`,
        byModel: `${product}${baseOptions.byModel}`,
        byCategory: `${product}${baseOptions.byCategory}`,
        byCategoryAndBrand: `${product}${baseOptions.byCategoryAndBrand}`,
        byCategoryAndModel: `${product}${baseOptions.byCategoryAndModel}`,
        byManufacturer:`${product}${baseOptions.byManufacturer}`,
        search: `${product}${baseOptions.search}`,
        details: `${product}${baseOptions.details}`,
    },
    cars:{
        brands: `${manufacturer}/brands`,
        models: `${manufacturer}/models_by_brand`,
        manufacturers: `${manufacturer}/part_manufacturers`
    },
    ecommerce: {
        addCart: `${ecommerce.base}${ecommerce.cart}`,
        updateCart: `${ecommerce.base}${ecommerce.cart}/update`,
        makeOrder: `${ecommerce.base}${ecommerce.order}/create`,
        listOrder: `${ecommerce.base}${ecommerce.order}`,
        changeStatus: `${ecommerce.base}${ecommerce.order}${ecommerce.changeStatus}`
    },
    payment: {
        checkout: `${payment}/create_checkout`
    },
    importData: {
        csvImport: `${importData}/csv_import`
    },
    cache: {
        phones: `${cache}/phones`,
        messengersPhones: `${cache}/messenger_contacts`,
        adminEmail: `${cache}/admin_email`,
        supportEmail: `${cache}/support_email`,
        currencyToUAH: `${cache}/currency/to_uah`,
        socialLinks: `${cache}/social_links`,
        evacPhones: `${cache}${evac}/phones`,
        evacPrices: `${cache}${evac}/prices`,
        autoServicePhones: `${cache}${autoService}/service/phones`,
        autoServicePartsPhones: `${cache}${autoService}/parts/phones`,
        autoServiceAddress: `${cache}${autoService}/address`,
        autoServiceAdvantages: `${cache}${autoService}/advantages`,
        usaPhones: `${cache}${usa}/phones`,
        usaAdvantages: `${cache}${usa}/advantages`,
        usaServices: `${cache}${usa}/services`,
    },
    evac: {
        request: `${evac}/request`
    },
    autoService:{
        request: `${evac}/auto_service`,
    },
    carFromUsa:{
        request: `${evac}/car_from_usa`,
    }


}

export {
    baseURL,
    urls
}