import React, {FC, PropsWithChildren} from 'react';
import {footerSocialLinksData} from "../../../../../constants/SocialLinksConstants/SocialLinksConstants";
import LinkSocialImage from "../../../../LinkImagesComponents/LinkSocialImage/LinkSocialImages";
interface IProps extends PropsWithChildren{
    infoDivClass: string
}
const FooterSocialLinks:FC<IProps> = ({infoDivClass}) => {
    return (
        <div className={infoDivClass}>
            <h5>Ми в соціальних мережах:</h5>
            <div className='footerSocialLinksContainer'>
                {footerSocialLinksData.map(link => <LinkSocialImage key={link.id} link={link}/>)}
            </div>
        </div>
    );
};

export default FooterSocialLinks;