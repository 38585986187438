import React, {useEffect, useState} from 'react';
import {useCache} from "../../../../context/cacheContext";
import ChangePricesInputContainer from "./ChangeEvacPricesInputContainer/ChangePricesInputContainer";
import {IEvacPrice, IEvacPrices} from "../../../../interfaces/evacInterfaces/evacPricesInterfaces";
import {cacheService} from "../../../../services/apiServices/cache/cacheService";
import {AdminLoadingPage} from "../../AdminLoadingPage/AdminLoadingPage";

const ChangeEvacPrices = () => {
    const {evacPrices, getEvacPrices} = useCache()
    const [changeInfo, setChangeInfo] = useState<string | null>(null)
    const [inputs, setInputs] = useState<IEvacPrice[] | null>([]);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if(evacPrices){
            const newPrices = evacPrices.map(price => price)
            setInputs(newPrices)

        }else {
            console.log('no prices')
            getEvacPrices()
        }
    }, [evacPrices]);
    useEffect(() => {
    }, [changeInfo]);
    const handleSubmit = async (event: any)=> {
        const data:IEvacPrices = {prices: inputs}
        try {
            setLoading(true)
            const res = await cacheService.evacPrices.setPrices(data)
            setChangeInfo('Ціни успішно додано')
            return res
        }catch (e){
            console.log(`Error: ${e}`)
            setChangeInfo('Помилка сервера: ' + e.toString())
            return null
        }finally {
            setLoading(false)
        }
    }
    return (
        <>
            {loading ?
                <AdminLoadingPage/>
                : //ELSE
                <div className='changeEvacPrices'>
                    <form className='changeEvacPricesForm' onSubmit={handleSubmit}>
                        <div className="innerChangeEvacPricesFormDiv">
                            <ChangePricesInputContainer prices={evacPrices} changeInfo={changeInfo}
                                                        header={'Ціни на послуги евакуатора'} inputs={inputs}
                                                        setInputs={setInputs}/>
                        </div>
                        <button>Зберегти</button>
                    </form>
                </div>
            }

        </>
    );
};

export default ChangeEvacPrices;