import React, {FC, PropsWithChildren} from 'react';
import {IOrder} from "../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {Link} from "react-router-dom";
import Order from "./Order/Order";

interface IProps extends PropsWithChildren{
    orders?: IOrder[]
    setLoading: (arg: boolean) => void;
    setTrigger: (arg: boolean) => void
}
const Orders:FC<IProps> = ({orders, setLoading, setTrigger}) => {
    return (
        <div className='orderContainer'>
            {orders?.length > 0 ?
             <>
            {orders?.map(order => <Order key={order.id} order={order} setLoading={setLoading} setTrigger={setTrigger}/> )}
             </>
                :
            <h2>Результатів немає</h2>
            }

        </div>
    );
};

export default Orders;

