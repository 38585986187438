import React, {useEffect, useState} from 'react';
import {useCache} from "../../../../context/cacheContext";
import {ICacheAdminEmailForCreate} from "../../../../interfaces/cacheInterface";
import {cacheService} from "../../../../services/apiServices/cache/cacheService";
import {AdminLoadingPage} from "../../AdminLoadingPage/AdminLoadingPage";

const ChangeSupportEmail = () => {
    const {supportEmail, getSupportEmail} = useCache()
    const [inputSupportEmail, setInputSuppurtEmail] = useState<string>()
    const [changeInfo, setChangeInfo] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    useEffect(() => {
        if(supportEmail){
            setInputSuppurtEmail(supportEmail)
        }
        else {
            setError('no email')
            getSupportEmail()
        }
    }, [supportEmail]);
    useEffect(() => {

    }, [changeInfo]);
    const handleSubmit = async (event:any) => {
        const data:ICacheAdminEmailForCreate = {email: inputSupportEmail}
        try {
            setLoading(true)
            const res = await cacheService.emailService.setEmailSupport(data)
            setChangeInfo('Email успішно додано')
            return res
        }catch (e){
            console.log(`Error: ${e}`)
            setChangeInfo('Помилка сервера: ' + e.toString())
            return null
        }finally {
            setLoading(false)
        }
    };
    return (
        <>
            {
        loading ?
            <AdminLoadingPage/>
            :
            <div className='changeEmail'>
                <form className='changeEmailForm' onSubmit={handleSubmit}>
                    <div className='innerChangeEmailFormDiv'>
                        <div className='InputContainer'>
                            <h2>Email підтримки</h2>
                            <input type='email' placeholder='email' required minLength={5} value={inputSupportEmail}
                                   onChange={e => {
                                       const newInput = e.target.value
                                       setInputSuppurtEmail(newInput)
                                   }}
                                   className="phoneInput"/>
                        </div>
                    </div>
                    {changeInfo && <label>{changeInfo}</label>}
                    <button>Зберегти</button>
                </form>

            </div>

        }

        </>
    );
};

export default ChangeSupportEmail;