export interface IFeaturesProps{
    id: number
    imageSrc: string,
    text: string,
    alt: string,
    styleId: string | null
}

const path = '/featuresImages'
const evacPath='/featuresImages/evacFeatures'
const servicePath = '/featuresImages/autoServiceFeatures'
const fileFormat = 'png'

export const featuresDataInMain: IFeaturesProps[] = [
    {
        id:0,
        imageSrc: `${path}/bonusFeature.${fileFormat}`,
        text: 'Бонус - 1% від замовлення при оплаті на сайті',
        alt: 'бонус',
        styleId: 'leftFeature'
    },
    {
        id:1,
        imageSrc: `${path}/qualityFeature.${fileFormat}`,
        text: 'Належна якість деталей',
        alt: 'якість',
        styleId: 'middleFeature'
    },
    {
        id:2,
        imageSrc: `${path}/consultFeature.${fileFormat}`,
        text: 'Консультація кваліфікованих спеціалістів',
        alt: 'консультація',
        styleId: 'rightFeature'
    },
    {
        id:3,
        imageSrc: `${path}/loyaltyFeature.${fileFormat}`,
        text: 'Система лояльності постійним клієнтам',
        alt: 'лояльність',
        styleId: 'leftFeature'
    },
    {
        id:4,
        imageSrc: `${path}/ownServiceFeature.${fileFormat}`,
        text: 'Власний сервіс',
        alt: 'сервіс',
        styleId: 'middleFeature'
    },
    {
        id:5,
        imageSrc: `${path}/guaranteeFeature.${fileFormat}`,
        text: 'Тривала онлайн-гарантія',
        alt: 'гарантія',
        styleId: 'rightFeature'

    }
]
export const featuresDataInProductDetails: IFeaturesProps[] = [
    {
        id:0,
        imageSrc: `${path}/bonusFeature.${fileFormat}`,
        text: 'Бонус - 1% від замовлення при оплаті на сайті',
        alt: 'бонус',
        styleId: 'leftFeature'
    },
    {
        id:1,
        imageSrc: `${path}/qualityFeature.${fileFormat}`,
        text: 'Належна якість деталей',
        alt: 'якість',
        styleId: 'middleFeature'
    },
    {
        id:2,
        imageSrc: `${path}/guaranteeFeature.${fileFormat}`,
        text: 'Тривала онлайн-гарантія',
        alt: 'гарантія',
        styleId: 'rightFeature'

    },
    {
        id:3,
        imageSrc: `${path}/deliveryFeature.${fileFormat}`,
        text: 'Швидка доставка',
        alt: 'доставка',
        styleId: 'rightFeature'

    }
]
export const featuresDataInEvac: IFeaturesProps[] = [
    {
        id:0,
        imageSrc: `${evacPath}/24-hoursFeature.${fileFormat}`,
        text: 'Працюємо цілодобово',
        alt: 'цілодобово',
        styleId: 'leftFeature'
    },
    {
        id:1,
        imageSrc: `${evacPath}/evacFeature.${fileFormat}`,
        text: 'Транспортуємо авто, мототехніку та негабаритні вантажі',
        alt: 'транспортуємо',
        styleId: 'middleFeature'
    },
    {
        id:2,
        imageSrc: `${evacPath}/mapFeature.${fileFormat}`,
        text: 'Дістанемося в будь-яке місце',
        alt: 'місце',
        styleId: 'rightFeature'

    },
]
export const featuresDataInService: IFeaturesProps[] = [
    {
        id:0,
        imageSrc: `${servicePath}/engine.${fileFormat}`,
        text: 'Ремон двигунів',
        alt: 'Ремон двигунів',
        styleId: 'leftFeature'
    },
    {
        id:1,
        imageSrc: `${servicePath}/chassis.${fileFormat}`,
        text: 'Ремонт ходової',
        alt: 'Ремонт ходової',
        styleId: 'middleFeature'
    },
    {
        id:2,
        imageSrc: `${servicePath}/processor.${fileFormat}`,
        text: 'Ремонт електроніки',
        alt: 'Ремонт електроніки',
        styleId: 'rightFeature'

    },
    {
        id:3,
        imageSrc: `${servicePath}/carAC.${fileFormat}`,
        text: 'Ремонт кондиціюванння',
        alt: 'Ремонт кондиціюванння',
        styleId: 'rightFeature'

    },
    {
        id:4,
        imageSrc: `${servicePath}/carDiagnostics.${fileFormat}`,
        text: 'Комп’ютерна діагностика',
        alt: 'Комп’ютерна діагностика',
        styleId: 'middleFeature'
    },


    {
        id:5,
        imageSrc: `${servicePath}/carBuy.${fileFormat}`,
        text: 'Діагностика перед покупкою',
        alt: 'Діагностика перед покупкою',
        styleId: 'middleFeature'
    },
    {
        id:6,
        imageSrc: `${servicePath}/welding.${fileFormat}`,
        text: 'Зварювання авто',
        alt: 'Зварювання авто',
        styleId: 'leftFeature'
    },
    {
        id:7,
        imageSrc: `${servicePath}/flattening.${fileFormat}`,
        text: 'Рихтування авто',
        alt: 'Рихтування авто',
        styleId: 'middleFeature'
    },

    {
        id:8,
        imageSrc: `${servicePath}/carTechnical.${fileFormat}`,
        text: 'Технічне обслуговування',
        alt: 'Технічне обслуговування',
        styleId: 'leftFeature'
    },

    {
        id:9,
        imageSrc: `${servicePath}/corrosive.${fileFormat}`,
        text: 'Протикорозійна обробка',
        alt: 'Протикорозійна обробка',
        styleId: 'middleFeature'
    },
    {
        id:10,
        imageSrc: `${servicePath}/polishing.${fileFormat}`,
        text: 'Полірування авто та фар',
        alt: 'Полірування авто та фар',
        styleId: 'rightFeature'

    },
    {
        id:11,
        imageSrc: `${servicePath}/carPainting.${fileFormat}`,
        text: 'Малярні роботи',
        alt: 'Малярні роботи',
        styleId: 'leftFeature'
    }

]
