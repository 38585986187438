import React, {FC, PropsWithChildren} from 'react';
import './socialLinksStyles1280.scss'
import {headerMessengerLinksData} from "../../../../constants/SocialLinksConstants/SocialLinksConstants";
import LinkContactImage from "../../../LinkImagesComponents/LinkContactImage/LinkContactImage";
import {IHeadermessengerLinksConfigs} from "../../../../interfaces/configInterfaces/headerConfigInterface";
interface IProps extends PropsWithChildren{
    configs: IHeadermessengerLinksConfigs
}

const HeaderSocialLinks:FC<IProps> = ({configs}) => {
    return (
        <div className='socialLinksContainer'>
            {configs?.headerMessengerLinksData.map(link => <LinkContactImage key={link.id} link={link}/>)}
        </div>
    );
};

export default HeaderSocialLinks;