import React, {ChangeEvent, FC, PropsWithChildren} from 'react';
import {IMakeOrderForm} from "../../../../interfaces/makeOrderFormInterface";
import {useForm} from "react-hook-form";
import {IOrderToCreate} from "../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {useAuth} from "../../../../context/authContext";
import {Link} from "react-router-dom";
interface IProps extends PropsWithChildren{
    formData: IMakeOrderForm
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void
}
const UserDeliverInfoInputs:FC<IProps> = ({formData, handleInputChange}) => {
    const {register} = useForm<IOrderToCreate>();
    const {isAuthenticated} = useAuth()

    return (

        <div className='makeOrderInnerFormContainer' id='orderFormLeftInnerContainer'>
            {!isAuthenticated &&
                <div className='orderFormRegister'>
                    <Link to={'/login'}>Я зареєстрований</Link>
                    <Link id='regLink' to={'/register'}>Зареєструватися</Link>
                </div>
            }
            <input type="text" placeholder={'Телефон (+380...)'} {...register('this_user_phone')}
                   value={formData.this_user_phone} onChange={handleInputChange} pattern="\+380\d{9}" required/>
            <input type="text" placeholder={"Ім'я"} {...register('this_user_name')} value={formData.this_user_name}
                   onChange={handleInputChange} required/>
            <input type="text" placeholder={'Прізвище'} {...register('this_user_surname')}
                   value={formData.this_user_surname} onChange={handleInputChange} required/>
            <input type="text" placeholder={"По-батькові (не обов'язково)"} {...register('this_user_middlename')}/>
            <input type="email" placeholder={"e-mail (не обов'язково)"} {...register('this_user_email')}/>
        </div>
    );
};

export default UserDeliverInfoInputs;