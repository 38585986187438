import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {
    IDeliveryCities,
    IDeliveryRegion,
    IDeliveryWareHouse
} from "../../../../../interfaces/externalInterfaces/deliversInterfaces";
import {deliversServices} from "../../../../../services";

interface IProps extends PropsWithChildren{
    setSelectedCitiesDelivery: (cities: IDeliveryCities) => void;
    selectedCityDeliveryId: string;
    setSelectedCityDeliveryId: (id: string) => void;
    setSelectedRegionDelivery: (region: IDeliveryRegion) => void
    selectedRegionDeliveryId: string;
    setSelectedRegionDeliveryId: (id: string) => void;
    setSelectedWareHousesDelivery: (wareHouses: IDeliveryWareHouse) => void;
    selectedWareHousesDeliveryId: string;
    setSelectedWareHousesDeliveryId: (id: string) => void;

}
const DeliveryForm:FC<IProps> = ({setSelectedCitiesDelivery, selectedCityDeliveryId, setSelectedCityDeliveryId, setSelectedRegionDelivery, selectedRegionDeliveryId, setSelectedRegionDeliveryId, setSelectedWareHousesDelivery, selectedWareHousesDeliveryId, setSelectedWareHousesDeliveryId}) => {
    const [regionDelivery, setRegionDelivery] = useState<IDeliveryRegion[]>([])
    const [citiesDelivery, setCitiesDelivery] = useState<IDeliveryCities[]>([])
    const [wareHousesDelivery, setWareHousesDelivery] = useState<IDeliveryWareHouse[]>([])
    useEffect(() => {
        const FetchData = async () => {
            try {

                const response = await deliversServices.getRegions()
                if (response.data.data && Array.isArray(response.data.data)) {
                    setRegionDelivery(response.data.data)
                } else {
                    console.error('Отримані дані не є масивом');
                }

            }catch (e){
                console.log(`Error: ${e}`)
            }

        }
        FetchData()
    }, []);
    const handleRegionChangeDelivery = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedRegionId = event.target.value;
        const selectedIndex = event.target.selectedIndex
        const selectedRegion = regionDelivery[selectedIndex - 1];
        setSelectedRegionDelivery(selectedRegion)
        setSelectedRegionDeliveryId(selectedRegionId)
        try {
            const response = await deliversServices.getCities(selectedRegionId);
            if (response.data.data && Array.isArray(response.data.data)) {
                setCitiesDelivery(response.data.data)
            } else {
                console.error("Отримані дані не є масивом");
            }
        } catch (e) {
            console.error(`Error: ${e}`);
        }
    };
    const handleCityChangeDelivery = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCityId = event.target.value;
        const selectedIndex = event.target.selectedIndex
        const selectedCity = citiesDelivery[selectedIndex - 1]
        setSelectedCitiesDelivery(selectedCity)
        setSelectedCityDeliveryId(selectedCityId)
        try {
            const response = await deliversServices.getWareHouses(selectedCityId);
            if (response.data.data && Array.isArray(response.data.data)) {
                setWareHousesDelivery(response.data.data);
            } else {
                console.error("Отримані дані не є масивом");
            }
        } catch (e) {
            console.error(`Error: ${e}`);
        }
    };
    const handleWareHouseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedDeliveryWareHouse = event.target.value;
        const selectedIndex = event.target.selectedIndex
        const selectedWareHouse = wareHousesDelivery[selectedIndex - 1]
        setSelectedWareHousesDelivery(selectedWareHouse)
        setSelectedWareHousesDeliveryId(selectedDeliveryWareHouse);
    }

    return (
        <>
            <select className='deliverySelect' value={selectedRegionDeliveryId} onChange={handleRegionChangeDelivery}
                    required>
                <option value="">Оберіть область</option>
                {regionDelivery.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
            <select className='deliverySelect' value={selectedCityDeliveryId} onChange={handleCityChangeDelivery}
                    required>
                <option value="">Оберіть місто</option>
                {citiesDelivery.map((option) => (
                    <option key={option.id} value={option.id}>
                        {`${option.name}`}
                    </option>
                ))}
            </select>
            <select className='deliverySelect' value={selectedWareHousesDeliveryId} onChange={handleWareHouseChange}
                    required>
                <option value="">Оберіть відділення</option>
                {wareHousesDelivery.map((option) => (
                    <option key={option.id} value={option.id}>
                        {`${option.name} - ${option.address}`}
                    </option>
                ))}
            </select>
        </>
    );
};

export default DeliveryForm;