import React, {useEffect} from 'react';
import './advantagesStyles.scss'
import { useLocation } from 'react-router-dom';
import {advantagesData} from "../../../constants/advantagesConstants/advantagesConstants";
import Advantage from "./Advantage/Advantage";

const AdvantagesComponent = () => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash === '#advantages') {
            const element = document.getElementById('advantages');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (
        <div className='advantages' id="advantages">
            <div className='mainSign'><p>Наші переваги</p></div>
            <div className='advantagesContainer'>
                {advantagesData.map(advantage => <Advantage key={advantage.id} advantage={advantage}/>)}
            </div>

        </div>
    );
};

export default AdvantagesComponent;