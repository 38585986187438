import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {authService} from "../../../services/apiServices/auth/authService";
import {useAuth} from "../../../context/authContext";
import '../ActivateAccountPage/activateAccountPageStyles.scss'
// import './ActivatePagesStyles.scss'
import ErrorComponent from "../../../components/ErrorComponent/ErrorComponent";
const ActivateEmailPage = () => {
    const {setMe, setIsEmailActivated, fetchMe, setLocalMe} = useAuth()
    const {token} = useParams<{token:string}>()
    const [isBad, setIsBad] = useState<string | null>(null)
    const [trigger, setTrigger] = useState<boolean | null>(null)
    useEffect(() => {

        checkActivation();

    }, []);
    const checkActivation = async () => {
        const isActivated = localStorage.getItem('isEmailActivated'); // Перевіряємо, чи вже була активація електронної пошти
        if (!isActivated) {
            try {

                    await authService.activateEmail(token); // Виконуємо активацію електронної пошти
                    localStorage.setItem('isEmailActivated', 'true'); // Зберігаємо інформацію про активацію у локальному сховищі
                    const me = await fetchMe()
                    if (me){
                        const newMe = {...me}
                        newMe.is_verified_email = true
                        setMe(newMe)
                        setLocalMe(newMe)
                    }
                    setIsEmailActivated(true)

            } catch (e) {
                console.log(e);
                setIsBad(e.toString());
            }
        }
        else {
            setIsBad("Email already acticated")
        }
    };

    return (
        <div className='activateAccountPage'>
            {isBad?
                <ErrorComponent to={'/main'} error={isBad}/>
                :
                <div className='activateAccountContainer'>
                    <p>Вашу пошту активовано і зараховано +100 бонусів</p>
                    <Link to={'/main'}>Головна сторінка</Link>
                </div>}
        </div>
    )
};

export default ActivateEmailPage;