import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {IOrder} from "../../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import CartInOrder from "./CartInOrder/CartInOrder";
import UserInOrder from "./UserInOrder/UserInOrder";
import {IUserInOrder} from "../../../../interfaces/userInterface";
import OrderDeliverInfo from "./OrderDeliverInfo/OrderDeliverInfo";
import {ecommerceService} from "../../../../services/apiServices/ecommerce/ecommerceService";
import {useCache} from "../../../../context/cacheContext";
import {orderStatuses} from "../../../../constants/ecommerceConstants/ecommerceConstants";
import OrderOpenFullButton from "./OrderOpenFullButton/OrderOpenFullButton";
import AdminOrderDetails from "./OrderDetails/AdminOrderDetails";
import AdminOrderPriceContainer from "./OrderPriceContainer/AdminOrderPriceContainer";
import OrderTotalPrice from "./OrderPriceContainer/OrderTotalPrice/OrderTotalPrice";
interface IProps extends PropsWithChildren{
    order?: IOrder
    setLoading: (arg: boolean) => void
    setTrigger: (arg: boolean) => void
}
const Order:FC<IProps> = ({order,setLoading, setTrigger}) => {
    const {currencyToUah} = useCache()
    const [isDetails, setIsDetails] = useState(false)
    const [orderStatus, setOrderStatus] = useState('')
    const [user, setUser] = useState<IUserInOrder | null>(null)
    const [isChanged, setIsChanged] = useState(false)
    const handleCartOpen = () => {
        setIsDetails(prevState => !prevState)
    }
    useEffect(() => {
        const userData: IUserInOrder = {
            name: order?.this_user_name,
            surname: order?.this_user_surname,
            middlename: order?.this_user_middlename,
            phone: order?.this_user_phone,
            email: order?.this_user_email
        }
        setUser(userData)
    }, []);
    useEffect(() => {

        setOrderStatus(order.status)
    }, [order]);

    const handleOrderStatusChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        setOrderStatus(event.target.value)
        if(event.target.value === order?.status){
            setIsChanged(false)
        }else {
            setIsChanged(true)
        }

    }
    const handleOrderUpdate = async () => {
        setLoading(true)
        try{
            const res = await  ecommerceService.changeOrderStatus(order.id.toString(), orderStatus)
            order.status = orderStatus
            return res
        }catch (e){

        }finally {
            setLoading(false)
        }
    }
    return (
        <div className='order'>
            <div className='orderHeader' onClick={handleCartOpen}></div>
            <div className='orderRowInfo'>
                <OrderOpenFullButton isOpen={isDetails} setIsOpen={setIsDetails}/>
                <h4>№{order.id}</h4>
                <h4>{user?.name}</h4>
                <h4>{user?.surname}</h4>
                <h4>{user?.phone}</h4>
                {!isDetails && <OrderTotalPrice currencyToUah={currencyToUah} totalPrice={order.cart.total_price}/>}
                {!isDetails && <h4>{order.this_user_city}</h4>}
                {isChanged && <button className='saveBut' onClick={handleOrderUpdate}>Зберегти</button>}


                <select className='deliverySelect' value={orderStatus} onChange={handleOrderStatusChange}
                        required>
                    {orderStatuses.map((status) => (
                        <option key={status.id} value={status.value}>
                            {`${status.translate}`}
                        </option>
                    ))}
                </select>
            </div>

            {isDetails && <AdminOrderDetails order={order} currencyToUah={currencyToUah}/>}



            {/*<button onClick={handleCartOpen}>{cartIsOpen ? <p>Згорнути товари</p> : <p>Подивитись товари</p>}</button>*/}
        </div>
    );
};

export default Order;