import React, {FC, PropsWithChildren} from 'react';
import {IPartManufacturer} from "../../interfaces/carInterface";
import Manufacture from "./Manufacture/Manufacture";

interface IProps extends PropsWithChildren{
    manufacturers?: IPartManufacturer[]
}
const Manufacturers:FC<IProps> = ({manufacturers}) => {
    return (
        <div className='manufacturers'>
            <div className='manufacturersContainer'>
                {manufacturers?.map(manufacture => <Manufacture manufacture={manufacture}/>)}
            </div>
        </div>
    );
};

export default Manufacturers;