import React, {FC, PropsWithChildren} from 'react';
import {IPartManufacturer} from "../../../interfaces/carInterface";
import {Link} from "react-router-dom";
interface IProps extends PropsWithChildren{
    manufacture: IPartManufacturer
}
const Manufacture: FC<IProps> = ({manufacture}) => {
    const {name, id } = manufacture
    //add photo
    return (
        <div>
            <Link style={{ textDecoration: 'none', color: 'black' }} to={`/products_by_manufacturer/${id}`}>{name}</Link>
        </div>
    );
};

export default Manufacture;