import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import {IProduct} from "../../../interfaces/productInterface";
import {Link} from "react-router-dom";
import {ICartItem, ICartToCreate, ICartInfo} from "../../../interfaces/ecommerceInterfaces/ecommerceInterface";
import {ecommerceService} from "../../../services/apiServices/ecommerce/ecommerceService";
import './productStyles.scss'
import {useCache} from "../../../context/cacheContext";
import {useAuth} from "../../../context/authContext";
interface IProps extends PropsWithChildren{
    product?: IProduct
}
export const Product:FC<IProps> = ({product}) => {
    let cart: ICartItem[] = []
    const [isAddToCart, setIsAddToCart] = useState(true)
    const [image, setImages] = useState<string | null>(null)
    const [isPrice, setIsPrice] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null)
    const {currencyToUah} = useCache()
    const {setCartTrigger} = useAuth()
    const [cur, setCur] = useState<number>(40)
    useEffect(() => {
        if(currencyToUah){
            setCur(currencyToUah)
        }else {
            setCur(40)
        }
    }, [currencyToUah]);
    useEffect(() => {
        if (product && product?.image) {
            const baseImageUrl = `http://export-content.baz-on.net`;
            let stArr = product.image.split(",");
            const newImages = stArr.map(s => {
                let index = s.indexOf("/pub");
                return index !== -1 ? baseImageUrl + s.substring(index) : null;
            }).filter(s => s !== null);
            setImages(newImages[0]);
        }
    }, [product.price])
    useEffect(() => {
        const localCart = localStorage.getItem('cart');

        if (localCart){
            try {
                const parsedCart = JSON.parse(localCart) as ICartItem[];
                for (let i = 0; i < parsedCart.length; i++){
                    if(parsedCart[i].id === product?.id){
                        setIsAddToCart(false)
                    }
                }
            }catch (e){
                const errorText = `Error to parse cart: ${e}`
                console.log(errorText)
                setError(errorText)
            }

        }
    }, []);
    useEffect(() => {

    }, [isAddToCart]);
    const itemToCard: ICartItem = {
        id: product?.id,
        name: product?.name,
        brand: product?.brand,
        model: product?.model,
        price: product?.price,
        oem: product?.oem,
        image: ''
    }
    const addItemToCart = async (item: ICartItem) => {
        setIsAddToCart(false)
        itemToCard.image = image
        let localCart = localStorage.getItem('cart');
        if (localCart) {
            const parsedCart = JSON.parse(localCart) as ICartItem[];
            cart = [...parsedCart]
        }
        let cartCopy = [...cart];
        let {id} = item;
        let existingItem = cartCopy.find(cartItem => cartItem.id === id);
        if (existingItem) {
            return
        } else {
            cartCopy.push(item)
        }
        let stringCart = JSON.stringify(cartCopy);
        localStorage.setItem("cart", stringCart)
        try {

            let response
            let cartForCreate: ICartToCreate = {items: cartCopy}
            if (localCart) {
                const localCartInfo = localStorage.getItem('cartInfo');
                let cartId: number
                if (localCartInfo) {
                    const cartInfo: ICartInfo = JSON.parse(localCartInfo)
                    cartId = cartInfo.id
                } else {
                    console.log("Cannot int id")
                }
                response = await ecommerceService.updateCart(cartForCreate, cartId);
                const cartInfo: ICartInfo = {
                    id: response.data.id,
                    can_be_paid: response.data.can_be_paid,
                    total_price: response.data.total_price,
                    total_items: response.data.items.length
                }
                const cartInfoStr = JSON.stringify(cartInfo)
                localStorage.setItem('cartInfo', cartInfoStr)
            } else {
                response = await ecommerceService.addCart(cartForCreate);
                const cartInfo: ICartInfo = {
                    id: response.data.id,
                    can_be_paid: response.data.can_be_paid,
                    total_price: response.data.total_price,
                    total_items: response.data.items.length
                }
                const cartInfoStr = JSON.stringify(cartInfo)
                localStorage.setItem('cartInfo', cartInfoStr)
            }
            if (response) {

            } else {
                console.log("nima")
            }// need to refactor!!!!!!!!!!!!!!


        } catch (error) {
            console.error('Error while adding item to cart:', error);
            setIsAddToCart(true)
        }
        setCartTrigger(prev => !prev)

    }

    if(image === null){
        setImages('/no_photo.svg')
    }

    return(
        <div className='bigProduct'>
            <Link className='link' style={{ textDecoration: 'none', color: 'black' }} to={`/product_details/${product.id}`}>
                <div className='product'>
                    <img src={image} alt="Зображення недоступно"
                         onError={(e) => {
                             e.currentTarget.src = "/no_photo.svg";
                         }}/>
                    <div className='productInfo'>
                        <h3>{product?.name} {product?.brand} {product?.model} {product?.oem}</h3>

                        <p>Код товару: {product?.id}</p>

                    </div>
                    <div className='productPriceContainer'>
                        {product?.price === 0 ?
                            <h3 style={{color: 'black'}}>Уточнити ціну</h3>
                            :
                            <>
                                <h4>$ {product?.price}</h4>
                                <p>{product?.price * cur} грн</p>
                            </>

                        }
                    </div>
                </div>
            </Link>
            {isAddToCart ?
                <button className='buyButton' disabled={!isAddToCart}
                        onClick={() => addItemToCart(itemToCard)}>
                    Купити
                </button>
                : //ELSE
                <div className='buyButton' style={{backgroundColor: "#adb9c0", cursor:"auto"}}>Вже у кошику</div>
            }
            {/*<button onClick={() => addItemToCart(itemToCard)}>Купити</button>*/}
        </div>
    )
}