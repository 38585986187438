import React from 'react';
import './autoServiceImgStyles.scss'
import {Link} from "react-router-dom";
const AutoServiceImageComponent = () => {
    const handleToForm =() =>{
        window.location.href = '/service#serviceForm'
    }
    return (
        <div className='serviceImageContainer'>
            <img src={'/autoServiceImages/autoServiceMainImg.png'} alt='service'/>
            <Link className='button' to={'/service#serviceForm'}>Записатися на сервіс</Link>
        </div>
    );
};

export default AutoServiceImageComponent;