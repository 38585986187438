import React from 'react';
import TagLineComponent from "./TagLineComponent/TagLineComponent";
import {SelectCarComponent} from "./SelectCarComponent/SelectCarComponent";
import './mainPageTopContainerStyles.scss'
//style={{backgroundImage:"url('/mainPageImages/mainPageBG.jpg')"}}
const MainPageTopContainer = () => {
    return (
        <div className='mainPageTopContainer' >
            <TagLineComponent/>
            <SelectCarComponent/>
        </div>
    );
};

export default MainPageTopContainer;