
import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import {router} from "./router";
import {AuthProvider} from "./context/authContext";
import './styles/fontStyle.css'
import './styles/mainStyle.scss'
import {CacheProvider} from "./context/cacheContext";
import { ThemeProvider } from '@mui/material/styles';
import theme from "./Theme/theme";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <ThemeProvider theme={theme}>
        <CacheProvider>
            <AuthProvider>
                <RouterProvider router={router}/>
            </AuthProvider>
        </CacheProvider>
    </ThemeProvider>
);


