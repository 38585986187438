import React, {FC, PropsWithChildren} from 'react';
interface IProps extends PropsWithChildren{
    sign: string;
    info: string | number | null;
}
const CartItemInnerInfo:FC<IProps> = ({sign, info}) => {
    return (
            <div className='cartItemInnerInfo'>
                <p>{sign}:</p>
                <h4>{info}</h4>
            </div>
    );
};

export default CartItemInnerInfo;