
const deliveryBaseURL = 'https://www.delivery-auto.com/api/'
const novaBaseURL = 'https://api.novaposhta.ua/v2.0/json/'

const getAreaList = 'GetAreasList'
const getRegionList = 'GetRegionList'
const getWarehousesList = 'GetWarehousesList'

const externalUrls = {
    deliveryUrls :{
        regions: `v4/Public/${getRegionList}`,
        cities : `v4/Public/${getAreaList}`,
        wareHouses: `v4/Public/${getWarehousesList}`
    }
}
export {
    novaBaseURL,
    deliveryBaseURL,
    externalUrls
}
